#ExternalRatingsRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;

  @include sm {
    flex-direction: row;
  }

  @media #{$tab-portrait-only} {
    flex-direction: column;
  }

  @include lg {
    flex-direction: column;
  }

  @include xl {
    flex-direction: row;
  }

  .reviewsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 20px;

    @include sm {
      flex-direction: row;
    }

    .review {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 200px;
      padding: 5px 10px;
      background-color: $white;
      border: 1px solid $pale-grey;
      border-radius: 10px;

      p {
        margin: 0;
      }

      .ratingContainer {
        display: flex;
        gap: 5px;

        p {
          margin-top: 5px;
          color: #2cd5c4;
          font-weight: 600;
        }
      }

      .totalReviews {
        font-size: 11px;
        color: #{$dark-blue};

        span {
          font-weight: 700;
        }
      }
    }

    img {
      margin: 0px 1.5px;
      height: 15px;
      @media #{$phoneL} {
        height: 12px;
      }
    }
  }
}

#ReviewsRoot {
  .customerReviewsContainer {
    display: flex;
    flex-direction: column;
    gap: 2%;
    row-gap: 30px;

    @include lg {
      flex-direction: row;
    }

    .review {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 10px;
      border: 1px solid $pale-grey;
      padding: 20px;
      background-color: $white;
      border-radius: 10px;

      .header {
        .author {
          margin-top: 10px;
          font-weight: bold;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}

#Reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @include lg {
    gap: 50px;
    padding-bottom: 100px;
  }

  h2 {
    font-size: 21px;
    color: $dark-blue;
    text-align: center;

    @include lg {
      font-size: 28px;
    }
  }

  .linear-background {
    background-image: linear-gradient(to bottom, #4169e1 28%, #ebf0fd 60%, #fff 70%);
    width: 100%;

    .mainSection {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      padding: 20px;

      @include lg {
        flex-direction: row-reverse;
        justify-content: center;
        padding: 60px;
        gap: 5%;
      }

      .titleWithDesc {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include lg {
          align-items: flex-start;
          max-width: 550px;
        }

        h1 {
          font-size: 36px;
          color: white;

          @include lg {
            font-size: 56px;
          }
        }

        p {
          color: white;
          text-align: center;

          @include lg {
            text-align: left;
          }

          &.primaryDesc {
            font-weight: bold;
          }
        }

        .starsBackground {
          display: none;

          @include xl {
            display: initial;
            position: absolute;
            right: -5%;
            top: -5%;
          }
        }
      }

      .externalRatingsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include lg {
          position: relative;
          flex-direction: row;
          align-items: flex-end;
        }

        .ratingsIllu {
          width: 80%;
          max-width: 700px;

          @include lg {
            min-width: 600px;
            width: 100%;
            display: initial;
            padding-right: 20%;
          }
        }

        #ExternalRatingsRoot {
          display: flex;
          background-color: $blue-blue;
          border-radius: 20px;
          margin-top: -15px;
          width: auto;

          @include lg {
            flex-direction: column;
            position: absolute;
            right: 5%;
            bottom: 5%;
          }

          @include xxl {
            right: 2%;
            bottom: 5%;
          }

          .rating {
            padding: 20px;

            @include lg {
              padding: 30px;
            }

            @include xxl {
              padding: 40px;
            }

            .starsContainer {
              gap: 5px;
              margin: 0 5px 20px;
            }

            .ratingScore {
              font-size: 26px;
              font-weight: bold;
              color: $tealish;
              letter-spacing: 2.48px;
              margin: 0;
              text-align: center;

              span {
                font-size: 16px;
              }
            }

            .sourceData {
              font-size: 12px;
              text-align: center;
              margin: 0;
              color: white;
              strong {
                font-weight: bold;
              }
            }
          }

          .dotted-separation {
            width: 3px;
            background-image: linear-gradient(to bottom, #ffffff00 50%, #4f6cd3bd 50%);
            background-position: top;
            background-size: 3px 12px;
            background-repeat: repeat-y;

            @include lg {
              height: 3px;
              width: 100%;
              background-image: linear-gradient(to left, #ffffff00 50%, #4f6cd3bd 50%);
              background-repeat: repeat-x;
              background-size: 12px 3px;
            }
          }
        }
      }
    }

    #ReviewsListRoot {
      padding: 40px 0;
      width: 100%;

      @include md {
        padding: 40px 20px;
      }
    }
  }

  .reviewsMattersSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 20px;

    @include lg {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 1080px;
      gap: 40px;
    }

    @include xl {
      max-width: 1280px;
    }

    .valetIllu {
      width: 80%;
      max-width: 500px;

      @include lg {
        grid-column: 2/3;
        justify-self: center;
      }
    }

    .titleWithDesc {
      grid-row: 1;
      grid-column: 1/2;
      h2,
      p {
        text-align: center;

        @include lg {
          text-align: left;
        }
      }

      p {
        &.primaryDesc {
          font-weight: bold;
        }
      }
    }
  }

  .ctaSection {
    padding: 50px 40px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 1080px;

    @include lg {
      padding: 50px 150px 80px;
    }

    @include xl {
      max-width: 1280px;
    }
  }

  .shareReviewsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $blue-blue;
    padding: 60px 30px;
    gap: 10px;

    @include lg {
      flex-direction: row;
      max-width: 1080px;
      padding: 60px 80px;
      border-radius: 20px;
      gap: 20px;
    }

    @include xxl {
      max-width: 1280px;
    }

    .illuContainer {
      display: flex;
      justify-content: center;
      width: 100%;

      @include lg {
        flex: 1;
      }
    }

    img {
      width: 80%;
      max-width: 400px;
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      flex: 1;

      h2,
      p {
        color: white;
        text-align: center;

        @include lg {
          text-align: left;
        }
      }

      .ctaContainer {
        width: 100%;

        p {
          font-weight: 600;
          margin: 20px 0 30px;
        }

        .ctaButtons {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          @include lg {
            flex-direction: row;
          }

          .shareLink {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #4169e177;
            border-radius: 30px;
            width: 100%;
            padding: 12px;
            max-width: 400px;

            &:hover {
              box-shadow: 0 8px 15px 0 rgba(0, 28, 134, 0.4);
              background-color: $warm-blue;
            }

            @include lg {
              &.mobile {
                display: none;
              }
            }

            &.desktop {
              display: none;

              @include lg {
                display: flex;
              }
            }

            .logo {
              width: 100px;

              @include lg {
                width: 80px;
              }
            }
          }
        }
      }
    }
  }
}


.reviewInline {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  padding: 0 0 4px;

  @include xl {
    display: none;
  }

  .rateAmount {
    margin-top: 5px;
    color: $dark-blue;
    font-weight: 600;
  }

  .rateStars {
    .starsContainer {
      .star path {
        fill: $sun-yellow;
      }
      .star.unfilled path {
        fill: #{$sun-yellow}59;
      }
    }
  }

  .totalReviews {
    font-size: 12px;
    color: $dark-blue;

    span {
      font-weight: 700;
    }
  }
}
