.complementaryServices {
	.services {
		margin-top: 50px;

		@media #{$phoneL} {
			margin-top: 30px;
		}

		.service {
			@extend .clearfix;
			margin: 0 auto 35px;
			max-width: 700px;

			.visuel {
				float: left;
				margin-right: 30px;
				width: 35px;
				img {
					width: 35px;
				}

				@media #{$phoneL} {
					width: 40px;
					margin-right: 20px;

					img {
						width: 40px;
					}
				}
			}
			.detail {
				float: left;
				width: calc( 100% - 90px);
				font-size: em(15px);

				h3 {
					margin: 0 0 10px 0;
					padding: 0;
					color: $blue-blue;
					text-transform: uppercase;
          font-weight: bold;
          font-size: inherit;
				}
				p {
					margin: 0;
					padding: 0;

					strong {
						color: $dark-blue;
					}
				}
			}
		}
	}
}
