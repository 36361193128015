.reassuranceWrapper {
  .reassurance {
    width: 95%;
    max-width: 820px;
    margin: 25px auto 50px;

    .needHelpTitle {
      font-weight: 700;
      font-size: em(22px);
      line-height: em(40px);
      color: $dark-blue;
    }

    .needHelpContent {
      font-size: em(16px);
      line-height: em(32px);
      color: $dark-blue;
      margin-bottom: 40px;

      a {
        color: $dark-blue;
        font-weight: 600;
        font-size: em(16px);
      }
    }

    .advantages {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 40px;

      .advantage {
        border-radius: 20px;
        border: 1px solid $light-blue-grey;
        padding: 20px 10px;
        background: white;
        text-align: center;
        flex-basis: 0;
        flex-shrink: 1;
        flex-grow: 1;
        min-width: 0;

        & > img {
          width: 42px;
          margin-bottom: 10px;
        }

        & > div {
          font-weight: 600;
          font-size: em(16px);
          color: $dark-blue;
        }

        & > p {
          display: none;
          font-weight: 500;
          color: $dark-blue;
          margin: 0;
        }
      }
    }

    .methodsAvailable {
      text-align: center;

      img {
        margin: 2px 4px;
        width: 50px;
      }
    }
  }
}

@include md {
  .reassuranceWrapper {
    .reassurance {
      .needHelpTitle {
        font-size: em(26px);
        line-height: em(48px);
      }

      .needHelpContent {
        font-size: em(17px);
        line-height: em(36px);
        a {
          font-size: em(17px);
        }
      }

      .advantages {
        flex-direction: row;
        gap: 40px;

        .advantage {
          flex-basis: 33.33%;
          padding: 20px;

          & > div {
            font-size: em(21px);
            margin-bottom: 10px;
          }
          & > p {
            display: block;
          }
        }
      }
    }
  }
}

@media #{$desktop} {
  .reassuranceWrapper {
    width: calc(100% - 316px);

    .reassurance {
      margin: 75px auto 75px;
    }
  }
}
