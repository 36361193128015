#wrapper .landing.landing-sea {
  display: block;

  h2 {
    span {
      font-weight: 600;
    }
  }

  .lp-boxed {
    max-width: 1380px;
    margin: 0 auto;
    padding: clamp(10px, 5%, 50px);

    @include md {
      padding: clamp(10px, 10%, 80px);
    }
  }

  .lp-sea-price {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include md {
      flex-direction: row;
      .price-content, .price-graph {
        flex-basis: 50%;
      }
    }

    .price-graph {
      text-align: center;

      .graph-title {
        color: $blue-blue;
        font-weight: bold;
        letter-spacing: 1.07px;
        text-transform: uppercase;
      }
      .graph-legend {
        font-size: 14px;
        color: $blue-grey;
        font-weight: 400;
        font-style: italic;
        margin: 10px auto 0;
        @include lg {
          max-width: 60%;
        }
      }
    }

    .price-cta {
      text-align: center;
      margin: 40px 0;

      @include md {
        text-align: left;
        margin: 0;
      }
    }

  }

  .lp-sea-reviews {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: clamp(10px, 10%, 80px);

    @include lg {
      gap: 50px;
    }

    h2 {
      text-align: center;
    }

  }

  .lp-sea-talk-about-us {
    background-color: #f5faff;

    .talk-about-us {
      gap: 20px 0;
    }

    @include lg {
      h2 {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    ul {
      margin: 0;

      li {
        margin: 0 !important;
      }
    }
  }

  .lp-sea-how-it-works {
    .action {
      margin: auto;
    }
  }

  .lp-sea-services {
    background-color: #f5faff;

    .lp-boxed {
      display: flex;
      flex-direction: column;

      @include lg {
        flex-direction: row;
      }

      > div, h2 {
        flex-basis: 50%;
      }
    }

    .services {
      display: flex;
      justify-content: space-evenly;

      .service {
        display: flex;
        flex-direction: column;
        flex-basis: 33.33%;
        justify-content: center;
        align-items: center;
        align-self: end;

        img {
          max-width: 100px;
        }

        h3 {
            font-size: 21px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.9;
            letter-spacing: normal;
            text-align: center;
            color: $dark-blue;
        }
      }
    }
  }
}
