$boxedSize: 1040px;

.bookingSuccess {
  background-image: linear-gradient(to bottom, rgba(20, 55, 185, 0.16), rgba(246, 249, 251, 0.76) 47%);
  margin: 0;
  padding: 40px 0;

  h3 {
    color: $dark-blue;
    font-size: em(20px);
    line-height: normal;
    margin: 0;
    text-align: center;

    @include md {
      font-size: em(24px);
    }

    img {
      vertical-align: middle;
      margin-right: 10px;
    }

    &.light {
      font-weight: 400;
      color: #4169e1;
      margin-top: 6px;
    }
  }

  .successMessage,
  .successSummary,
  .successNextSteps,
  .successBookingUpdate,
  .successSponsorship,
  .successCta {
    max-width: $boxedSize;
    margin: 40px auto;

    @include xl {
      margin: 60px auto;
    }
  }

  @media screen and (min-width: $boxedSize) {
    .successMessage,
    .summaryHeader,
    .summaryBody,
    .successNextSteps,
    .successBookingUpdate,
    .successSponsorship {
      border: solid 1px #f2f6f9;
    }
  }

  .successMessage {
    background: white;
    margin: 20px auto;
    padding: 40px 20px;

    @media screen and (min-width: $boxedSize){
      border-radius: 20px;
    }

    @include md {
      padding: 40px;
    }

    .miniSummary {
      display: flex;
      gap: 8px;
      justify-content: space-evenly;
      border: solid 0.5px $light-blue-grey;
      border-radius: 10px;
      background-color: rgba(242, 246, 249, 0.3);
      margin: 20px 0;
      padding: 10px;

      @include md {
        gap: 20px;
        margin: 40px;
        padding: 20px;
      }

      & > div > div:first-child {
        font-weight: 600;
        color: $dark-blue;
        padding: 10px;
        text-transform: uppercase;
      }
      & > div > div:last-child {
        padding: 10px;
      }
    }

    .successB2b {
      color: $dark-blue;
      margin: 20px 10px;

      @include lg {
        margin: 40px;
      }
    }

    .ctaSummary {
      text-align: center;
    }
  }

  .successSummary {
    .summaryHeader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $warm-blue;
      color: $white;
      padding: 60px 40px;
      gap: 15px;

      @include md {
        flex-direction: row;
        gap: 20px;
      }

      @media screen and (min-width: $boxedSize){
        border-radius: 20px 20px 0 0;
      }

      .departure,
      .arrival {
        display: flex;
        flex-direction: column;
        gap: 10px 0;
        align-items: center;

        .title {
          background: $white;
          color: $warm-blue;
          width: 100%;
          font-weight: 600;
          text-align: center;
          padding: 6px 0;
          text-transform: uppercase;
          letter-spacing: 1.6px;
        }
        .hours {
          font-size: em(30px);
          letter-spacing: 2px;
        }
        .date {
          font-weight: bold;
          letter-spacing: 1.2px;
        }
        .site {
          font-size: em(12px);
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 1.6px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .dots {
        transform: rotate(90deg);
        margin: 30px 0;

        &:nth-child(4) {
          display: none;
        }

        @include md {
          transform: none;
          margin: 0;

          &:nth-child(4) {
            display: block;
          }
        }
      }

      .duration {
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: em(16px);
        flex-direction: column;
        align-items: center;
        gap: 10px;
        display: none;

        @include md {
          display: flex;
        }

        span {
          text-align: center;
        }
      }

    }
    .summaryBody {
      background: $white;
      padding: 40px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      gap: 20px;
      text-align: center;

      @include md {
        flex-direction: row;
        text-align: left;
      }

      @media screen and (min-width: $boxedSize){
        border-radius: 0 0 20px 20px;
      }

      .title {
        color: $dark-blue;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 11px;
        margin-bottom: 4px;
      }

      > div > div {
        padding: 4px 0;
      }

      .services > div:not(.title)::first-letter {
        text-transform: uppercase;
      }

    }
  }

  .successNextSteps {
    background: white;
    padding: 60px 40px;

    @media screen and (min-width: $boxedSize){
      border-radius: 20px;
    }

    .steps {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      @include md {
        flex-direction: row;
      }

      .step {
        background-color: $white;
        border: solid 0.5px #efefef;
        border-radius: 20px;
        padding: 20px;
        color: $dark-blue;
        text-align: center;

        .stepTime {
          margin: 20px 0 0;
          font-weight: 600;
          font-size: em(18px);
          color: $dark-blue;
          text-transform: capitalize;
        }

        .stepIcon {
          background: $warm-blue;
          border-radius: 100%;
          width: 90px;
          height: 90px;
          margin: 0 auto;
          position: relative;

          img {
            width: 100%;
            padding: 20px;
            @include md {
              padding: 30px;
            }
          }

          &::before {
            position: absolute;
            background: white;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            line-height: 30px;
            top: -5px;
            left: -5px;
            border: 2px solid #4169e1;
            color: #4169e1;
            font-weight: bold;
            font-size: 16px;
          }

          @include md {
            width: 120px;
            height: 120px;
          }
        }

        &:nth-child(1) .stepIcon::before {
          content: "1";
        }
        &:nth-child(2) .stepIcon::before {
          content: "2";
        }
        &:nth-child(3) .stepIcon::before {
          content: "3";
        }
      }
    }
  }

  .successBookingUpdate {
    background: white;
    margin: 20px auto;
    padding: 60px 40px;

    @media screen and (min-width: $boxedSize){
      border-radius: 20px;
    }

    p {
      padding: 20px;
      font-size: 16px;
      line-height: 28px;

      @include md {
        padding: 20px 80px;
      }

      a {
        font-size: 16px;
        text-decoration: underline;
        color: $blue-blue;

        &:hover {
          color: $dark-blue;
        }
      }
    }

    > div {
      text-align: center;
    }
  }

  .successSponsorship {
    background: white;
    padding: 40px 20px;

    @media screen and (min-width: $boxedSize){
      border-radius: 20px;
    }

    @include md {
      padding: 40px;
    }

    .my-code {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin: 40px 0 20px;

      > div {
        display: flex;
        flex-direction: column;

        @media #{$tab-portrait} {
          padding-top: 20px;
        }
      }

      strong {
        display: block;
        font-size: em(11px);
        font-weight: 600;
        color: $dark-blue;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .code {
        span {
          display: block;
          width: 150px;
          height: 40px;
          background: $warm-blue;
          font-size: em(26px);
          font-weight: bold;
          letter-spacing: 2px;
          color: $white;
          text-transform: uppercase;
          line-height: em(40px);
          text-align: center;
        }
      }

      .sms {
        strong {
          padding: 0 0 0 10px;
        }
        form {
          display: flex;

          input {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .successCta {
    text-align: center;
  }

}
