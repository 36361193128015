// Import des variables
@import 'var';
@import 'mixins';

// Import des ressources
@import '~compass-sass-mixins/lib/animate';
@import '~compass-sass-mixins/lib/compass';
@import '~@bluevalet/datepicker/dist/datepicker';
@import '~@bluevalet/common-ui/dist/index.css';
@import '~magnific-popup/dist/magnific-popup.css';
@import '~intl-tel-input/build/css/intlTelInput.css';

// Imports des modules
@import 'modules/fonts';
@import 'modules/reset';
@import 'modules/ratingReviews.scss';
@import 'modules/form';
@import 'modules/button';
@import 'modules/datepicker';
@import 'modules/message';
@import 'modules/richeText';
@import 'modules/iconsList';
@import 'modules/cookies';
@import 'modules/popup';
@import 'modules/loader';
@import 'modules/pagination';
@import 'modules/chip';
@import 'modules/_phonenumber.scss';
@import 'modules/_img.scss';

// Partials
@import 'partials/body';
@import 'partials/wrapper';
@import 'partials/howitworks';
@import 'partials/mainContainer';
@import 'partials/customerReviews';
@import 'partials/customerReviewslist';
@import 'partials/sitesSlider';
@import 'partials/reviews';
@import 'partials/header';
@import 'partials/footer';
@import 'partials/account';
@import 'partials/subscription';
@import 'partials/blog';
@import 'partials/login';
@import 'partials/contact';
@import 'partials/downloadFile';
@import 'partials/joinus';
@import 'partials/bvforbusiness';
@import 'partials/complementaryServices';
@import 'partials/businesssignup';
@import 'partials/businesslogin';
@import 'partials/cgv';
@import 'partials/landing';
@import 'partials/landingValet';
@import 'partials/landingParking';
@import 'partials/landingParkingAirport';
@import 'partials/landingAds';
@import 'partials/landingOffer';
@import 'partials/landingLongStay';
@import 'partials/landingPrice';
@import 'partials/payment';
@import 'partials/howitworksshema';
@import 'partials/error';
@import 'partials/smart-app-banner';
@import 'partials/home/home';
@import 'partials/home/advantages';
@import 'partials/home/establishment';
@import 'partials/faq';
@import 'partials/talkaboutus';
@import 'partials/media';
@import 'partials/modalInsuranceUpselling';
@import 'partials/partners';
@import 'partials/recruitement';
@import 'partials/company';
@import 'partials/promoCode';
@import 'partials/recaptcha';
@import 'partials/customerReviewsContainer';
@import 'partials/landingAirports';
@import 'partials/cookieConsent';
@import 'partials/cookieConsentModal';

// Booking
@import 'partials/fastbooking';
@import 'partials/fastbookingForm';
@import 'partials/fastbookingLandingpage';
@import 'partials/scrollMenu';
@import 'partials/bookingLogin';
@import 'partials/booking';
@import 'partials/availableServices';
@import 'partials/summary';
@import 'partials/bookingOptions';
@import 'partials/bookingUserInformations';
@import 'partials/bookingTravelInfo';
@import 'partials/bookingTotal';
@import 'partials/bookingReassurance';
@import 'partials/bookingSuccess';
