.lpAirports {

  h2 {
    color: $dark-blue;
    margin: 0 0 20px;
    font-weight: 600;

    font-size: em(26px);
    line-height: em(32px);

    @include md {
      font-size: em(32px);
      line-height: em(40px);
    }

    @include xxl {
      font-size: em(38px);
      line-height: em(45px);
    }

    span {
      font-weight: 700;
    }
  }

  p {
    margin: 0 0 20px 0;
    line-height: 28px;
    font-size: 16px;

    @include lg {
      line-height: 25px;
      font-size: 15px;
    }

    span {
      color: $blue-blue;
    }

    a {
      font-size: 16px;
      color: $blue-blue;

      @include lg {
        font-size: 15px;
      }

      &:hover {
        text-decoration: underline;
        color: $dark-blue;
      }
    }
  }

  .contentBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    @include lg {
      gap: 40px;
      flex-direction: row;
    }

    &:first-of-type {
      img {
        display: none;
        @include lg {
          display: initial;
        }
      }
    }

    &> div {
      text-align: center;

      img {
        max-height: 200px;
        @include lg {
          max-height: 250px;
        }
      }
    }
  }

  .contentSitesBlock {
    .contentSite {
      display: flex;
      margin: 40px 0;
      gap: 40px;

      flex-direction: column;

      @include md {
        flex-direction: row;
      }

      &:first-of-type {
        margin-top: 0;
      }

      .siteCardRoot {
        position: relative;
        overflow: hidden;
        flex-basis: 300px;
        height: 400px;
        border-radius: 20px;
      }

      .siteCard {
        display: inline;
      }

      .siteCardContent {
        flex-basis: calc(100% - 300px);
      }
    }
  }

}
