.bv-for-business {
	.page-header {
		h1 {
			text-transform: capitalize;
		}

		.image {
			> img {
				border-radius: 10px;
			}
		}

		.button {
			margin-top: 10px;

			&.second {
				margin-right: 10px;
			}

			@media #{$phoneL} {
				&.second {
					padding: 14px 30px 12px 10px;
					margin-right: 0;

					.arrow {
						right: 12px;
					}
				}

				&:last-of-type {
					margin: 10px;
				}
			}
		}
	}

	.with-dot {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				position: relative;
				margin-bottom: 15px;
				padding-left: 25px;

				&:before {
					position: absolute;
					top: 7px;
					left: 0;
					content: '';
					display: block;
					height: 5px;
					width: 5px;
					background: $dark-blue;
				}
			}
		}
	}

	.cards {
		display: flex;
		padding: 50px 0;

		@media #{$tab-portrait} {
			flex-direction: column;
			align-items: center;
		}

		@media #{$phoneL} {
			padding: 20px 0;
		}

		.card {
			display: flex;
			flex-direction: column;

			@media #{$tab-portrait} {
				max-width: 445px;
			}

			.title {
				min-height: 65px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 10px 10px 0 0;

				h2 {
					margin: 10px;
					font-size: em(22px);
					color: $white;
					font-weight: 600;
				}
			}

			.content {
				flex: 1 1 auto;
				padding: 20px 50px 30px;
				background: $white;
				border: 0.3px solid $light-blue-grey;
				border-top: none;
				border-radius: 0 0 10px 10px;
			}

			&.admin {
				padding-right: 25px;

				@media #{$tab-portrait} {
					padding-right: 0;
				}

				.title {
					background-color: $blue-blue;
				}
			}

			&.worker {
				padding-left: 25px;

				@media #{$tab-portrait} {
					padding-top: 50px;
					padding-left: 0;
				}

				@media #{$phoneL} {
					padding-top: 20px;
				}

				.title {
					background-color: $warm-blue;
				}
			}
		}
	}

	.price {
		display: flex;
		padding-top: 0;

		@media #{$phoneL} {
			flex-direction: column;
		}

		.image {
			text-align: center;

			p {
				font-size: em(16px);
				font-weight: bold;
				color: $dark-blue;
				text-align: center;
				letter-spacing: 1.1px;
				text-transform: uppercase;
			}

			img {
				height: 330px;
			}

			@media #{$tab-portrait} {
				img {
					max-width: 325px;
				}
			}

			@media #{$phoneL} {
				img {
					max-width: 100%;
				}
			}
		}

		.content {
			padding-left: 50px;

			@media #{$tab-portrait} {
				padding-left: 25px;
			}

			@media #{$phoneL} {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-left: 0;

				h2 {
					text-align: center;
				}
			}

			h2 {
				margin-bottom: 20px;
				font-size: em(21px);
				line-height: em(32px);
				color: $dark-blue;
				font-weight: 600;

				@media #{$phoneL} {
					margin-top: 40px;
				}
			}

			a {
				margin-top: 50px;

				@media #{$phoneL} {
					margin-top: 10px;
				}
			}
		}
	}

	.why-bvb {
		border-radius: 20px;

		h2 {
			margin-bottom: 10px;
		}

		.row {
			.column {
				width: 33%;
				float: left;

				&:nth-child(1) {
					padding: 60px 30px 60px 0;
				}

				&:nth-child(2) {
					padding: 60px 30px;
				}

				&:nth-child(3) {
					padding: 60px 0 60px 30px;
				}

				@media #{$phoneL} {
					width: 100%;
					padding: 30px 10px !important;
					text-align: center;
				}

				img {
					width: 50px;
				}

				h3 {
					margin-top: 25px;
					font-size: em(16px);
					text-transform: uppercase;
				}

				p {
					font-size: em(15px);
				}
			}
		}
	}

	.testimony {
		padding-top: 100px;

		.block {
			display: flex;
			flex-wrap: wrap;
			background-color: $white;
			border: 0.5px solid $light-blue-grey;
			border-radius: 10px;
			margin-bottom: 50px;
			overflow: hidden;

			.content {
				width: 50%;
				padding: 40px;

				@media #{$phoneL} {
					width: 100%;
					padding: 20px;
				}

				h2 {
					margin: 0 0 15px;
					color: $dark-blue;
					font-size: em(20px);
					font-weight: bold;
					text-transform: uppercase;
					letter-spacing: 1.8px;
				}

				p {
					font-size: em(15px);
					line-height: em(25px);

					&.text {
						margin-bottom: 20px;
					}

					&.name, &.function {
						margin: 0;
						font-weight: 600;
					}

					&.name {
						color: $dark-blue;
					}

					&.function {
						color: $blue-grey;
					}
				}
			}

			.visuel {
				position: relative;
				width: 50%;
				overflow: hidden;

				@media #{$phoneL} {
					width: 100%;
					height: 230px;
				}

				img {
					position: absolute;
					top: 0;
					left: 0;
					min-height: 100%;
					min-width: 100%;
					max-width: 100%;
					object-fit: cover;

					@media #{$phoneL} {
						top: -50%;
					}
				}

				.wave {
					position: absolute;
					top: -1px;
					bottom: -1px;
					right: -2px;
					width: 38px;
					background: url('../../static/images/waves/left.png') center no-repeat;
					background-size: 100% 100%;

					@media #{$phoneL} {
						top: auto;
						bottom: -2px;
						left: -2px;
						right: -2px;
						height: 46px;
						width: auto;
						background: url('../../static/images/waves/horizontal.png') bottom no-repeat;
						background-size: 100%;
					}
				}
			}
		}
	}

	.bvb-contact {
		padding-top: 0;
		text-align: center;

		p {
			padding-top: 5px;
			font-size: em(15px);
			color: $dark-blue;

			a {
				padding-left: 5px;
				font-weight: bold;
				color: $blue-blue;
				transition: color 0.15s;

				&:hover {
					color: $dark-blue;
				}
			}
		}
	}
}

.landingBvForBusiness {
	.description {
		margin-bottom: 50px;
		.boxed {
			position: relative;
			z-index: 2;
		}
		h2 {
			color: #fff;
			text-align: center;
			font-size: em(40px);
			font-weight: 400;
			margin: 0 0 20px;
			padding: 0;
			line-height: 3rem;
			text-transform: uppercase;
		}
		p {
			color: #fff;
			text-align: center;
			font-size: em(22px);
			font-weight: 400;
			margin: 0 0 20px;
			padding: 0;
		}
		.action {
			background: rgba(255, 255, 255, 0.85);
			padding: 20px;
			p {
				color: $primary;
				font-size: em(16px);
			}
			.center {
				text-align: center;
			}
			form {
				@extend .clearfix;
				margin: 0 auto 20px;
				max-width: 510px;
				// width: 510px;
				input[type=email] {
					width: 50%;
					float: left;
				}
				input[type=submit] {
					width: 50%;
					float: left;
					color: #FFF;
					padding: 6px 0;
					background-color: $primary;
				}
			}
		}
		&.hasVisuel {
			background-image: url('../../static/images/visuels/landing-bluevalet-for-business.jpg');
			background-size: cover;
			background-position: center;
			position: relative;
			padding: 50px 0;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				content: '';
				z-index: 1;
				background: rgba(0,0,0,0.4);
			}
			@media #{$phoneL} {
				padding: 92px 0 20px;
				h2 {
					font-size: em(26px);
				}
				p {
					font-size: em(16px);
				}
				.action form {
					margin-bottom: 0;
					input {
						width: 100%;
						margin-bottom: 20px;
						display: block;
					}
				}
			}
			.contain {
				max-width: 600px;

			}
		}
	}
}
