#wrapper {
  position: relative;

  @extend .clearfix;

  & > h1,
  & > h2,
  .h1,
  .h2 {
    font-weight: bold;
    font-size: em(42px);
    padding: 50px 0 0;
    margin: 0;
    text-align: center;
    color: $dark-blue;
    line-height: em(55px);

    &.blue-blue {
      color: $blue-blue;
    }

    .button,
    button {
      margin-top: 20px;
    }

    @media #{$phoneL} {
      padding: 26px 0;
    }
  }
}

.grey-background {
  background-color: $pale-grey;
}

.dark-blue-background {
  background-color: $dark-blue;
  color: $white;

  h2 {
    font-size: em(36px);
    line-height: em(45px);
    margin: 25px 0;
    font-weight: 600;

    @media #{$phoneL} {
      text-align: center;
    }
  }
}

.boxed {
  @extend .clearfix;
  max-width: 1040px;
  padding: 50px;
  margin: 0 auto;
  @media #{$phoneL} {
    padding: 20px;
  }
}

.page-header {
  position: relative;

  .boxed {
    display: flex;
    padding: 50px 25px;
  }

  .image {
    margin-right: 100px;

    img {
      width: 445px;
    }
  }

  .titles {
    h1 {
      padding-top: 10px !important;
      text-align: left !important;

      @media #{$phoneL} {
        padding-top: 40px !important;
      }
    }

    .subtitle {
      padding-top: 20px;
      font-size: em(21px);
      line-height: em(32px);
      color: $dark-blue;
      font-weight: 600;
    }

    .description {
      padding-bottom: 40px;
      font-weight: em(15px);
      line-height: em(25px);
    }
  }

  @media #{$tab-portrait} {
    display: block;

    .boxed {
      padding: 30px 25px 50px;
    }

    .image {
      position: absolute;
      top: 40px;
      left: -151px;

      img {
        width: 374px;
      }
    }

    .titles {
      min-height: 500px;
      padding-left: 273px;
    }
  }

  @media #{$phoneL} {
    .image {
      top: 0;
      left: 0;
      width: 100%;
      height: 180px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit: cover;
        transform: translateY(-45px);
      }
    }

    .titles {
      padding-left: 0;
      padding-top: 150px;
      text-align: center;

      h1 {
        text-align: center !important;
      }

      .subtitle {
        padding-top: 0;
      }

      .description {
        padding-bottom: 20px;
      }
    }
  }
}

.display-md {
  display: none;

  @include md {
    display: initial;
  }
}

.full-page {
  min-height: calc(100vh - #{$headerHeightFull});

  &.with-fixed-btn {
    @media #{$tab-portrait} {
      margin-bottom: $fixedButtonHeight;
    }
  }
}

.noHeader .full-page {
  min-height: 100vh;
}
