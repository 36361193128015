.lpOffer {

  .lpBoxed {
    &.reduced {
      @include md {
        padding: clamp(10px, 10%, 20px) clamp(10px, 10%, 80px);
      }
    }
  }

  p {
    margin: 0 0 20px 0;
    line-height: 28px;
    font-size: 16px;

    span {
      color: $warm-blue;
    }

    a {
      font-size: 16px;
      color: $warm-blue;

      &:hover {
        text-decoration: underline;
        color: $blue-blue;
      }
    }

    b {
      font-weight: 600;
    }
  }

  h1 {
    color: $dark-blue;
    font-weight: 600;
    margin: 20px 0;

    font-size: em(26px);
    line-height: em(32px);

    @include md {
      font-size: em(32px);
      line-height: em(40px);
    }

    @include xxl {
      font-size: em(38px);
      line-height: em(45px);
    }
  }

  h2 {
    color: $dark-blue;
    font-weight: 600;
    margin: 0 0 20px;

    font-size: em(26px);
    line-height: em(32px);

    @include md {
      font-size: em(32px);
      line-height: em(40px);
    }

    @include xxl {
      font-size: em(38px);
      line-height: em(45px);
    }
  }

  h3 {
    color: $dark-blue;
    font-weight: 500;
    margin: 0 0 20px;

    font-size: em(20px);
    line-height: em(26px);

    @include md {
      font-size: em(28px);
      line-height: em(32px);
    }

    @include xxl {
      font-size: em(32px);
      line-height: em(40px);
    }
  }

  .lpIllus {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;

    @include lg {
      flex-direction: row;
      gap: 50px;
    }

    .lpIllusContent {
      flex-grow: 1;
      flex-basis: 100%;

      @include lg {
        flex-basis: 75%;
      }

    }
    .lpIllusImage {
      flex-grow: 1;
      flex-basis: 100%;

      @include lg {
        flex-basis: 25%;
      }
      img {
        width: 100%;
        min-height: 250px;
      }
    }
  }

}

.lpOfferValet {

  .contentBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    @include lg {
      gap: 40px;
      flex-direction: row;
    }

    &:first-of-type {
      img {
        display: none;
        @include lg {
          display: initial;
        }
      }
    }

    &> div {
      text-align: center;

      img {
        max-height: 200px;
        @include lg {
          max-height: 250px;
        }
      }
    }
  }

  .contentSite {
    margin: 40px 0;

    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .lpIllus {
    &.ourParks {
      .lpIllusContent {
        @include lg {
          flex-basis: 60%;
        }
      }

      .lpIllusImage {
        @include lg {
          flex-basis: 40%;
        }
      }
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 0;
    gap: 40px;

    @include md {
      flex-direction: column;
    }

    @include xl {
      flex-direction: row;
    }

    .step {
      background-image: linear-gradient(174deg,#f2f6f9 5%,rgba(242,246,249,.6) 96%);
      flex-basis: 100%;
      border-radius: 20px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 20px;
      text-align: justify;

      @include md {
        flex-direction: row;
      }

      @include lg {
        flex-basis: 33.33%;
      }

      @include xl {
        flex-direction: column;
      }

      .visuel {
        min-width: 145px;
        text-align: center;
        @include md {
          text-align: left;
        }
        @include xl {
          text-align: center;
        }

        img {
          max-height: 100px;

          @include xs {
            max-height: 120px;
          }
        }
      }

      .step-text {
        width: 100%;

        @include md {
          width: calc(100% - 120px);
        }

        @include xl {
          width: 100%;
        }

        p {
          color: $black;
          b {
            font-weight: 600;
          }
        }
      }
    }
  }

}

.lpOfferParking {

  #HowItWorks {
    h2 {
      padding: 0;
    }
    .howItWorks .steps .step .step-content .step-text span {
      margin: 0;
      padding: 10px 0;
      color: $blue-blue;
      font-size: 27px;
      font-weight: 700;
      text-transform: capitalize;
      letter-spacing: normal;
    }
  }

  .sitesParking {

    .sitesParkingDesc {
      margin: 0 auto 40px;
      @include lg {
        width: 70%;
        text-align: center;
      }
    }

    .sitesParkingList {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      .contentSite {
        flex-grow: 1;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;

        @include xl {
          flex-basis: calc(50% - 15px);
        }

        .siteContainer {
          border-radius: 20px;
          background-image: linear-gradient(170deg, $pale-grey 12%, rgba(242, 246, 249, 0.6) 90%);
          padding: 40px 20px;
          display: flex;
          flex: 1;
          gap: 20px;
          align-items: center;

          .siteImg {
            position: relative;
            overflow: clip;
            border-radius: 100%;
            width: 175px;
            height: 175px;

            img {
              object-fit: cover;
              object-position: 50% 30%;
              width: 100%;
              height: 100%;
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-image: linear-gradient(to top, #4169e1 6%, rgba(216, 216, 216, 0) 44%);
            }
          }

          p {
            flex-basis: calc(100% - 220px);
            margin: 0;
            color: $dark-blue;

            @include lg {
              font-size: em(15px);
            }
          }
        }
      }
    }
  }

  .reviewsDesc {
    margin: 0 auto 40px;
    @include lg {
      width: 70%;
      text-align: center;
    }
  }

  #ExternalRatingsRoot {
    margin: 40px 0;

    .reviewsContainer {
      .review {
        background-color: $pale-grey;
      }
    }
  }
  #ReviewsRoot {
    margin: 20px 0;
  }

}
