.lpPrice {
  p strong {
    font-weight: 600;
  }

  @include md {

    &.lpBoxed {
      padding: 40px;
    }
  }
  @include lg {
    &.lpBoxed {
      max-width: 100%;
      padding: 0;

      .lpBlockDouble,
      .lpBlock {
        max-width: 1380px;
        margin: 0 auto;

        padding: clamp(10px, 5%, 50px);
        @include md {
          padding: clamp(10px, 10%, 40px) clamp(10px, 10%, 80px);
        }
      }

      .lpBlockDouble .lpBlock {
        padding: 0;

        &:last-of-type {
          padding-top: clamp(10px, 5%, 40px);
        }
      }

      .lpBlockWrapper {
        margin: 20px 0;

        &.pale-grey {
          background-color: $pale-grey;
        }
      }
    }
  }

  .lpBlockDouble {
    display: flex;

    .blockExtended {
      flex: 1 1 100%;
    }

    .visualExtended {
      display: none;
    }

    @include lg {
      .blockExtended {
        flex: 1 1 70%;
      }
      .visualExtended {
        flex: 1 1 30%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @include xl {
      .blockExtended {
        flex: 1 1 75%;
      }
      .visualExtended {
        flex: 1 1 25%;
      }
    }
  }

  .floating {
    float: left;
    margin: 0 20px 20px 0;
  }

  .priceTitle {
    color: $blue-blue;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.07px;
    text-transform: uppercase;
  }

  .priceLegend {
    flex-basis: 100%;
    font-size: 14px;
    color: $blue-grey;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    margin-top: 10px;
  }

  .priceTables {
    width: 100%;

    p em {
      font-size: 11px;
    }

    > div {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      width: 100%;
      flex-wrap: wrap;

      > div {
        background: #4169e1;
        max-width: 400px;
        margin: 20px 0;
        color: white;
        font-weight: 600;
        border-radius: 50px;
        padding: 20px 30px 30px 30px;

        @include lg {
          margin: 50px;
          width: calc(50% - 2 * 50px);
        }

        p.title {
          text-align: center;
          font-weight: 600;
          font-size: 18px;

          @include lg {
            font-size: 20px;
          }
        }

        table {
          width: 100%;
          border-collapse: collapse;
          margin: auto;

          tr {
            border-bottom: 1px solid #f2f6f9;

            td {
              width: 50%;
              padding: 20px 0 0 0;

              &:last-of-type {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
