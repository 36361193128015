@import 'src/styles/var';

#review-in-layout {
  margin: 0 auto;
  max-width: 800px;
  height: 100vh;
  padding: 30px;

  &.withBanner {
    height: calc(100vh - #{$smart-banner-height});
  }

  > img {
    display: block;
    margin: 0 auto;
    width: 300px;
  }
}