.pagination {
  display: flex;
  justify-content: center;
  padding: 0;

  li {
    position: relative;
    display: flex;
    height: 29px;
    width: 30px;
    margin: 0 10px;
    border: 1px solid $blue-grey;
    border-radius: 50%;
    font-size: em(15px);
    font-weight: 600;
    list-style: none;
    letter-spacing: 1.3px;
    line-height: em(15px);
    text-align: center;
    transition: all 0.15s ease-in-out;

    a,
    button,
    span {
      width: 100%;
      height: 100%;
      color: $blue-grey;
      padding: 8px 10px;
    }

    button {
      border: none;
      margin: 0;
      font-size: 15px;
      padding: 8px 10px 6px;
    }

    &.active {
      border-color: $blue-blue;
      background-color: $blue-blue;

      span {
        color: $white;
      }

      &:hover {
        color: $white;
      }
    }

    &:hover {
      border-color: $blue-blue;
      color: $blue-blue;

      a,
      button {
        color: $blue-blue;
      }
    }

    &.prev,
    &.next {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 9px;
        height: 1px;
        width: 10px;
        background-color: $blue-grey;
      }

      a,
      button {
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          height: 1px;
          width: 6px;
          background-color: $blue-grey;
        }

        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }

      &:hover {
        &::after {
          background-color: $blue-blue;
        }

        a,
        button {
          &::before,
          &::after {
            background-color: $blue-blue;
          }
        }
      }
    }

    &.prev {
      a,
      button {
        &::before,
        &::after {
          transform-origin: left;
          left: 9px;
        }
      }
    }

    &.next {
      a,
      button {
        &::before,
        &::after {
          transform-origin: right;
          right: 9px;
        }
      }
    }
  }
}
