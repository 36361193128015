.datepicker-container {
  border: none;
  border-radius: 1px;
  overflow: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 212px;
}

.datepicker-top-left,
.datepicker-top-right {
  border-top-color: #dddddd;

  &:before {
    border-bottom-color: #dddddd;
  }
}

.datepicker-top-left:after,
.datepicker-top-right:after {
  display: none;
}

.datepicker-bottom-left,
.datepicker-bottom-right {
  border-bottom-color: #dddddd;

  &:before {
    border-top-color: #dddddd;
  }
}

.datepicker-panel {
  > ul {
    background: $dark-blue;
    width: 100%;

    &:first-child {
      li,
      li:hover {
        background: $dark-blue;
        font-weight: bold;
        color: $white;
        text-transform: uppercase;
      }
    }

    &[data-view="week"] {
      background: $white;
      border-left: 0.5px solid $light-blue-grey;
      border-right: 0.5px solid $light-blue-grey;

      > li {
        font-weight: 600;
        color: $blue-grey;
        font-size: em(11px);
      }
    }

    &[data-view="days"] {
      padding: 3px;
      background: $white;
      border-left: 0.5px solid $light-blue-grey;
      border-right: 0.5px solid $light-blue-grey;
      border-bottom: 0.5px solid $light-blue-grey;

      > li {
        width: 27px;
        height: 27px;
        margin: 1px;
        border: 1px solid $white;
        border-radius: 50%;
        font-weight: normal;
        color: $dark-blue;
        font-size: em(12px);
        line-height: em(26px);

        &:hover {
          border: 1px solid $blue-blue;
          background: $white;
          font-weight: normal;
          color: $blue-blue;
        }

        &.muted {
          visibility: hidden;
          display: none;
          margin-top: 0;
          margin-bottom: 0;

          &[data-view="day prev"] {
            display: block;
          }
        }

        &.disabled {
          color: #a0abb3;
          background: $white;

          &:hover {
            color: #a0abb3;
            border: 1px solid $white;
            background: $white;
          }
        }

        &.highlighted {
          border: 0.5px solid $white;
          background: $white;
          color: $dark-blue;

          &:hover {
            border: 0.5px solid $blue-blue;
            background: $white;
            font-weight: normal;
            color: $blue-blue;
          }
        }

        &.picked {
          border: 0.5px solid $blue-blue;
          background: $blue-blue;
          font-weight: 600;
          color: $white;
        }
      }
    }

    &[data-view="months"],
    &[data-view="years"] {
      background: $white;
      border-left: 0.5px solid $light-blue-grey;
      border-right: 0.5px solid $light-blue-grey;
      border-bottom: 0.5px solid $light-blue-grey;

      li {
        padding: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
