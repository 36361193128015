$paragraphWidth: 1380px;

#wrapper .landing.landingParkingAirport {

  margin: 0 0 40px;

  h1 {
    color: $dark-blue;
    font-weight: 600;
    margin: 20px 0;

    font-size: em(26px);
    line-height: em(32px);

    @include md {
      font-size: em(32px);
      line-height: em(40px);
    }

    @include xxl {
      font-size: em(38px);
      line-height: em(45px);
    }
  }

  h2 {
    color: $dark-blue;
    font-weight: 600;
    margin: 0 0 20px;

    font-size: em(26px);
    line-height: em(32px);

    @include md {
      font-size: em(32px);
      line-height: em(40px);
    }

    @include xxl {
      font-size: em(38px);
      line-height: em(45px);
    }
  }

  h3 {
    color: $dark-blue;
    font-weight: 500;
    margin: 0 0 20px;

    font-size: em(20px);
    line-height: em(26px);

    @include md {
      font-size: em(28px);
      line-height: em(32px);
    }

    @include xxl {
      font-size: em(32px);
      line-height: em(40px);
    }
  }

  .paragraph {
    max-width: $paragraphWidth;
    padding: 0 40px;
    margin: 20px 0;

    .content {
      p {
        text-align: justify;

        strong {
          font-weight: 700;
        }
      }

      &.reduced {
        @include lg {
          width: 80%;
        }
      }
    }

    .visual {
      img {
        max-height: 400px;
        max-width: 100%;
      }
    }

    &.w-bg-blue {
      max-width: $paragraphWidth - 80px;
      background: $warm-blue;
      color: white;
      padding: 40px;

      @media (min-width: #{$paragraphWidth - 80px}) {
        border-radius: 20px;
        margin: 40px 0;
      }

      h1, h2, h3, h4, h5, h6 {
        color: white;
      }

      a {
        text-decoration: underline;
        color: $white;
        font-size: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.w-visual {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 25px;
      align-items: center;

      @include lg {
        flex-direction: row;
        gap: 50px;
      }

      .content {
        flex: 1 1 75%;
      }

      .visual {
        flex: 1 0 25%;
      }
    }

    &.w-visual-big {
      .content {
        flex: 1 1 60%;
      }

      .visual {
        flex: 1 0 40%;
      }
    }

    &.w-visual-only {
      display: flex;
      justify-content: center;

      .visual {
        @include lg {
          text-align: center;
        }

        img.parking-map {
          max-height: initial;
        }
      }
    }

    &.paragraph-valet {
      padding: 0;
      align-items: center;

      @include lg {
        align-items: flex-end;
      }

      .content {
        padding: 40px;
      }

      .visual {
        position: relative;
        text-align: center;

        img {
          max-height: 280px;
        }
      }
    }
  }
}
