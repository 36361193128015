$gapColRows: 18px;

.UserInformations {
  position: relative;
  width: 100%;
  max-width: 1160px;
  padding: 0 calc(#{$gapColRows} / 2);
  margin: 16px auto;

  h2 {
    margin: 0;
    padding: 15px 0;
    font-size: em(26px);
    line-height: em(36px);
    text-align: center;
    font-weight: 600;
    background-color: $dark-blue;
    color: $white;
  }

  .iti {
    width: 100%;

    input[type="tel"] {
      padding: 11px 20px 9px 100px !important;
    }
  }

  .content {
    padding: 30px 10px 0;
    background-color: $white;
    border: 0.5px solid $light-blue-grey;
    border-top: none;

    .Fieldset {
      margin: 10px auto;
      text-align: center;

      .link {
        margin-top: 40px;
        color: $dark-blue;
        font-size: 14px;

        .Link {
          font-size: inherit;
        }
      }
    }
  }

  .UserLogin .Fieldset {
    max-width: 555px;
  }
}

.bookingInformation {
  .form .field {
    label {
    margin: 0;
    width: 100%
    }
    .iti {
      margin: 5px 0;
    }
  }
}

.bookingInformation {
  .form .field label {
    margin: 0;
    width: 100%
  }
}

@media #{$phoneL} {
  .UserInformations {
    .content .Fieldset .Input {
      margin-bottom: 20px;
    }
  }
}

@media #{$tab-min} {
  .UserInformations {
    .UserDetails .Fieldset {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
      margin: 20px;
    }
  }
}

@media #{$desktop} {
  .UserInformations {
    .UserDetails .Fieldset {
      margin: 40px;
    }
  }
}

#InformationStepRef {
  display: block;
  position: relative;
  top: calc(-#{$headerHeightFull} - #{$scrollableMenuHeight} - 25px);
  visibility: hidden;

  @media #{$phoneL} {
    top: calc(-#{$headerHeightSimplified} - #{$scrollableMenuHeight} - 25px);
  }
}
