$gapColRows: 18px;

#order-summary {
  background-color: $warm-blue;

  .card-loader {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: inherit;

    .mask {
      width: 100%;
      height: 100%;
      opacity: 0.9;
      background: #fff;
      border-radius: inherit;
    }
  }

  .FullBookingSummary > h2 {
    height: $scrollableMenuHeight - 1px;
    line-height: $scrollableMenuHeight - 1px;
    margin: 0;
    background-color: #4e7eff;
    text-align: center;
    color: $white;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .summary {
    width: auto;
    padding: 0 $gapColRows;
    box-sizing: content-box;
    text-align: center;

    .content {
      display: inline-block;
      width: 100%;
      margin-bottom: 25px;
      padding: 0 20px;
      background-color: white;
      border-radius: 15px;
      text-align: left;

      h2 {
        margin: 15px 0 10px;
        padding: 0;
        color: $dark-blue;
        font-size: 19px;
        text-align: center;
      }
    }

    .price {
      padding: 15px;
      border: 0.5px solid $light-blue-grey;
      border-radius: 15px;
      color: $dark-blue;
      display: block;

      .parking-title {
        margin-top: 0px;
      }

      .without-promotion {
        text-decoration-line: line-through;
        text-decoration-color: #5f798d;
        color: #5f798d;
        font-size: 18px;
        font-weight: lighter;
      }

      & > span {
        display: block;
        margin-top: 15px;
        font-family: "AvenirNextCondensed";
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }


      .price-span {
        display: inline-block;
        text-align: right;
        width: 100%;

        .price-amount {
          font-size: 18px;
        }

      }


      .promotion-span {

        .promotion {
          color: #ff436c;
          margin-left: 10px;
        }
      }

      .switch-price {
        strong {
          text-align: right;
          width: 100%;
          display: block;
          font-size: 18px;
          font-weight: bold;

          em {
            font-style: normal;
          }
        }
      }

      .travel {
        position: relative;
        margin: 0;
        padding: 5px 5px 5px 20px;
        list-style: none;

        & > span {
          display: block;
          margin-bottom: 5px;
          height: 100%;
          font-family: "AvenirNextCondensed";
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 1px;

          strong {
            font-size: 18px;
            font-weight: bold;

            em {
              font-style: normal;
            }
          }
        }

        .airport,
        .station {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          position: relative;
          margin-bottom: 15px;
          font-weight: normal;
          font-size: em(13px);
          line-height: em(20px);

          &:last-child {
            margin-bottom: 0;
          }

          .icon {
            margin: 0;
          }

          .travel-name {
            display: block;
            margin-left: 10px;
            font-size: 12px;
            font-weight: 500;
          }

          em {
            width: 100%;
            margin-left: 30px;
            font-size: 11px;
            font-style: normal;

            strong {
              color: $blue-blue;
              font-weight: bold;
            }
          }

          .travel-bar {
            content: "";
            position: absolute;
            left: -15px;
            top: 5px;
            bottom: 0;
            width: 1px;
            border-left: 0.5px solid $dark-blue;
            height: calc(100% + 25px);

            &:before,
            &:after {
              content: "";
              display: block;
              position: absolute;
              left: -5px;
              width: 9px;
              height: 9px;
              border: 0.5px solid $dark-blue;
              background-color: $white;
              @include border-radius(50%);
            }

            &:before {
              top: 0;
            }

            &:after {
              bottom: 0;
            }
          }
        }
      }
    }

    .services {
      margin-bottom: 15px;
      border: 0.5px solid $light-blue-grey;
      border-radius: 10px;
      color: $dark-blue;
      list-style: none;

      .service {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        font-size: 12px;

        &:not(:last-child) {
          border-bottom: 0.5px solid $light-blue-grey;
        }

        &.no-services {
          font-family: "AvenirNextCondensed";
          font-size: 12px;
          letter-spacing: 1.08px;
          color: #7f94a4;
        }

        .icon {
          @include border-radius(50%);
          position: absolute;
          top: 1px;
          right: 4px;
          margin: 0;
          padding: 2px;
          fill: $dark-blue;
          border: 1px solid white;
          cursor: pointer;
        }

        h3 {
          flex-grow: 1;
          max-width: 90%;
          margin: 0;
          padding: 0;
          font-family: "AvenirNextCondensed";
          font-size: inherit;
          line-height: normal;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        .service-price {
          flex-shrink: 0;
          font-weight: 600;
        }
      }
    }

    .booking-voucher {
      margin: 15px 0;

      .promo {
        font-size: 14px;
      }

      em {
        display: block;
        padding-left: 4px;
      }

      input[type="text"] {
        padding: 10px 20px 10px;
      }
    }

    .business-voucher {
      margin-bottom: 15px;
      border: 0.5px solid $light-blue-grey;
      border-radius: 15px;
      color: $dark-blue;

      strong {
        padding-left: 10px;
        font-size: em(11px);
        font-weight: 600;
        display: block;
        text-transform: uppercase;
        color: $white;
        letter-spacing: 1px;
      }

      .voucher-content {
        display: flex;
        align-items: center;
        padding: 15px;
        justify-content: space-between;
        font-weight: 700;

        .code {
          letter-spacing: 0.6px;
          font-size: em(11px);
          text-transform: uppercase;
        }

        .voucher-price {
          flex-shrink: 0;
          font-size: em(13px);
        }
      }
    }

    .booking-button {
      padding: 10px 0;
      text-align: center;
    }

    .saving {
      div {
        background: #fff;
        text-align: right;
        font-size: em(13px);
        margin-bottom: 10px;
        padding: 3px 5px 3px 0px;

        em {
          font-style: normal;
        }

        &:before {
          content: "";
          background: $secondary;
          width: 52px;
          height: 30px;
          float: left;
          margin: -3px 0;
          background-position: center center;
          background-repeat: no-repeat;
        }

        &.time {
          &:before {
            background-image: url("../../static/images/icons/time-b.png");
          }
        }

        &.euro {
          &:before {
            background-image: url("../../static/images/icons/euro-b.png");
          }
        }
      }
    }

    .final-price {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        max-width: 45%;
        font-family: "AvenirNextCondensed";
        font-size: 14px;
        text-transform: uppercase;
        color: $dark-blue;
        font-weight: 700;
        letter-spacing: 1.17px;

        span {
          display: block;
          margin-top: 5px;
          font-family: "AvenirNext";
          font-weight: normal;
          font-size: 12px;
          text-transform: none;
          color: $blue-grey;
          letter-spacing: normal;
          line-height: normal;
        }
      }

      .amount {
        font-size: em(22px);
        color: $dark-blue;
        font-weight: 700;

        em {
          font-style: normal;
          font-weight: 700;
        }

        small {
          padding-left: 2px;
          font-size: em(12px);
          font-weight: 500;
        }
      }
    }

    .button {
      max-width: 260px;

      @media #{$tab-portrait} {
        max-width: 100%;
        width: 100%;
      }
    }

    p.helper {
      display: block;
      color: #fff;
      font-size: em(9px);
      line-height: em(12px);
    }
  }
}

@media #{$tab-portrait} {
  .payment-page {
    #order-summary {
      margin: 50px 0;

      .summary {
        padding-top: 20px;

        .content {
          max-width: 450px;
        }

        .button {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  .payment-page {
    #order-summary {
      margin: 0;
    }
  }
}

@media #{$desktop} {
  #order-summary {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    min-width: 316px;
    min-height: 100%;
    height: max-content;
    z-index: 10001;

    .FullBookingSummary {
      position: sticky;
      top: 66px;
    }

    .summary {
      max-width: 320px;
      margin: 32px auto 0;
      padding: $gapColRows;
    }
  }
}

.noHeader #order-summary .FullBookingSummary {
  top: 0;
}


// MOBILE SUMMARY

@media #{$tab-portrait} {
  .cart-page {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    #order-summary {
      margin: 0;
      height: 130px;
      order: 1;
      background-color: $light-grey;
    }
  }

  #cart-form-messages-container,
  #BookingStepRoot,
  #ServicesStepRoot,
  #OptionsStepRoot,
  #FullBookingInformations,
  #ServicesForm,
  #OptionsForm,
  .reassuranceWrapper {
    order: 2
  }
}

.MobileSummaryContainer {
  background: $light-grey;
  padding: 20px;
  border-bottom: 1px solid $light-blue-grey;

  &.sticky {
    position: fixed;
    top: $headerHeightSimplified;
    @media #{$tab-portrait-only} {
      top: $headerHeightFull;
    }
    left: 0;
    right: 0;
    z-index: 3;
  }

  .MobileSummary {
    position: relative;
    background: $white;
    border-radius: 20px;
    border: solid 0.5px $light-blue-grey;
    padding: 12px 24px;

    .Sites {
      color: $dark-blue;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: -0.5px;
      text-transform: uppercase;
      line-height: 1.15;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .Trip {
      position: relative;
      margin: 10px 0 10px 14px;

      .Departure,
      .Return {
        display: flex;
        margin: 6px 0;

        span:first-of-type {
          text-transform: uppercase;
          color: $dark-blue;
          font-size: 11px;
          flex-basis: 60px;
        }

        span:last-of-type {
          color: $blue-blue;
          font-size: 11px;
          font-weight: 700;
        }
      }

      .TravelBar {
        content: "";
        position: absolute;
        left: -10px;
        top: 2px;
        bottom: 0;
        width: 1px;
        border-left: 0.5px dashed $dark-blue;
        height: calc(100% - 6px);

        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: -3px;
          width: 6px;
          height: 6px;
          background-color: $dark-blue;
          border-radius: 100%;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }
      }
    }

    .Separator {
      background-image: linear-gradient(to right, $light-blue-grey 50%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 12px 1px;
      background-repeat: repeat-x;
      height: 1px;
      margin: 20px 0;
    }

    .LinesTitle {
      color: $dark-blue;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.5px;
      text-transform: uppercase;
      margin: 16px 0 8px;
    }

    .Line {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      color: $dark-blue;
      font-size: 12px;
      margin: 6px 0;

      &.Bullet {
        position: relative;
        margin: 6px 0 6px 8px;

        &:before {
          content: '';
          height: 4px;
          width: 4px;
          background-color: $dark-blue;
          border-radius: 100%;
          position: absolute;
          top: 4px;
          left: -8px;
        }
      }

      .CrossedOutPriceWrapper {
        font-size: 10px;
        margin-right: 2px;

        .CrossedOutPrice {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 40%;
            margin: 0 auto;
            width: 80%;
            height: 1px;
            transform: rotate(172deg);
            background-color: $dark-blue;
          }
        }
      }

      .Price {
        text-align: right;
        white-space: nowrap;
        font-weight: 600;

        &.Free {
          font-weight: 500;
        }
      }
    }

    .Total {
      margin-top: 12px;
      margin-bottom: 2px;
      padding-top: 6px;
      border-top: 1px solid $dark-blue;
      font-weight: 700;
      letter-spacing: -0.5px;
      text-transform: uppercase;

      .PriceWrapper {
        display: flex;
        flex-direction: column;

        .Price {
          font-weight: 700;
        }

        .Savings {
          color: $blue-grey;
          font-size: 11px;
          text-transform: none;
          font-weight: normal;
        }
      }
    }

    .ShowButton {
      .icon {
        z-index: 10;
        background: $warm-blue;
        border-radius: 100%;
        position: absolute;
        bottom: -14px;
        left: calc(50% - 14px);
        padding: 2px;
        transform: rotate(90deg);
        cursor: pointer;

        path {
          fill: $white;
        }
      }

      &.expanded {
        .icon {
          transform: rotate(270deg);
        }
      }
    }

    .booking-voucher .input-label {
      margin: 0;
      color: $dark-blue;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.5px;
      text-transform: uppercase;
    }
  }
}

#order-summary .booking-voucher {

  em {
    font-size: 12px;
    color: #ff436c;
  }

  .InputCode.success {
    input {
      padding-right: 70px;
    }
  }

  .Button {
    margin-right: 0;
    height: 40px;
    font-size: 10.5px;
    letter-spacing: 0.88px;

    .loader {
      border-width: 2px;
      top: calc(50% - 13px);
      left: calc(50% - 13px);
    }
  }
}


.noHeader .MobileSummaryContainer {
  top: $scrollableMenuHeight;
}
