#cookie-consent {
  .CookieConsent {
    &Display {
      display: flex !important;
    }

    &Container {
      display: none;
      flex-direction: column;
      align-items: center;
      column-gap: 5px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: auto;
      max-width: 480px;
      padding: 5px 15px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 10px 10px 52px -1px rgba(0, 0, 0, 0.24);
      -webkit-box-shadow: 10px 10px 52px -1px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 10px 10px 52px -1px rgba(0, 0, 0, 0.24);
      z-index: 2000;

      @include md {
        column-gap: 8px;
        padding: 20px 25px 10px 25px;
        bottom: 50px;
        left: 50px;
        border-radius: 15px;
      }

      p {
        margin: 6px 0 0;
        font-size: 13px;

        &:nth-child(3) {
          font-style: italic;
          margin: 10px 0;
        }
      }
    }

    &Title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: 600;
        font-size: 21px;
        color: $dark-blue;
        margin: 10px 0;
      }
    }

    &Buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 5px;
      column-gap: 10px;
      width: 100%;

      @include md {
        flex-direction: row;
      }

      .personalizedCookieButton {
        color: $blue-blue;
        font-weight: 600;
        padding: 0 0 3px;
        margin-bottom: 10px;
        border: transparent;
        border-bottom: $blue-blue 1px solid;
        border-radius: 0;
        background-color: inherit;
        cursor: pointer;
        letter-spacing: 0;
        text-transform: none;
      }

      &Response {
        width: 100%;
        margin-bottom: 10px;

        @include md {
          width: auto;
        }

        .Button {
          width: 100%;
          font-weight: bold;
          font-size: 13px;
          border-radius: 20px;
          @include md {
            width: auto;
          }
        }
      }
    }
  }
}
