html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'AvenirNext';
  font-size: em(15px);
  line-height: normal;
  color: $dark;
  overflow-x: hidden;

  // Espacement pour le header en position Fixed
  padding-top: $headerHeightFull;
  @media #{$phoneL} {
    padding-top: $headerHeightSimplified;
  }

  &.noHeader {
    padding-top: 0;
  }

  a {
    color: $primary;
  }

  strong {
    font-weight: 500;
  }

  u {
    text-decoration: underline;
  }
}
