#Footer {
  background-image: linear-gradient(to bottom, #fff, $pale-grey);

  @extend .clearfix;

  .followus {
    margin-top: 50px;

    .title {
      font-size: em(16px);
      font-weight: bold;
      color: $dark-blue;
      text-align: center;
      margin: 20px 0;
      text-transform: uppercase;
      letter-spacing: 1.6px;
    }

    ul {
      display: block;
      margin: 0 0 55px 0;
      padding: 0;
      text-align: center;

      li {
        display: inline-block;
        margin: 0 15px;

        a {
          display: inline-block;

          svg {
            width: 46px;
            height: 46px;
            padding: 10px;
            border: 1px solid $blue-blue;
            border-radius: 100%;
            fill: $blue-blue;
            transition: all 0.1s ease-in-out;
          }

          &:hover {
            svg {
              fill: $dark-blue;
              border-color: $dark-blue;
            }
          }
        }
      }
    }


    @media #{$phoneL} {
      margin-top: 25px;
      ul {
        margin-bottom: 20px;
      }
    }
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    max-width: 1140px;
    padding: 0 10px;
    margin: 0 auto 30px;

    > div {
      padding: 0 10px;
      margin: 15px 0;
      flex: 1 1 auto;

      @media #{$tab-portrait} {
        padding: 0 30px 0 10px;
      }

      @media #{$phoneL} {
        padding: 0 10px;
      }

      > .title {
        max-width: 170px;
        margin-bottom: 10px;
        font-size: em(14px);
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $dark-blue;
        line-height: normal;
      }

      ul {
        list-style: none;
      }

      &.application {
        @media #{$tab-portrait} {
          width: 40%;
        }

        @media #{$phoneL} {
          width: 100%;
          text-align: center;
          p {
            margin: 0 auto 10px;
          }
        }

        a {
          display: block;
          margin-bottom: 15px;
        }

        img {
          width: 160px;
          max-width: 100%;
        }
      }

      &.about,
      &.usefulLinks,
      &.establishment{
        @media #{$tab-portrait} {
          width: 30%;
        }

        a {
          display: block;
          font-size: em(15px);
          color: $blue-grey;
          padding: 4px 0;
          transition: color 0.15s ease-in-out;

          &:hover {
            color: $blue-blue;
          }
        }
      }

      @media #{$phoneL} {
        &.about {
          width: 50%;
        }

        &.usefulLinks {
          width: 50%;
        }
      }

      &.descWithReviews {
        padding: 0 10px 0 10px;

        .reviewsContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 10px;

          @include sm {
            flex-direction: row;
          }

          @media #{$tab-portrait-only} {
            flex-direction: column;
          }

          @include lg {
            flex-direction: column;
          }

          @include xl {
            flex-direction: row;
          }

          .review {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 170px;
            padding: 2px 10px;
            background-color: rgba(255, 255, 255, 0.329);
            border-radius: 5px;
            border: solid 2px #ebf1f4;

            p {
              margin: 0;
            }

            .ratingContainer {
              display: flex;
              gap: 5px;

              p {
                margin-top: 5px;
                color: #2cd5c4;
                font-weight: 600;
              }
            }

            .totalReviews {
              font-size: 11px;
              color: #{$dark-blue};

              span {
                font-weight: 700;
              }
            }
          }

          img {
            margin: 0 1.5px;
            height: 15px;
            @media #{$phoneL} {
              height: 12px;
            }
          }
        }

        width: 300px;

        @media #{$tab-portrait} {
          width: 40%;
        }

        @media #{$phoneL} {
          width: 100%;
        }

        dd {
          display: flex;
          justify-content: center;

          a {
            display: block;
            width: 200px;
            margin-bottom: 10px;
          }
        }

        .description {
          font-size: 14px;
          margin: 30px 0 5px;
          text-align: center;
          color: $dark-blue;
        }
      }

      &.legal {
        display: flex;
        align-items: flex-end;
        width: 100%;
        padding: 0 10px;
        margin: 15px 0;
        font-size: em(13px);
        color: #888888;

        @media #{$tab-portrait} {
          width: 30%;
          flex-direction: column-reverse;
          align-items: flex-start;
          justify-content: flex-end;
        }

        .left {
          @media #{$phoneL} {
            width: 100%;
          }

          ul {
            display: flex;
            justify-content: space-evenly;
            margin: 0;

            @media #{$tab-portrait} {
              flex-direction: column;
            }

            @media #{$phoneL} {
              flex-direction: row;
            }

            li {
              margin: 0;

              @media #{$tab-portrait} {
                padding: 4px 0;
              }

              a {
                font-size: em(15px);
                color: $blue-grey;
                padding: 0 10px;

                &:hover {
                  color: $blue-blue;
                }
              }
            }
          }
        }

        .middle {
          flex: 1 1 auto;
          text-align: center;
          color: $dark-blue;
          font-size: em(13px);
          margin: 0;

          @media #{$tab-portrait} {
            display: none;
          }
        }

        .right {
          color: $dark-blue;
          font-size: em(13px);

          .phone {
            font-weight: 500;

            a {
              color: $dark-blue;
            }
          }

          @media #{$tab-portrait} {
            margin-bottom: 15px;
          }

          @media #{$phoneL} {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  .newsletterSubscription,
  .newsletterSubscriptionWithIllu {
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      margin: 5px 0;
      text-align: right;

      @include sm {
        flex-direction: row;
      }

      input[type='email'] {
        border: 1px solid #dae4eb;
        height: 40px;
        width: 240px;

        &:focus {
          border-color: $blue-blue;
        }
      }

      input[type='submit'] {
        margin-left: 5px;
        font-size: em(11px);
        line-height: em(15px);
        padding: 14px 20px 11px;
        letter-spacing: 1px;
      }
    }

    .newsletter-info {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      position: relative;
      font-size: 13px;
      text-align: right;
      font-weight: 500;

      &.success {
        color: #008577;
      }

      &.error {
        color: #eb0033;
      }
    }
  }

  .newsletterSubscription {
    background: $light-grey;
    position: relative;

    .boxed {
      padding-top: 15px !important;
      padding-bottom: 15px !important;

      .subscriptionContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;
        min-height: 40px;

        @include sm {
          flex-direction: row;
        }

        @media #{$tab-portrait} {
          justify-content: center;
        }

        .description {
          display: flex;
          align-items: center;
          background: url('../../static/images/icons/newsletter-icon.svg') top center no-repeat;

          p {
            position: relative;
            margin: 0;
            width: 100%;
            padding-top: 35px;
            color: $dark;

            small {
              position: absolute;
              line-height: 10px;
              color: grey;
            }
          }

          @include sm {
            background: url('../../static/images/icons/newsletter-icon.svg') center left no-repeat;

            p {
              min-width: 400px;
              padding: 0 0 0 50px;
            }
          }
        }
      }
    }
  }

  .newsletterSubscriptionWithIllu {
    background-color: $warm-blue;

    .boxed {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      max-width: 1380px;
      padding-bottom: 0;
      padding-top: 0;

      &::after {
        display: none;
      }

      @include sm {
        min-height: 250px;
      }

      .subscriptionContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: clamp(20px, 10%, 40px);

        @include md {
          justify-content: space-between;
          padding: 30px 30px 30px 300px;
        }

        .description {
          max-width: 800px;

          p {
            color: white;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            max-width: 530px;
          }

          @include lg {
            p {
              font-size: 24px;
            }
          }
        }

        form {
          width: 100%;

          input[type='email'] {
            width: 100%;

            @include sm {
              max-width: 400px;
            }
          }
        }
      }

      .visuel {
        width: 50%;
        max-width: 225px;

        @include lg {
          max-width: 250px;
        }

        @include md {
          position: absolute;
          left: 50px;
          bottom: 0;
        }

        img {
          width: 100%;
        }
      }
    }

    .newsletter-info {
      &.success {
        color: $white;
      }
    }
  }
}
