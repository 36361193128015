.CookieConsentModal {
  &Title {
    text-align: center;
    border-bottom: 1px solid $light-blue-grey;

    > p {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &Text {
    padding: 5px 20px;

    @include md {
      padding: 10px 15px;
    }

    ul {
      padding-left: 35px;
    }

    p {
      margin-top: 5px;
      margin-bottom: 5px;

      @include md {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  &Switchs {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      border-top: 1px solid $light-blue-grey;
      border-bottom: 1px solid $light-blue-grey;

      &:last-child {
        border-top: none;
      }

      > label {
        font-size: 15px;
        font-weight: 700;
      }

      .checkbox {
        width: 40px;
        height: 24px;
        border-radius: 20px;

        &:after {
          top: 2px;
          left: 2px !important;
          width: 19px;
          height: 19px;
        }
      }

      .Switch input[type="checkbox"]:checked + .checkbox::after {
        left: 19px !important;
      }
    }
  }

  &Infos {
    display: flex;
    align-items: center;
    padding: 0 15px;

    a {
      font-size: 15px;
    }

    @include md {
      padding: 10px 15px;
    }
  }

  &Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;

    @include md {
      padding: 10px 0;
    }
  }
}

#CookieConsentModalCloseButton {
  position: absolute;
  top: 15px;
  right: 10px;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}
