.company {
  .page-header {
    img {
      aspect-ratio: 6/7;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .motion {
    padding: 0 25px 50px;

    @media #{$tab-portrait} {
      padding: 0 0 25px;
    }

    video {
      width: 100%;
      height: auto;

      @include lg {
        border-radius: 10px;
      }
    }
  }

  .timeline {
    @media #{$tab-portrait} {
      padding: 50px 25px;
    }

    @media #{$tab-portrait} {
      padding: 25px;
    }

    @media #{$phoneL} {
      padding: 20px 10px;
    }

    .timeline-content {
      position: relative;
      margin: 70px 0;
      @include clearfix;

      @media #{$tab-portrait} {
        margin: 40px 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: calc(50% - 0.5px);
        height: 100%;
        border: 0.5px dashed $light-blue-grey;

        @media #{$phoneL} {
          display: none;
        }
      }

      .card {
        position: relative;
        float: left;
        width: calc(50% - 120px);
        margin-top: 160px;
        border: 1px solid #CBD5DD;
        border-radius: 1px;

        @media #{$tab-portrait} {
          width: calc(50% - 40px);
        }

        @media #{$phoneL} {
          width: 100%;
          margin-top: 20px !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: $white;
          z-index: 1;

          @media #{$phoneL} {
            display: none;
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          z-index: 2;

          @media #{$phoneL} {
            display: none;
          }
        }

        &:first-child {
          margin-top: 50px;
        }

        &:nth-child(2) {
          margin-top: 270px;
        }

        &:nth-child(3) {
          margin-top: -70px;
        }

        &:nth-child(5) {
          margin-top: -70px;
        }

        &:nth-child(odd) {
          margin-right: 120px;

          &:before {
            right: -132px;
          }

          &:after {
            right: -126px;
          }

          @media #{$tab-portrait} {
            margin-right: 40px;

            &:before {
              right: -52px;
            }

            &:after {
              right: -46px;
            }
          }
        }

        &:nth-child(even) {
          margin-left: 120px;

          &:before {
            left: -132px;
          }

          &:after {
            left: -126px;
          }

          @media #{$tab-portrait} {
            margin-left: 40px;

            &:before {
              left: -52px;
            }

            &:after {
              left: -46px;
            }
          }
        }

        .visuel {
          position: relative;
          height: 260px;
          width: 100%;
          overflow: hidden;

          .year {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 2px 10px 0;
            color: $white;
            font-size: em(16px);
            font-weight: bold;
            border-radius: 15px;
            z-index: 5;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            min-height: calc(100% - 2px);
            min-width: 100%;
            max-width: 100%;
            object-fit: cover;
          }

          .wave {
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -1px;
            height: 42px;
            background: url('../../static/images/waves/horizontal.png') bottom no-repeat;
            background-size: 100% 28px;
          }
        }

        .content {
          padding: 20px;

          .smiley {
            display: block;
          }

          .smiley, .title {
            font-size: em(18px);
            text-align: center;
          }

          .title {
            margin: 5px 0 10px;
            color: $blue-blue;
            font-weight: bold;
            font-stretch: condensed;
            line-height: 1.56;
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .description {
            font-size: em(15px);
            line-height: 1.67;
            text-align: center;
          }
        }

        &.watermelon {
          &:before {
            border: 1px solid $watermelon;
          }

          &:after {
            background: $watermelon;
          }

          .year {
            background-color: $watermelon;
          }
        }

        &.blue-blue {
          &:before {
            border: 1px solid $blue-blue;
          }

          &:after {
            background: $blue-blue;
          }

          .year {
            background-color: $blue-blue;
          }
        }

        &.green {
          &:before {
            border: 1px solid $green;
          }

          &:after {
            background: $green;
          }

          .year {
            background-color: $green;
          }
        }

        &.warm-blue {
          &:before {
            border: 1px solid $warm-blue;
          }

          &:after {
            background: $warm-blue;
          }

          .year {
            background-color: $warm-blue;
          }
        }
      }
    }
  }

  .today {
    .statistics {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;

      @media #{$phoneL} {
        padding: 0 40px;
      }

      p {
        font-family: AvenirNextCondensed;
        flex: 1 1 auto;
        padding-right: 60px;
        padding-bottom: 40px;
        font-size: em(16px);
        font-weight: bold;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-stretch: condensed;

        @media #{$phoneL} {
          width: 100%;
          text-align: center;
          padding-right: 0;
          padding-bottom: 20px;
        }

        strong {
          font-family: AvenirNext;
          display: block;
          font-size: em(44px);
          color: $tealish;
          font-weight: bold;
          line-height: em(60px);
          letter-spacing: normal;
          text-transform: none;
        }
      }
    }
  }

  .tomorrow {
    padding: 100px 50px;
    @include clearfix;

    h2.h2, p {
      text-align: left !important;
      margin: 0;
      padding: 0 !important;
      float: left;
      width: 50%;

      @media #{$phoneL} {
        float: none;
        width: 100%;
      }
    }

    @media #{$phoneL} {
      p {
        margin-top: 20px;
      }
    }
  }

  #join-us {
    .boxed {
      h2 {
        text-align: left;
        color: $blue-blue;

        @media #{$phoneL} {
          text-align: center;
        }
      }

      > h3 {
        font-size: em(21px);
        font-weight: 600;
        color: $dark-blue;
        padding: 5px 0;

        @media #{$phoneL} {
          text-align: center;
        }
      }
    }
  }
}
