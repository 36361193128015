#wrapper .landing.landingParking {
  .boxed {
    max-width: 1380px;

    &::after {
      content: none;
    }
  }

  table,
  tr,
  td {
    border: none;
  }

  strong {
    font-weight: bold;
  }

  h3 {
    color: $dark-blue;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-top: 0;
  }

  .segment,
  .boxed {
    h2 {
      @include lg {
        font-size: 38px !important;
        margin-top: 0;
        padding: 0;
      }
    }
  }
  .boxed.blog,
  .boxed.reviews {
    .h2 {
      font-size: 32px !important;
      line-height: normal !important;

      @include lg {
        font-size: 38px !important;
        padding: 0 5% 10px !important;
      }
      @include xxl {
        padding: 0 10% 10px !important;
      }
    }
  }

  .segment {
    a[href='#bookingHome'].button {
      background-color: $watermelon !important;
      color: white;
      border: none;

      svg {
        fill: white !important;
      }
    }

    .pricing {

      > table {
        height: 100% !important;
        width: 100% !important;
        max-width: 400px;
        min-height: 200px;
        color: white;
        background: $warm-blue;
        border-radius: 20px;
        grid-row: 1/6;
        grid-column: 2/3;

        > tbody > tr {
          &:first-child {
            td,
            p {
              text-align: center;
              margin: 0;
              line-height: 1.33;
              font-weight: bold;
              font-size: 22px;
            }
          }

          &:first-child,
          &:last-child {
            td {
              padding: clamp(5px, 5%, 30px);
            }
          }
        }

        td {
          padding: 0;
          text-align: center;
          table {
            height: 100% !important;
            tr {
              :first-child {
                width: 33.33% !important;
                font-size: 14px;
                font-weight: bold;
              }
              :last-child {
                width: 66.66% !important;
                font-size: 18px;
                font-weight: bold;
              }
              td {
                height: 40px !important;
                text-align: center;
                vertical-align: center;
                border: 1px $white solid;
                padding: 16px 10px;

                @include xl {
                  padding: 10px;
                }

                &:first-child {
                  border-left: 0;
                }
                &:last-child {
                  border-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .prices-graphs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;

    img.price {
      width: 100%;

      @include md {
        padding: 15px;
      }

      @include lg {
        max-height: 350px;
      }
    }

    .prices-graph {
      flex-basis: 100%;
      text-align: center;

      @include xl {
        flex-basis: 50%;
      }

      .price-title {
        color: $blue-blue;
        text-align: center;
        font-weight: 700;
        letter-spacing: 1.07px;
        text-transform: uppercase;
      }
      &.graph-1 {
        display: none;
        @include md {
          display: block;
        }
      }
    }

    .prices-legend {
      flex-basis: 100%;
      font-size: 14px;
      color: $blue-grey;
      font-weight: 400;
      font-style: italic;
      text-align: center;
      margin-top: 10px;
    }
  }

  #HowItWorks .howItWorks .descriptionWithCta {
    padding: 0;
  }

  #ExternalRatingsRoot {
    .reviewsContainer {
      .review {
        background-color: $pale-grey;
      }
    }
  }

  .boxed.reviews {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include lg {
      gap: 50px;
    }
  }

  .didyouknow {
    width: 100%;
    border-radius: 20px;
    padding: 0 5%;
    background: $warm-blue;
    color: white;

    @include lg {
      display: flex;
      gap: 5%;
    }

    .content {
      flex: 5;
      padding: 5% 5%;
      h2, h3 {
        font-size: 28px !important;
        line-height: normal !important;
        color: white;
        text-align: center;

        @include lg {
          font-size: 32px !important;
          text-align: left;
        }
      }

      p {
        color: white;

        strong {
          font-weight: bold;
        }
      }
    }

    .illu {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: 33%;
        min-width: 50px;
        height: auto;

        @include lg {
          width: unset;
          max-height: 338px;
          padding: 5% 5% 0;
        }
      }
    }

    a {
      text-decoration: underline;
      color: $white;
      font-size: inherit;
    }
    a:hover {
      text-decoration: none;
    }
  }

  .boxed.blog-articles {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include lg {
      gap: 50px;
    }

    h3 {
      text-align: center;
    }

    .articles {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      @include lg {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      a {
        width: 100%;
        max-width: 600px;

        @include lg {
          height: 100%;
        }

        .article {
          height: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          overflow: hidden;
          border: solid 1px $pale-grey;

          .visuel {
            img {
              height: 180px;
              width: 100%;
              object-fit: cover;

              @include lg {
                height: 220px;
              }
            }
          }
          .content {
            height: 100%;
            background-color: $pale-grey;
            padding: 15px 20px;

            p {
              margin-top: 5px;
              font-weight: bold;

              @include lg {
                margin-top: 15px;
              }
            }

            .title {
              font-size: 18px;
              color: $dark-blue;
            }

            .date {
              font-size: 10px;
              color: $blue-blue;
            }
          }
        }
      }
    }
  }
  .linear-grey-background {
    background-image: linear-gradient(to bottom, #fff, #f7f9fb);
    width: 100%;
  }

  .faqSection {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    padding: 40px 10vw;
    border: solid 1px $pale-grey;
    max-width: unset;
    border-radius: 20px;
    background: $white;

    @include lg {
      padding: 40px 90px;
      margin: auto;
    }

    h3 {
      font-size: 32px;
      color: $dark-blue;
      line-height: normal;
      text-align: left;
      margin-bottom: 0;

      @include lg {
        font-size: 38px;
      }
    }

    .accordion {
      .accordion-item {
        .Button {
          .accordion-title {
            font-weight: bold;
            line-height: 2.5;
            margin: 0;
          }
        }
      }
    }

    .zendesk-redirection {
      display: flex;
      justify-content: center;
      padding: 30px 0;
    }
  }

  .boxed.talk-about-us {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;

    &::after {
      display: none;
    }

    @include lg {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: clamp(50px, 8%, 150px);
    }

    .h2 {
      padding-top: 10px !important;
      font-size: 32px !important;
      @include lg {
        min-width: 300px;
      }
    }

    .green-chip {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 0 60px;
      padding: 0;
      width: 100%;

      @include lg {
        margin: 0;
        justify-content: space-between;
      }

      li {
        list-style: none;
        flex: 1 1 auto;
        margin: 30px 20px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$tab-portrait} {
          flex: 0;
          margin: 40px 25px 0 25px;
        }

        @include lg {
          margin: 0;
        }
      }
    }
  }
}
