#HowItWorks {
  max-width: 1380px;

  .howItWorks {
    > h2 {
      font-size: 27px !important;
      font-weight: bold !important;

      @include md {
        font-size: 42px !important;
      }
    }

    > p {
      margin: 40px auto 0;
      max-width: 600px;
      font-size: em(15px);
      line-height: em(25px);

      @media #{$phoneL} {
        margin: 10px auto 0;
      }
    }

    .contentBefore {
      margin: 30px 0 0;

      p {
        text-align: center;
      }
    }

    .steps {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 40px 0 50px;

      @include sm {
        padding: 40px 5% 2%;
      }

      @include lg {
        flex-direction: row;
        justify-content: space-between;
        padding: 40px 3% 2%;
      }

      @include xl {
        gap: 40px;
      }

      .step {
        width: calc(100%);
        border-radius: 20px;
        background-image: linear-gradient(174deg, $pale-grey 5%, rgba(242, 246, 249, 0.6) 96%);
        margin-bottom: 35px;

        @include sm {
          min-height: initial;
          width: 100%;
        }

        @include xl {
          max-width: 326px;
          flex: 1;
          min-height: 304px;
          margin-bottom: 0;
        }

        .step-content {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-around;
          border-radius: 1px;
          padding: 20px;
          overflow: hidden;
          align-items: center;

          @include sm {
            flex-direction: row;
            justify-content: center;
            width: 100%;
            min-height: 200px;

            .visuel {
              width: 45%;

              img {
                display: block;
                margin: auto;
              }
            }
          }

          @include xl {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;

            img {
              display: inline-block;
            }
          }

          img {
            max-height: 136px;
          }

          .step-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 2%;

            @include sm {
              width: 50%;
            }

            @include lg {
              width: 100%;
            }

            h3, span {
              margin: 0;
              padding: 10px 0;
              color: $blue-blue;
              font-size: 27px;
              font-weight: bold;
              text-transform: capitalize;
              letter-spacing: normal;
            }

            p {
              text-align: center;
              font-size: em(15px);
              color: $dark-blue;
              margin: 0;
              line-height: 1.67rem;
            }
          }
        }
      }
    }

    .descriptionWithCta {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 30px 5% 50px;

      @include md {
        flex-direction: row;
        justify-content: center;
      }

      > p {
        margin: 0 0 25px;
        text-align: center;

        @include md {
          width: 80%;
          padding: 0 10%;
          text-align: left;
          margin-bottom: 0;
        }
      }

      .action {
        width: 250px;
        text-align: center;
      }
    }
  }
}
