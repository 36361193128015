.home {
  h2, .home-h2, .home-faq .faqSection > h4 {
    margin: 0;
    color: $dark-blue;
    font-size: em(26px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    @include md {
      font-size: em(38px);
    }
    @include xl {
      font-size: em(38px);
    }

    span {
      font-weight: 600;
    }
  }

  h3, .h3 {
    margin: 0;
    color: $dark-blue;
    font-size: em(18px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    @include md {
      font-size: em(22px);
    }
    @include xl {
      font-size: em(24px);
    }

    &.light {
      font-weight: 400;
      color: $warm-blue;
    }
  }
}

.bg-gradient-grey-unbound {
  background-image: linear-gradient(to bottom, #fff, #f7f9fb 33%, #f7f9fb 66%, #fff);
  width: 100%;
}

.linear-grey-background {
  background-image: linear-gradient(to bottom, #fff, #f7f9fb);
  width: 100%;
}

.home-boxed {
  max-width: 1380px;
  margin: 0 auto;
  padding: clamp(10px, 5%, 50px);

  @include md {
    padding: clamp(10px, 10%, 80px);
  }

  &.home-boxed-sm {
    max-width: 1040px;
  }
}

#HowItWorks.home-how-it-works {
  padding-top: 40px;

  .howItWorks {
    display: flex;
    flex-wrap: wrap;

    .steps-aside {
      width: 100%;

      h2,
      .h3 {
        text-align: center;
        width: 100%;
      }
    }

    .steps {
      width: 100%;
      padding: 30px 0;

      .step {
        background-image: linear-gradient(174deg,#f2f6f9 5%,rgba(242,246,249,.6) 96%);
        max-width: 600px;
        margin: 0 auto 20px;

        .step-content {
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          min-height: auto;

          .visuel {
            img {
              max-height: 100px;

              @include xs {
                max-height: 120px;
              }
            }
          }

          .step-text {
            width: calc(100% - 120px);

            @include xs {
              width: calc(100% - 159px);
            }

            p {
              color: $black;

              b {
                font-size: em(18px);
                display: block;
              }
            }
          }
        }
      }
    }

    .action {
      width: 100%;
      text-align: center;
      padding: 0 0 40px;
    }

    @include xl {
      gap: 20px;

      .steps-aside {
        width: calc(33.33% - (20px / 2));

        h2,
        .h3 {
          text-align: left;
          margin: 10px 0;
        }

        p {
          margin: 20px 0;
        }
      }

      .steps {
        width: calc(66.66% - (20px / 2));
        padding: 0;
        gap: 20px;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;

        .step {
          width: 33.33%;
          margin: 0;
          max-width: 240px;

          .step-content {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .visuel {
              width: auto;
            }

            .step-text {
              width: 100%;
              margin: 20px 0 0;
            }
          }
        }
      }

      .action {
        width: calc(33.33% - (20px / 2));
        text-align: center;
      }
    }

  }
}

.home-services {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 20px;
  padding: 40px 40px 20%;
  max-width: 1040px;

  @include md {
    gap: 5%;
    grid-template-columns: 4fr 5fr;
    grid-template-rows: auto;
    padding: clamp(10px, 5%, 50px);

    &.inverse {
      grid-template-columns: 5fr 4fr;

      .contain {
        grid-column: 1/2;
      }
    }

    &.containSide {
      .visual {
        grid-row: 1/3;
        align-self: center;
        justify-self: flex-end;
      }

      .action {
        justify-self: start;
      }

      &.inverse {
        .contain {
          grid-row: 1/2;
        }
      }
    }
  }

  h2 {
    font-weight: normal;

    font-size: em(22px);

    @include lg {
      font-size: em(28px);
    }

    b {
      font-weight: bold;
    }
  }

  .visual {
    margin-bottom: 20px;

    @include md {
      align-self: flex-end;
      justify-self: start;
      margin-bottom: 0;
    }

    img {
      max-width: 400px;
      width: 100%;

      @include xl {
        max-width: 500px;
      }
    }
  }

  .action {
    order: 3;
    padding: 20px 0;

    @include md {
      align-self: start;
    }
  }

  .contain {
    @include md {
      grid-column: 2/3;
      grid-row: 1/3;
    }
  }

  .contains {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.home-bvb-container {
  padding: 0;

  @include md {
    padding: 50px 0;
  }

  .home-bvb {
    background: $warm-blue;
    color: $white;
    padding: 40px 40px 0;


    @media (min-width: 1040px) {
      border-radius: 20px;
    }

    h3 {
      margin: 0;
      font-size: em(26px);
      line-height: em(36px);
      color: $white;

      @include md {
        font-size: em(32px);
        line-height: em(44px);
      }

      @include lg {
        font-size: em(32px);
        line-height: em(46px);
      }
    }

    .home-bvb-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include sm {
        flex-direction: row;
        align-items: flex-end;
      }

      > div {
        width: 100%;

        @include sm {
          width: calc(100% - 160px);
        }

        @include md {
          width: calc(100% - 300px);
        }
      }

      a.button {
        margin-bottom: 20px;

        @include lg {
          margin-top: 10px;
          margin-bottom: 40px;
        }

      }

      img.illus {
        display: none;
        max-width: 200px;

        @include sm {
          display: block;
        }
        @include lg {
          max-width: 250px;
        }
      }
    }

  }
}

.home-reviews {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: clamp(10px, 10%, 80px);

  @include lg {
    gap: 50px;
  }

  .home-h2 {
    text-align: center;
  }
}

.home-prices {
  margin: clamp(10px, 5%, 80px) auto;
  padding: 40px;
  display: flex;
  background-color: $white;
  border-radius: 20px;
  flex-direction: column;

  @include lg {
    flex-direction: row;
    flex-wrap: wrap;
  }

  p {
    margin: 20px 20px 20px 0;
  }

  .prices-cta {
    order: 4;
    text-align: center;
    margin-top: 30px;

    @include lg {
      order: 3;
      text-align: left;
      margin-top: 0;
      width: 45%;
    }
  }

  .prices-content {
    width: 100%;

    @include lg {
      width: 45%;
    }
  }

  .prices-graphs {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    width: 100%;

    @include lg {
      margin: 0;
      width: 55%;
    }

    img {
      width: 100%;
      max-height: 300px;

      @include md {
        padding: 15px;
      }

      @include lg {
        max-height: 350px;
      }
    }

    .prices-graph {
      .graph-title {
        color: $blue-blue;
        text-align: center;
        font-weight: bold;
        letter-spacing: 1.07px;
        text-transform: uppercase;
      }
      &.graph-3d {
        display: none;
        @include md {
          display: block;
        }
      }
    }
  }

  .prices-legend {
    font-size: 14px;
    color: $blue-grey;
    font-weight: 400;
    font-style: italic;
    order: 3;

    display: flex;
    align-items: center;
    justify-content: center;

    @include lg {
      order: 4;
      width:55%;
    }
  }

}
