@import '../mixins';

.fastBookingLandingPage {
  display: flex;
  flex-direction: column;
  padding: 5% 5% 100px;
  max-width: 1680px;
  margin: auto;

  @include sm {
    padding-bottom: 150px;
  }

  @include xl {
    padding-bottom: 3%;
    flex-direction: row;
  }

  @include xxl {
    padding: 75px 10%;
  }

  .contentContainer {
    @include xl {
      width: calc(95% - 400px);
      order: 2;
    }

    h1 {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      opacity: 0.95;
      letter-spacing: normal;
      text-align: center;
      margin: 10px 0 20px;
      color: $white;
      font-size: 25px;

      @include sm {
        font-size: 30px;
      }
      @include md {
        font-size: 38px;
        margin-top: 0;
      }
      @include xl {
        font-size: 40px;
      }
      @include xxl {
        font-size: 42px;
      }
    }

    .imgWithBoxsContainer {
      display: flex;

      @include md {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }

      @include xl {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .bookingIllus {
        display: none;

        @include xl {
          display: flex;
          position: initial;
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        img {
          width: 80%;
          max-width: 550px;
          max-height: 200px;
          margin: 0 auto -10px;

          @include sm {
            max-height: 240px;
            margin: 0 auto -12px;
          }

          @include md {
            min-width: 350px;
            width: 100%;
            max-height: 300px;
            margin: 0 auto -16px;
          }

          @include xxl {
            min-width: 450px;
          }
        }
      }

      .boxesInfo {
        display: none;
        flex-direction: column;
        justify-content: center;
        gap: 25px;

        @include  xl {
          display: flex;
          flex-direction: column;
        }

        @include xl {
          margin-left: 35px;
          position: initial;
        }

        .box {
          display: flex;
          width: 217px;
          height: 88px;
          padding: 15px 10px;
          opacity: 0.95;
          border-radius: 20px;
          background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0.5));

          svg {
            height: 18px;
            width: 18px;
            margin: 6px;
            fill: $dark-blue;

            &.location-blue {
              height: 26px;
              width: 17px;
              margin: 0 5px;
            }

            &.time {
              height: 20px;
              width: 20px;
              margin: 6px 4px 0 5px;
            }
          }

          p {
            margin: 5px;
            font-size: 14px;
            color: $dark-blue;
          }

          .title {
            font-weight: bold;
          }
        }
      }
    }
  }

  .subtitle {
    color: $white;
    font-size: 16px;
    text-align: center;
    margin: 0 0 20px;

    @include md {
      font-size: 18px;
    }

    &.second {
      font-weight: bold;
    }
  }

  .bookingFormContainer {
    @include md {
      margin: 0 auto;
    }
    @include lg {
      margin: 0 auto;
    }
    @include xl {
      margin: 0 5% 0 0;
    }
  }

  .contentContainer {
    .subtitle.second {
      display: none;
      @include xl {
        display: block;
      }
    }
  }

  .afterSimulation {
    display: block;
    order: 2;
    @include xl {
      display: none;
    }

    img.illustration {
      width: 85%;
      margin: 10px auto;
      display: block;
      max-width: 500px;
    }
    .subtitle.second {
      color: $warm-blue;
    }
  }
}
