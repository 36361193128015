.talk-about-us {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 1380px;

  &::after {
    display: none;
  }

  @include lg {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: clamp(50px, 8%, 150px);
  }

  .h2 {
    padding-top: 10px !important;
    font-size: 32px !important;
    @include lg {
      min-width: 300px;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 60px;
    padding: 0;
    width: 100%;
    gap: 4px 20px;

    @include lg {
      margin: 0;
      justify-content: space-between;
    }

    li {
      list-style: none;
      flex: 1 1 auto;
      margin: 30px 20px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media #{$tab-portrait} {
        flex: 0;
      }

      @include lg {
        margin: 0;
      }
    }
  }
}
