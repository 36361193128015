.message {
  margin: 0 auto 20px;
  border: 0.5px solid;
  padding: 15px 80px;
  text-align: center;
  border-radius: 1px;

  @media #{$phoneL} {
    padding: 10px;
  }
  #wrapper > & {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0;
      padding: 0;
    }
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
  &.alert {
    border-color: $error;
    color: $error;
  }
  &.success {
    border-color: $success;
    color: $success;
  }
  &.warning {
    border-color: $tertiary;
    color: $tertiary;
  }
  &.info {
    border-color: $primary;
    color: $primary;
  }
}
.message-highlight {
  padding: 15px;
  margin-bottom: 30px;
  background: $secondary;
  color: $primary;
  text-align: center;
  p {
    margin: 0;
  }
  padding-left: 80px;
  padding-right: 80px;
  @media #{$phoneL} {
    padding: 30px;
  }
  &.grey {
    background: #eee;
  }
}
