.partners {
  .image {
    img {
      @media #{$phoneL} {
        top: calc(-50% + -15vw)
      }
      aspect-ratio: 4/5;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .partners-types {
    display: flex;
    flex-wrap: wrap;

    h2 {
      width: 100%;
      text-align: left !important;
      padding-bottom: 50px !important;

      @media #{$phoneL} {
        text-align: center !important;
      }
    }

    .partners-type {
      padding: 0 80px 60px 0;
      width: calc(100% / 3);

      @media #{$tab-portrait} {
        width: 50%;
        padding: 0 60px 40px 0;
      }

      @media #{$phoneL} {
        width: 100%;
        text-align: center;
        padding: 10px 20px;
      }

      .icon {
        img {
          width: 50px;
        }
      }

      h3 {
        font-size: em(16px);
        color: $dark-blue;
        letter-spacing: 1.3px;
        text-transform: uppercase;
      }

      p {
        font-size: em(15px);
        line-height: em(25px);
      }
    }
  }

  .partners-actions {
    padding-top: 20px;
    text-align: center;

    p {
      font-size: em(15px);
      color: $dark-blue;

      a {
        font-weight: bold;
        color: $blue-blue;

        &:hover {
          color: $dark-blue;
        }
      }
    }
  }

  .our-partners {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 0 60px;
      padding: 0;
  
      li {
        list-style: none;
        flex: 1 1 auto;
        margin: 30px 20px 0 0;
  
        @media #{$tab-portrait} {
          flex: 0;
          margin: 40px 25px 0 25px;
        }
      }
    }
  }
}