@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input[type="button"],
input[type="reset"],
input[type="submit"],
textarea {
  @include border-radius(20px);
  @include appearance(none);
  @include box-shadow(none);

  font-size: em(14px);

  @media #{$phoneL} {
     font-size: em(16px) !important; // Fix scale screen on input for iOS
  }
}

textarea {
  overflow: auto;
  resize: none;
}

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
  font-family: AvenirNext;
  border: 0.8px solid $light-blue-grey;
  color: $dark;
  background: #fff;
  padding: 11px 20px 9px;
  height: 40px;
  line-height: em(26px);
  width: 100%;
  font-weight: 300;

  @include placeholder {
    color: $blue-grey;
    font-family: AvenirNext;
    font-size: em(14px);
  }


  &[type="password"] {
    letter-spacing: 2px;
    font-weight: bold;
  }

  &.error {
    border-color: $error;
    color: $error;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &.success {
    border-color: $success;
    color: $success;

    @include placeholder {
      color: $success;
    }
  }

  &:focus {
    border-color: $blue-blue;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

textarea {
  padding: 10px;
  height: 130px;
}

select {
  @include box-shadow(none);
  @include appearance(none);
  @include border-radius(20px);

  font-family: AvenirNext;
  font-size: em(14px) !important;
  background: #FFF;
  width: 100%;
  border: 0.8px solid $light-blue-grey;
  margin: 0;
  height: 40px;
  padding: 6px 25px 4px 20px;
  line-height: em(26px);
  position: relative;
  font-weight: 300;
  background-image: url('../../static/images/icons/arrow-select.svg');
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: calc( 100% - 10px );
  text-transform: uppercase;

  &::-ms-expand {
    display: none;
  }

  &.error {
    border-color: $error;
    color: $error;
  }

  &:focus {
    border-color: $blue-blue;
  }

  &.empty {
    color: #7d8a95;
  }
}

.selectContainer {
  @include box-shadow(none);
  @include appearance(none);
  @include border-radius(17px);

  font-size: em(14px);
  background: #FFF;
  width: 100%;
  border: 0.8px solid $light-blue-grey;
  color: $blue-grey;
  margin: 0;
  height: 36px;
  padding: 5px 25px 5px 15px;
  line-height: em(26px);
  font-weight: 300;
  position: relative;
  background-image: url('../../static/images/icons/arrow-select.svg');
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: calc( 100% - 10px );

  @media #{$tab-portrait} {
    font-size: 16px !important; // Fix scale screen on input for iOS
  }

  &.error {
    border-color: $error;
    color: $error;
  }

  &.empty {
    color: #7d8a95;

    .selected {
      color: #7d8a95;
    }
  }

  .selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $dark;
  }

  &:focus, &:focus-within {
    border-color: $blue-blue;
  }

  select {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-transform: none !important;
  }
}

.switch {
  position: relative;
  width: 26px;
  height: 15px;
  user-select: none;

  > input[type="checkbox"] {
    display: none;

    &:checked {
      & + label {
        background-color: $blue-blue;
        border-color: $blue-blue;

        &::before {
          background-color: $white;
          right: 2px;
          left: auto;
        }
      }
    }
  }

  > label {
    display: block;
    overflow: hidden;
    height: 15px;
    padding: 0;
    line-height: 20px;
    border: 1px solid $blue-grey;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      height: 11px;
      width: 11px;
      margin: 0;
      background-color: $light-blue-grey;
      border-radius: 20px;
      transition: all 0.15s ease-in-out 0s;
    }
  }
}

.radio {
  label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;

      &:checked ~ span.checkmark {
        border: 1px solid $blue-blue;

        &::after {
          display: block;
          opacity: 1;
        }
      }
    }

    span.checkmark {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      background: $white;
      border: 1px solid $dark-blue;
      border-radius: 50%;
      transition: all 0.1s ease-in;

      &::after {
        content: '';
        position: absolute;
        display: none;
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;
        background: $blue-blue;
        opacity: 0.6;
        border-radius: 50%;
        transition: all 0.1s ease-in;
      }
    }

    &:hover {
      span.checkmark {
        border: 1px solid $blue-blue;

        &::after {
          display: block;
        }
      }
    }
  }
}

.Input.error {
  input,
  textarea {
    @include placeholder {
      color: $error !important;
    }
  }
}
