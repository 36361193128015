.cgv {
	h2 {
		font-weight: normal;
		text-transform: uppercase;
		font-size: em(20px);
		margin: 0 0 20px 0;
	}
	h3 {
		font-weight: normal;
		text-transform: uppercase;
		font-size: em(18px);
		margin: 0 0 20px 0;
	}
	h4 {
		font-weight: normal;
		text-transform: uppercase;
		font-size: em(18px);
		margin: 0 0 20px 0;
		color: $tertiary;
	}
	p {
		span {
			color: $tertiary;
		}
	}

	ul {
		margin: 0 0 20px 0;
	}
}
