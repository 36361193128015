.PaymentWrapper {
  @media #{$desktop} {
    width: calc(100% - 316px);
  }
}

.payment {

  &.bookingStep {
    margin: 0 auto;
    @media #{$desktop} {
      padding: 25px 0 0;
    }
  }

  .content {
    background-color: $white;
    border: 1px solid $light-blue-grey;
    border-top: none;
    padding-top: 30px;
    //min-height: 30vh;

    fieldset {
      margin: 10px 40px 30px;
      padding: 40px;
      border: 1px solid $light-blue-grey;

      @media #{$phoneL} {
        margin: 10px 10px 30px;
        padding: 40px 20px 20px;
        border: none;
        border-top: 1px solid $light-blue-grey;
      }

      legend {
        font-size: em(16px);
        color: $dark-blue;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 20px;
        margin: auto;
        letter-spacing: 1.6px;
      }
    }
  }

  .StripeElement {
    padding: 0 0 20px 20px;
    margin-bottom: 20px;
  }
  .onlyNew .StripeElement {
    padding: 20px 0;
  }

  .form {
    .field {
      margin: 0 0 20px 0;

      @media #{$phoneL} {
        margin: 0 0 20px 0;
      }

      &.disabled {
        opacity: 0.45;
      }

      label {
        display: inline-block;
        color: $dark-blue;
        font-weight: 600;
        font-size: em(11px);
        text-transform: uppercase;
        margin-bottom: 5px;
        padding-left: 15px;
        letter-spacing: 1px;
      }

      .input {
        @include border-radius(20px);
        @include appearance(none);
        @include box-shadow(none);
        font-size: em(14px);
        border: 1px solid $light-blue-grey;
        color: $light-blue-grey;
        background: #fff;
        padding: 11px 10px 9px;
        height: 40px;
        line-height: em(40px);
        width: 100%;
        font-weight: 300;

        &.error {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          border-color: $error;
          color: $error;
        }
      }
    }
  }

  .existingCard .radio,
  .monthly .radio {
    margin: 20px 0;
    padding: 14px 20px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.03) 0 1px 1px 0, rgba(0, 0, 0, 0.02) 0 3px 6px 0;
    color: rgb(109, 110, 120);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    label {
      padding: 0;
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 4px 0;
    }

    p {
      margin: 4px 0 0;
    }

    .radioIcon {
      height: 1.2em;
      width: 1.2em;
      transition: fill 0.1s ease;

      circle {
        display: none;
      }
    }

    &:hover {
      .radioIcon {
        color: rgb(48, 49, 61);
        //circle {
        //  display: initial;
        //  fill: rgb(48, 49, 61);
        //}
      }
      .cardIcon {
        fill: rgb(48, 49, 61);
      }
    }

    &.active {
      color: $blue-blue;
      .radioIcon {
        circle {
          display: initial;
          fill: $blue-blue;
        }
      }
      .cardIcon {
        fill: $blue-blue;
      }
    }
  }

  .monthly p {
    padding: 0 40px;
    font-style: italic;
    color: #717171;
    font-size: 13px;
    margin: 0;
  }

  .cards {
    @extend .clearfix;
    margin: 10px 40px 30px;

    .card {
      @include transition(background .2s ease-in-out,color .2s ease-in-out);
      @extend .clearfix;
      @include border-radius(5px);
      float: left;
      border: 1px solid $primary;
      width: calc( (100% - 60px) / 3);
      padding: 60px 10px 10px 10px;
      margin-bottom: 20px;
      margin-right: 20px;
      background-color: #fff;
      cursor: default;
      &:nth-child(4n) {
        margin-right: 0;
      }
      @media #{$phoneL} {
        width: 100%;
      }
      .number {
        text-align: right;
      }
      .date {
        margin: 0 0 10px;
        text-align: right;
        font-size: em(14px);
      }
      .name {
        float: left;
      }
      .type {
        float: right;
      }
      span {
        display: none;
      }
      &:hover:not(.active) {
        background-color: $tertiary;
        color: #fff;
        position: relative;
        cursor: pointer;
      }
      &.active {
        background-color: $primary;
        color: #fff;
        position: relative;
        span {
          display: block;
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 10px;
          background: $secondary;
          color: $primary;
          text-transform: uppercase;
          padding: 2px 10px;
          font-size: em(10px);
        }
      }
    }
  }

  #SimplePaymentStore {
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$phoneL} {
      padding-left: 30px;
    }

    p {
      margin: 0;
      padding-left: 15px;
      font-size: em(15px);
      color: $dark-blue;
    }
  }

  #FullPaymentStore {
    display: flex;
    justify-content: center;

    @media #{$phoneL} {
      padding: 0 30px;
    }
  }

  .payment-loading {
    padding: 100px 15px;

    p {
      text-align: center;
      margin: 10px 0;
      font-size: em(18px);
    }
  }

  .payment-options-wrapper {
    width: 100%;
    max-width: 440px;
    margin: 0 auto 30px;

    .booking-content {
      background-color: $white;
      border: 0.5px solid $light-blue-grey;
      border-radius: 15px;
      padding: 0;
      margin: 0;
    }

    h3 {
      margin: 0 0 30px 0;
      color: $dark-blue;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }

    .paymentOptions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .creditCard,
      .useCredit {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 109px;
        height: 107px;
        border: 0.8px solid $light-blue-grey;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          border-color: $blue-blue;
        }

        > span {
          line-height: normal;
          font-family: "AvenirNextCondensed";
          text-align: center;
          letter-spacing: 2.18px;
          color: $blue-blue;
          font-weight: 700;
          font-size: 12px;
          text-transform: uppercase;
        }

        &.active {
          background-color: #f1f3fb;
          border-color: $blue-blue;
        }
      }

      > em {
        font-size: 10px;
        color: $dark-blue;
        letter-spacing: 2px;
        font-weight: 600;
        text-transform: uppercase;
        font-style: normal;
      }
    }

    .form {
      padding: 20px 50px;
      .field.full {
        margin: 10px 0 0;
        color: $blue-grey;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        font-style: italic;
      }
    }
    .details {
      .byCredits {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px 0;
        font-size: 14px;
        color: $dark-blue;
        font-weight: 700;
        letter-spacing: 1.87px;
        border-top: 0.5px solid $light-blue-grey;
        text-transform: uppercase;

        &:not(.active) {
          display: none;
        }

        .total {
          margin-top: 10px;
          font-size: 26px;
          letter-spacing: 3.47px;
          font-style: normal;
        }
      }
    }
  }

  .byCard:not(.active) {
    display: none;
  }

  .bvb-reminder {
    text-align: center;
    color: $dark-blue;
    b {
      color: $blue-blue;
    }
  }
}

.PaymentStep {
  #FinishForm {
    .confirm {
      text-align: center;
      margin: 25px 0 40px;

      button {
        img {
          width: 12px;
          margin-right: 4px;
        }
      }

      @media #{$tab-portrait} {
        background: #fff;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        margin: 0;
        z-index: 1;

        button {
          width: 100%;
          border-radius: 0;
          padding: 25px 50px 25px 25px;
        }
      }
    }
  }

  #payment-errors {
    width: 95%;
    max-width: 820px;
    margin: 50px auto;
    padding: 0;

    p {
      text-align: center;
      padding: 20px 30px;
      font-weight: 600;
      color: $error;
      border: 1px solid $error;
    }
  }
}

.checkbox-container {
  text-align: left;
  margin: 10px 20px 0;

  @media #{$desktop} {
    width: 95%;
    max-width: 820px;
    margin: 50px auto;
    padding: 0;
  }

  .subscribe-newsletter,
  .accept-cgu {
    display: flex;
    margin: 20px auto;
    max-width: 400px;

    .switch-container {
      padding-top: 2px;
    }

    > label {
      flex: 1 1 auto;
      margin: 0 0 0 10px;
      font-size: em(16px);
      color: $dark-blue;

      a {
        font-weight: 600;
        font-size: em(16px);
      }
    }
  }
}

.bv-spinner {
  margin: 0 auto 30px;
  width: 70px;
  text-align: center;

  & > span {
    width: 18px;
    height: 18px;
    background-color: $blue-blue;
    border-radius: 100%;
    display: inline-block;
    animation: payment-spinner-animation 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }

  @keyframes payment-spinner-animation {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
  }
}
