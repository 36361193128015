$gapColRows: 15px;

.bookingStep {

  h2 {
    font-size: em(26px);
    line-height: em(36px);
    text-align: center;
    font-weight: 600;
    color: $white;
    background-color: $warm-blue;
    margin: 0 0 25px;
    padding: 15px 0;
  }

  fieldset {
    margin: 10px 0 40px;
    padding: 20px 40px 10px;
    border: 0.5px solid $light-blue-grey;

    legend {
      margin: auto;
      padding: 0 20px;
      font-size: 20px;
      color: $dark-blue;
      text-align: center;
      text-transform: initial;
      font-weight: 700;
      letter-spacing: normal;
    }

    @media #{$phoneL} {
      margin: 10px;
      padding: 10px;
      border: none;
      border-top: 0.5px solid $light-blue-grey;

      legend {
        padding: 0 10px;
      }
    }
  }

  .cart-end {
    margin-top: 50px;
    text-align: center;

    @media #{$tab-portrait} {
      background: #fff;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      margin: 0;
      z-index: 1;

      button, a {
        width: 100%;
        border-radius: 0 !important;
        padding: 25px 50px 25px 25px !important;
      }
    }
  }

  .booking-content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 0.5px solid $light-blue-grey;
    padding: 40px 30px;

    .booking-error {
      color: $error;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 500;
      margin: 20px auto 0;
    }
  }

  .form {
    width: 100%;

    &:last-of-type {
      border-bottom: 0 none;
      margin-bottom: 0;
    }
  }

  .DayPicker {
    .success input:disabled {
      background-color: $white;
      cursor: default;
    }
  }

  .HourPicker {
    .Fixed.dark {
      .content {
        height: 40px;
        border-radius: 20px;
      }
    }
    .regular {
      .Fixed.dark .content {
        border-color: $light-blue-grey;
        color: $blue-grey;
      }
    }
    .success {
      .Fixed.dark .content {
        border-color: $tealish;
        color: #008577;
      }
    }

    .info {
      position: relative;
      white-space: normal;
    }
  }

  .message {
    background: white;
  }

  .b2b-order-collab {
    color: $blue-blue;
    text-align: center;
  }
}

#order-detail {

  .travel {
    display: flex;
    flex-direction: column;

    > .departure {
      border-bottom: 0.5px solid $light-blue-grey;
    }
    > .arrival {
      padding: 20px 0 0;
      margin: 0 0 10px;
    }

    > .departure,
    > .arrival {
      h3 {
        margin: 0 0 20px 0;
        padding: 0;
        color: $blue-blue;
        font-size: 24px;
        font-weight: 700;
      }

      .form > *:not(.notice) {
        margin-bottom: $gapColRows;
      }
    }
  }
}

#order-options {
  .booking-error {
    scroll-margin-top: $scrollableMenuHeight;
    @media #{$tab-portrait} {
      scroll-margin-top: $scrollableMenuHeight + $cartSummaryMobile;
      scroll-margin-bottom: $fixedButtonHeight + 20px;
    }
  }
}

#order-informations {
  #SiteInfoRoot {
    clear: both;
  }

  .site-info {
    position: relative;
    font-size: 13px;
    color: $blue-grey;
    padding: 0 5px 0 25px;
    line-height: normal;
    margin: 10px 0 15px;
    text-align: left;

    svg {
      position: absolute;
      top: -1px;
      left: 4px;
      height: 15.5px;
      width: 15.5px;
      fill: $blue-grey;
    }
  }

  .tripsType {
    .radio {
      padding: 10px 0;
      text-align: left;

      label {
        color: $blue-blue;
        font-weight: 500;
        font-size: em(16px);

        span {
          font-weight: 700;
        }
      }
    }

    .WorkerSelectorContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      gap: 0 20px;
      margin-top: 6px;

      > .WorkerSelectorPrefix {
        color: $blue-blue;
        font-weight: 500;
        font-size: em(14px);
        padding-left: 25px;
      }
      > .WorkerSelector {
        .InputSelect {
          margin: 0;
          padding: 0;
          min-width: 200px;
          div.label { display: none; }
        }
      }
    }

    &.error {
      .radio {
        label {
          color: $error;

          span.checkmark {
            border-color: $error;
          }

          &:hover {
            span.checkmark {
              border-color: $error;

              &::after {
                background: $error;
              }
            }
          }
        }
      }
      .WorkerSelectorContainer {
        > .WorkerSelectorPrefix {
          color: $error;
        }
      }
    }
  }

  #FieldsetTravelType,
  #FieldsetCustomer,
  #FieldsetVehicle,
  #FieldsetTrip,
  #FieldsetTrip .Trip {
    scroll-margin-top: $scrollableMenuHeight;
    @media #{$tab-portrait} {
      scroll-margin-top: $scrollableMenuHeight + $cartSummaryMobile;
      scroll-margin-bottom: $fixedButtonHeight;
    }
  }
}

#ArrivalUnavailableModal {
  .modal-content {
    white-space: pre-line;
  }
}

#cart-inform-messages-container {
  scroll-margin: calc(#{$headerHeightFull} + #{$scrollableMenuHeight} + 10px);
  @media #{$phoneL} {
    scroll-margin: calc(#{$headerHeightSimplified} + #{$scrollableMenuHeight} + 10px);
  }

  .cart-form-infos {
    .message {
      font-weight: 600;
    }
  }
}

@media #{$tab-portrait} {

  .cart-form-errors {
    display: none;

    &.show {
      display: block;
    }

    > .message {
      margin: 0 auto;
    }
  }

  .cart-form-infos {
    display: block;
    padding: 20px 10px;

    > .message {
      margin: 0 auto;
    }
  }

  .bookingStep {
    margin: 0 0 20px;
    .booking-content {
      padding: 25px 20px;
    }
  }
}

@media #{$tab-min} {
  .bookingStep {
    border-radius: 20px;
  }

  #order-detail {

    .trip-container {
      margin-top: 30px;
    }
    .booking-trip-kind {
      fieldset {
        margin: 0;
      }
    }

    .travel {
      flex-direction: column;

      > .departure {
        padding: 0 0 40px
      }

      > .arrival {
        padding: 40px 0 0;
      }

      > .departure,
      > .arrival {
        display: flex;
        width: 100%;

        .title-form {
          flex-shrink: 0;
          flex-basis: 60px;
          display: flex;
          align-content: center;
          align-items: center;
          align-self: center;
          margin-right: 10px;
        }

        h3 {
          margin: 0;
          padding: 0;
          letter-spacing: 1.8px;
          text-transform: uppercase;
          writing-mode: vertical-lr;
          transform: rotate(180deg);
        }

        .form {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          row-gap: 20px;
          column-gap: 15px;

          > *:not(.notice) {
            width: calc(33.33% - #{$gapColRows});
            margin: 0;

            .input-label {
              font-size: 10.5px;
            }

            input,
            .selectContainer {
              height: 34px;
              line-height: 34px;
            }
          }

          .Switch {
            width: 185px;
            margin-top: 3px;
          }

          .trip-informations,
          .DayPicker {
            margin-bottom: 0;
          }

          .HourPicker {
            margin: 0;
          }
        }
      }
    }
  }
}

@media #{$desktop} {

  #cart-form-messages-container {
    width: calc(100% - 316px);
    padding-top: 10px;

    .cart-form-errors {
      display: none;
      max-width: 820px;
      margin: 0 auto 0;

      &.show {
        display: block;
      }

      > .message {
        margin: 0 auto;
      }
    }
  }

  #cart-inform-messages-container {

    .cart-form-infos {
      padding: 20px 10px;
      max-width: 820px;
      margin: 0 auto 0;
      display: block;

      > .message {
        margin: 0 auto;
      }
    }
  }

  .bookingStep {
    width: 95%;
    max-width: 820px;
    margin: 25px auto 50px;
    padding: 0;

    h2 {
      border-radius: 20px;
    }

    .booking-content {
      border-radius: 20px;
    }

    fieldset {
      border-radius: 10px;
    }
  }

  #BookingStepRoot,
  #ServicesStepRoot,
  #OptionsStepRoot,
  #FullBookingInformations {
    width: calc(100% - 316px);
  }
  #BookingStepRoot .bookingStep {
    margin-top: 25px;
  }

}
