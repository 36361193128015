.AvailableServices {
  .services {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;

    .info {
      color: $tertiary;
      font-weight: 500;
      font-size: em(10px);
      margin-bottom: $gapColRows;
      margin-top: -15px;
      float: left;

      &:before {
        @include border-radius(50%);

        content: "!";
        background: $tertiary;
        color: #fff;
        margin-right: 4px;
        width: 12px;
        height: 12px;
        line-height: 1em;
        text-align: center;
        display: inline-block;
      }
    }

    .warning {
      position: relative;
      border: 0.5px solid $light-blue-grey;
      color: $blue-grey;
      margin-bottom: 40px;
      padding: $gapColRows $gapColRows $gapColRows 48px;
      text-align: left;
      font-size: em(15px);

      &::before {
        content: "i";
        position: absolute;
        display: table-cell;
        top: 25px;
        left: 20px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: 10px;
        font-weight: bold;
        border: 0.5px solid $blue-grey;
        border-radius: 50%;
        text-align: center;
      }
    }

    .service {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(33.33% - 20px);
      height: 230px;
      margin: 0 10px 0;
      border: 0.8px solid $light-blue-grey;
      border-radius: 10px;
      overflow: hidden;
      box-sizing: border-box;

      &:hover,
      &.selected {
        border-color: $blue-blue;

        .choose {
          border-color: $blue-blue;
        }
      }

      &.selected {
        background-color: #f1f3fb;

        .choose .ButtonIcon {
          background-color: $blue-blue;

          .icon {
            transform: rotate(0deg);
            fill: $white;
          }
        }
      }

      &.disabled {
        border-color: $light-blue-grey;

        h4,
        .choose .price {
          color: $blue-grey;
          opacity: .5;
        }

        .Tooltip {
          background-color: $light-grey;
          color: $light-blue-grey;
          border:.5px solid $light-blue-grey;
        }

        .choose .ButtonIcon {
          border-color: $light-blue-grey;
          opacity: .5;

          .icon {
            fill: $blue-grey;
          }
        }

        &:hover,
        &.selected {
          border-color: $light-blue-grey;

          .choose {
            border-color: $light-blue-grey;
          }
        }
      }

      h4 {
        width: 100%;
        max-height: 100px;
        margin: 0;
        font-size: 14px;
        color: $blue-blue;
        text-transform: uppercase;
        line-height: normal;
        overflow: hidden;
      }

      .informations {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 25px;

        .Tooltip {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }

      .see-more {
        align-self: flex-start;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: $blue-grey;
        cursor: pointer;

        span {
          border-bottom: 1px solid $blue-grey;
        }

        .icon-chevron {
          fill: $blue-grey;
        }
      }

      .choose {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        padding: 15px 20px;
        border-top: 1px solid $light-blue-grey;

        .price {
          font-weight: 700;
          font-size: 20px;
          color: $blue-blue;
        }

        .ButtonIcon {
          width: 40px;
          min-width: 40px;
          height: 40px;
          padding: 0;
          border-radius: 50%;
          outline: none;

          .icon {
            transform: rotate(45deg);
          }
        }
      }
    }

    .ModalService {
      .modal-content {
        max-height: 275px;
        overflow-y: auto;
      }
    }
  }

  .services-pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      fill: $dark-blue;
    }

    .previous-page,
    .next-page {
      display: inline-flex;
      cursor: pointer;

      &.disabled {
        cursor: default;

        .icon {
          fill: $light-blue-grey;
        }
      }
    }

    .previous-page {
      .icon {
        transform: rotate(180deg);
      }
    }

    .page {
      display: inline-block;
      width: 25px;
      height: 25px;
      line-height: 25px;
      margin: 0 12px;
      border: 1px solid $dark-blue;
      border-radius: 50%;
      background-color: $white;
      text-align: center;
      color: $dark-blue;
      font-size: 10px;
      font-weight: 700;
      cursor: pointer;

      &.active {
        border-color: $blue-blue;
        background-color: $blue-blue;
        color: $white;
      }
    }
  }
}

@media #{$phoneL} {
  .AvailableServices {
    .services {
      flex-direction: column;
      align-items: center;
      row-gap: 10px;

      .service {
        flex-direction: row;
        width: 100%;
        max-width: 450px;
        height: auto;
        margin: 0;

        h4 {
          margin: 10px 0;
        }

        .informations {
          padding: 15px 0 15px 10px;

          .Tooltip {
            right: 110px;
          }
        }

        .choose {
          flex-direction: column;
          justify-content: space-around;
          width: 100px;
          min-width: 100px;
          height: auto;
          padding: 15px 10px 15px 0;
          border-top: none;
          border-left: 1px solid $light-blue-grey;
        }
      }

      .warning {
        font-size: em(14px);
      }
    }
  }
}

.AvailableServices.paginated {
  .services {
    min-height: 520px;

    @media #{$phoneL} {
      min-height: 940px;
    }
  }
}
