.mfp-bg {
  opacity: 0.5;
  z-index: 10002;
}

.mfp-wrap {
  z-index: 10002;
}

.popup {
  padding: 50px;
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  font-size: 14px;

  h2 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 18px;
    color: $dark-blue;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.8px;
  }

  .mfp-close {
    color: $blue-blue;
    opacity: 1;

    &:hover {
      background: none;
      border: none;
      color: $dark-blue;
    }
  }

  .highlight {
    color: $tertiary;
    font-weight: 500;
  }

  .submit {
    overflow: hidden;
    margin-top: 40px;

    .left,
    .right {
      margin: 0 0 20px;
    }

    .left {
      float: left;
      margin-bottom: 0px;
    }

    .right {
      float: right;
      margin-bottom: 0px;
    }

    .btn {
      width: auto;
      border: 0;
      padding-left: 20px;
      padding-right: 20px;
      background: $tertiary;
      min-height: 36px;
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
    }

    .link-caps {
      width: auto;
      font-size: 14px;
      text-align: center;
      background: none;
      color: $tertiary;
    }
  }

  &#ConfirmModal .submit {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      padding: 5px 0;
    }
  }
}

.popup-center {
  text-align: center;
}

#ChangePaymentStoreStrategyModal {
    ul {
        padding-left: 17px;
    }
    .submit {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;

      button {
        flex-basis: calc(50% - 10px);
      }
    }
}
