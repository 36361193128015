.dimmer {
  z-index: 800;
  border-radius: inherit !important;
  //background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  line-height: 1;
  will-change: opacity;

  &.active {
    display: block;
    opacity: 1;
  }
}
