#Join-us {
  @include xl {
    padding: 50px 0;
  }

  .boxed {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f7fa;
    border-radius: 20px;
    padding-bottom: 0;
    margin-bottom: 50px;

    > h3 {
      font-size: 30px;
      color: $dark-blue;
      font-weight: bold;
      line-height: normal;
    }

    > p {
      text-align: center;
    }

    .actions {
      margin: 40px 0;
    }

    .visuel {
      width: 100%;
      margin-top: 20px;
      img {
        width: 100%;
      }
    }

    @include md {
      > p {
        padding: 0 10%;
      }

      .visuel {
        width: 65%;
      }
    }

    @include lg {
      position: relative;
      padding-right: 50%;
      align-items: flex-start;

      > p {
        text-align: left;
        padding: 0 40px 0 0;
      }

      .visuel {
        width: 50%;
        bottom: 0;
        right: 4%;
        position: absolute;
        img {
          width: 100%;
        }
      }
    }
  }
}
