.iti {
  display: block;

  &.iti--container {
    z-index: 18000001;
  }

  .iti__flag-container {
    padding-left: 10px;
    background: none;

    .iti__selected-flag {
      background: none;
    }

    .iti__selected-dial-code {
      padding-top: 2px;
    }

    ul {
      list-style: none !important;
      padding-left: 0 !important;
    }

    &:hover {
      .iti__selected-flag {
        background: none;
      }
    }
  }

  input[type="text"] {
    padding: 11px 20px 9px 100px !important;
  }
}
