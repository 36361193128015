#home-advantages {
  background: $warm-blue;
  color: $white;

  @include xl {
    max-width: 1380px;
    margin: auto;
    border-radius: 30px;
  }

  .boxed {
    max-width: 1200px;
    padding: 20px;
  }

  h2 {
    color: $white;
    margin: 10px 0;

    @include md {
      margin: 20px 0;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px 0;

    .column {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      gap: 20px;

      @include md {
        width: calc(33.33% - (40px/3));
      }

      img {
        width: 50px;
      }

      h3 {
        color: $white;
        font-size: em(16px);
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5px;
      }

      p {
        font-size: em(15px);
        padding: 0;
        margin: 0;

        @media #{$phoneL} {
          flex-grow: 1;
          width: 100%;
          flex-basis: 1;
          text-align: left;
        }
      }
    }
  }
}
