#PromoCodeLp {
  .mainSection {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include lg {
      background-image: linear-gradient(to bottom, #4169e1 28%, #ebf0fd 60%, #fff 70%);
    }

    .titleWithDesc {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-image: linear-gradient(to bottom, #4169e1 33%, #ebf0fd 83%, #fff 94%);
      padding: 40px 10vw;

      @include lg {
        flex-direction: row-reverse;
        background-image: unset;

        gap: 50px;
      }

      h1 {
        color: white;
        font-size: 36px;
        margin: 10px 0;
        line-height: normal;
        text-align: center;

        @include lg {
          font-size: 45px;
          text-align: left;
        }
      }

      .textContainer {
        max-width: 600px;

        p {
          margin-top: 20px;
          color: white;
          text-align: center;

          &.primaryDesc {
            font-weight: bold;
          }

          @include lg {
            text-align: left;
          }
        }
      }

      .illuContainer {
        img {
          width: 100%;
          @include md {
            width: 450px;
          }
          @include xl {
            width: 550px;
          }
        }
      }
    }

    .promoCode {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 10px 10vw;

      h2 {
        text-align: center;
        line-height: normal;
        font-size: 24px;
        color: $dark-blue;

        @include md {
          font-size: 28px;
        }
      }

      .activeCodesContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include md {
          flex-direction: row;
        }
        .activeCode {
          padding: 5px;
          max-width: 320px;
          min-height: 150px;
          background-color: $watermelon;
          border-radius: 10px;
          position: relative;

          @include md {
            max-width: 220px;
          }

          p {
            text-align: center;
            color: white;

            &.code {
              font-weight: bold;
              font-size: 16px;
            }
          }
          .dotted {
            bottom: 50px;
            height: 4px;
            width: 100%;
            background-image: linear-gradient(to right, #f4f7f956 50%, $watermelon 50%);
            background-position: top;
            background-size: 22px 2px;
            background-repeat: repeat-x;
          }
        }
      }

      > p {
        text-align: center;

        @include lg {
          padding: 0 14vw;
        }
      }
    }

    .cloud {
      display: none;

      @include lg {
        display: initial;
        max-height: 300px;
        height: 14vw;
        top: calc(60% - 3vw);
        position: absolute;

        &.left {
          left: -10px;
          transform: rotateY(180deg);
        }

        &.right {
          right: -10px;

          &.xs {
            right: calc(10px + 5vw);
            height: 4vw;
            max-height: 65px;
            top: calc(55% - 6vw);
          }
        }
      }
    }
  }

  .ctaSection {
    display: flex;
    flex-direction: column;
    gap: 75px;
    max-width: 960px;
    margin: 100px auto;
    padding: 10px 10vw;

    @include lg {
      padding: 10px 0;
    }

    @include xl {
      max-width: 1040px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      @include lg {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      img {
        height: 250px;

        @include md {
          height: 375px;
        }
      }

      .textWithActionWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        max-width: 500px;

        @include lg {
          width: 50%;
          align-items: flex-end;
          text-align: end;
        }

        h3 {
          font-size: 28px;
          color: $dark-blue;
          line-height: normal;
          text-align: center;

          @include lg {
            text-align: unset;
          }
        }

        strong {
          font-weight: bold;
        }

        form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          gap: 10px;

          @include sm {
            flex-direction: row;
          }
        }
      }
    }

    .newsletter {
      form {
        max-width: 240px;

        @include sm {
          max-width: unset;
        }
      }

      @include lg {
        flex-direction: row;

        .textWithActionWrapper {
          text-align: start;
          align-items: flex-start;
        }
      }
    }
  }

  .faqSection {
    max-width: 992px;
    margin: 40px auto 0;
    background: $blue-blue;
    border: none;

    @include lg {
      border-radius: 20px;
      padding: 40px 80px;
      margin: 100px auto;
    }

    @include xl {
      max-width: 1040px;
    }

    h2 {
      font-size: 24px;
      line-height: normal;
      color: white;
      text-align: center;
    }

    .accordion {

      .accordion-item {
        border-bottom: 2px solid rgba(44, 213, 196, 0.21);

        &:first-child {
          border-top: 2px solid rgba(44, 213, 196, 0.21);
        }

        .Button {
          background-color: $blue-blue;
          color: white;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        .content {
          p {
            color: $tealish;
            font-weight: 400;

            strong {
              font-weight: 600;
            }
          }
        }
      }
    }

    .zendesk-redirection {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      p {
        color: white;
        margin: 0;

        &.title {
          letter-spacing: 1.42px;
          font-weight: bold;
        }

        span {
          font-weight: bold;
          color: $tealish;
          text-transform: uppercase;
        }
      }

      a {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      img {
        height: 30px;
        padding: 8px;
        border: solid 2px rgba(44, 213, 196, 0.4);
        border-radius: 22px;
      }
    }
  }
}
