@import '../../../../../styles/var';
@import '../../../../../styles/mixins';

#ReviewInRoot form {
  margin-top: 20px;
  h2 {
    color: $dark-blue;
    font-weight: 600;
    font-size: em(21px);
    margin-bottom: 20px;
    text-align: left;
  }

  p {
    color: $dark-blue;
    text-align: center;
    font-style: italic;
  }

  .stars {
    margin-bottom: 40px;
  }

  button {
    display: block;
    margin: 20px auto 20px;
    background: $watermelon;
    border: $watermelon;
    text-transform: none;
    letter-spacing: normal;
  }

  span.error {
    text-align: right;
    font-size: em(10px);
    font-style: italic;
    color: $watermelon;
  }
}

