.downloadFile {
  .form {
    max-width: 455px;
    margin: 0 auto;
    padding: 50px 0 40px;

    h1 {
      margin: 0;
       padding: 0 10px;
      font-size: em(26px);
      line-height: em(65px);
      font-weight: bold;
      color: $white;
      background: $dark-blue;
      text-align: center;
    }

    .content {
      padding: 30px;
      background: $white;
      border: 1px solid $light-blue-grey;
      border-top: none;

      p.legend {
        margin: 0 0 10px;
        font-size: em(15px);
        line-height: em(25px);
        color: $dark-blue;
        text-align: center;
        font-style: italic;
      }

      .field {
        margin-bottom: 30px;

        label {
          padding-left: 10px;
          font-size: em(11px);
          font-weight: 600;
          color: $dark-blue;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }

      .action {
        text-align: center;
      }
    }
  }
}
