.home-establishments {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: clamp(10px, 5%, 50px) 0;

  @include lg {
    flex-wrap: nowrap;
  }

  .slider-container {
    order: 2;

    @include lg {
      display: none;
    }

    #sites-slider-container {
      .swiper {
        padding: 20px 0 50px;
      }
    }
  }

  .map-container {
    display: none;

    @include lg {
      display: block;
      width: 50%;
    }
  }

  .establishments-container {
    order: 1;
    width: 100%;
    padding: 20px 0 0;

    h2 {
      text-align: center;
    }

    h3, .h3 {
      text-align: center;
      margin: 4px 0;
    }

    @include lg {
      width: 50%;

      h2, h3, .h3 {
        text-align: left;
      }
    }

    p {
      display: none;

      @include lg {
        display: block;
      }
    }

    .establishment-lists {
      margin-top: 30px;
      display: none;

      @include lg {
        display: flex;
      }

      > div {
        width: 50%;
      }

      .establishments-type {
        font-weight: bold;
        color: $dark-blue;
        font-size: em(22px);
        margin: 0 0 10px;
      }

      .establishments-content {
        h4 {
          margin: 0;
          padding: 0;
          font-weight: normal;
        }
        a {
          display: flex;
          align-items: flex-end;
          width: 100%;
          padding: 4px 0;
          font-size: em(16px);

          &:hover {
            color: $dark-blue;
            text-decoration: underline;
          }

          svg {
            display: inline-block;
            width: 24px;
            height: 24px;
            fill: $dark-blue;
            margin-right: 4px;
          }
        }
      }
    }
  }

}
