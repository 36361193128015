#wrapper .landing {
  display: flex;
  flex-direction: column;
  align-items: center;

  .boxed {
    width: 100%;
  }

  p {
    margin: 0 0 20px 0;

    line-height: 28px;
    font-size: 16px;

    @include lg {
      line-height: 25px;
      font-size: 15px;
    }

    a {
      font-size: 16px;
      @include lg {
        font-size: 15px;
      }
    }
  }

  ul {
    list-style-type: decimal;
    padding-left: 10px;
    li {
      margin-bottom: 2em;
    }
  }

  h2 {
    color: $dark-blue;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: em(26px);
    line-height: em(36px);

    @include md {
      font-size: em(32px);
      line-height: em(44px);
    }

    @include lg {
      font-size: em(32px);
      line-height: em(46px);
    }
  }

  .boxed {
    .kicker {
      max-width: 610px;
      margin: 50px auto 10px;
      font-size: em(16px);
      line-height: em(28px);

      @include lg {
        font-size: em(15px);
        line-height: em(25px);
      }
    }

    @include lg {
      h2 {
        font-size: em(42px);
        line-height: em(55px);
      }
    }
  }

  .centered {
    p {
      text-align: center;
    }
  }

  .grey-gradient {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to bottom, #fff, $pale-grey 17%, $light-grey 92%, #fff);
  }

  .serviceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to bottom, #0035ad, $dark-blue);
    padding: 10% 7%;
    max-width: 1380px;

    img {
      width: 80%;
      max-width: 400px;
    }

    &BookingButton {
      min-width: 320px;
      margin: 15px 0;
      display: flex;
      justify-content: center;
      order: 3;
    }

    &Title {
      text-align: center;
      color: white;
      font-size: 28px;
      margin-top: 34px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
    }

    &Content {
      p {
        text-align: center;
        color: white;
        margin: 25px 15px;
        font-weight: normal;

        > a {
          color: white !important;
          font-weight: 500;
          font-family: AvenirNext !important;
          text-decoration: underline;
        }

        strong {
          font-weight: bold;
        }
      }

      :nth-child(2) {
        margin-bottom: 5px;

        strong {
          text-transform: uppercase;
          font-weight: 700;
        }
      }

      :nth-child(3) {
        margin-top: 5px;
      }
    }

    @include md {
      display: grid;
      border-radius: 20px;
      margin: 5% auto;
      grid-template-columns: repeat(12, 1fr);
      grid-auto-rows: auto;
      justify-items: center;
      align-items: center;
      padding: 5%;

      &BookingButton {
        grid-column: 1/13;
      }

      &Content {
        grid-column: 1/13;
        p {
          text-align: left;
        }
      }

      img {
        grid-column: 1/6;
      }

      .serviceContainerTitle {
        grid-column: 6/13;
        text-align: left;
        margin: 15px;
      }
    }

    @include xl {
      padding: 3% 5%;
      grid-template-rows: repeat(10, 10%);

      &BookingButton {
        grid-column: 1/6;
        grid-row: 7/11;
      }

      &Content {
        grid-column: 6/13;
        grid-row: 4/10;
      }

      img {
        grid-column: 1/6;
        grid-row: 1/7;
      }

      &Title {
        grid-column: 6/13;
        grid-row: 1/4;
        width: 100%;
        padding: 15px;
        margin: 0 !important;
      }
    }
  }

  .segment {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 20px;
    max-width: 1380px;
    padding: 40px 40px 20%;

    @include md {
      gap: 5%;
      grid-template-columns: 4fr 5fr;
      grid-template-rows: auto;
      padding: clamp(10px, 5%, 50px);

      &.inverse {
        grid-template-columns: 5fr 4fr;
        .contain {
          grid-column: 1/2;
        }
      }

      &.containSide {
        .visuel {
          grid-row: 1/3;
          align-self: center;
          justify-self: flex-end;
        }
        .action {
          justify-self: start;
        }
        &.inverse {
          .contain {
            grid-row: 1/2;
          }
        }
      }
    }

    .visuel {
      @include md {
        align-self: flex-end;
        justify-self: start;
      }

      img {
        max-width: 400px;
        width: 100%;

        @include xl {
          max-width: 500px;
        }
      }
    }

    .action {
      order: 3;
      padding: 20px 0;

      @include md {
        align-self: start;
      }
    }

    .contain {
      @include md {
        grid-column: 2/3;
        grid-row: 1/3;
      }
    }

    .contains {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .place-prices-comparer {
    background: #ededed;
    padding: 20px 10px 10px 10px;
    flex: 1 1 100%;
    margin-top: 50px;
    .ppc-table {
      color: #1c2746;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
      .ppc-col {
        margin-bottom: 10px;
        &:after {
          clear: both;
          display: table;
          content: ' ';
        }
        .ppc-heading {
          text-align: center;
          padding-bottom: 5px;
          font-weight: bold;
          .sub-label {
            font-size: 10px;
          }
        }
      }
      .ppc-cell {
        background: #d7d7d7;
        padding: 8px 5px;
        text-align: center;
        float: left;
        width: 49%;
        font-weight: bold;
        &:not(:last-child) {
          margin-right: 2%;
        }
        &.ppc-cell-important {
          background: #1c2747;
          color: #fed02f;
          font-weight: 500;
        }
        .ico {
          background: #fff;
          width: 20px;
          height: 20px;
          color: #1c2747;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin: 0 auto;
          margin-bottom: 5px;
          img {
            display: block;
          }
        }
        .ppc-sub-cell:not(:last-child):after {
          width: 25px;
          height: 0;
          content: '';
          border-bottom: solid 1px;
          display: block;
          margin: auto;
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }
    }
    .footer {
      font-size: 11px;
    }
  }
  @media (min-width: 640px) {
    .place-prices-comparer {
      padding: 30px 15px 15px 15px;
    }
    .place-prices-comparer .ppc-table .ppc-cell {
      margin-bottom: 5px;
    }
    .place-prices-comparer .ppc-table .ppc-cell .ppc-sub-cell > .ico {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
    .place-prices-comparer .ppc-table .ppc-cell .ppc-sub-cell > .ppc-saved {
      display: inline;
    }
    .place-prices-comparer .ppc-table .ppc-cell .ppc-sub-cell > div:not(:last-child) {
      margin-right: 15px;
    }
  }
  @media (min-width: 960px) {
    .place-prices-comparer {
      padding: 30px 40px 20px 40px;
    }
    .place-prices-comparer .ppc-table {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .place-prices-comparer .ppc-table .ppc-col:nth-child(1) {
      width: 100px;
    }
    .place-prices-comparer .ppc-table .ppc-col:nth-child(2),
    .place-prices-comparer .ppc-table .ppc-col:nth-child(3) {
      width: 160px;
    }
    .place-prices-comparer .ppc-table .ppc-cell {
      float: none;
      width: auto;
    }
    .place-prices-comparer .ppc-table .ppc-col .ppc-heading {
      text-align: left;
    }
    .place-prices-comparer .ppc-table .ppc-cell:not(:last-child) {
      margin-right: 0;
    }

    .place-prices-comparer .ppc-table .ppc-cell {
      padding-bottom: 9px; /*Rattrapage dÃ©calage d'un px'*/
    }
    .place-prices-comparer .ppc-table .ppc-cell.ppc-cell-big {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 8px 20px;
    }
    .place-prices-comparer .ppc-table .ppc-cell .ico,
    .place-prices-comparer .ppc-table .ppc-cell .ppc-saved {
      margin: 0;
      vertical-align: bottom;
    }
    .place-prices-comparer .ppc-table .ppc-cell .ppc-sub-cell:not(:last-child) {
      margin-right: 15px;
    }
    .place-prices-comparer .ppc-table .ppc-cell .ppc-sub-cell:not(:last-child):after {
      width: 0px;
      height: 20px;
      content: '';
      border-bottom: none;
      border-right: solid 1px;
      margin: 0;
      margin-left: 10px;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

.lpBoxed {
  max-width: 1380px;
  margin: 0 auto;
  padding: clamp(10px, 5%, 50px);

  @include md {
    padding: clamp(10px, 10%, 40px) clamp(10px, 10%, 80px);
  }
}
