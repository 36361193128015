@import 'src/styles/var';

ul.social-medias {
  display: flex;
  justify-content: center;
  column-gap: 30px;

  li {
    list-style-type: none;

    a {
      display: flex;

      svg {
        width: 46px;
        height: 46px;
        padding: 10px;
        border: 1px solid $blue-blue;
        border-radius: 100%;
        fill: $blue-blue;
        transition: all 0.1s ease-in-out;
      }


      &:hover {
        svg {
          fill: $dark-blue;
          border-color: $dark-blue;
        }
      }
    }
  }
}