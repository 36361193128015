@import 'src/styles/var';
@import 'src/styles/mixins';

.stars {
  padding: 20px 5px;

  border-radius: 8px;
  border: 1px solid $light-blue-grey;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 0;
      display: inline-block;
      text-align: center;

      button {
        width: 80px;
        @include reset-button
      }

      .star {
        display: inline-block;
        width: 35px;
        height: 31px;
        background: url('../../../../../../static/images/icons/star-on.svg') center top no-repeat;
      }

      span {
        display: block;
        color: $light-blue-grey;
        font-size: em(10px);
        font-weight: 600;
        font-stretch: condensed;
        text-transform: uppercase;
      }

      &.active {
        .star {
          background-image: url('../../../../../../static/images/icons/star-on.svg');
        }

        & ~ li .star {
          background-image: url('../../../../../../static/images/icons/star-off.svg');
        }

        span {
          color: $blue-blue;
        }
      }
    }
  }
}
