.starsContainer {
  display: flex;
  align-items: center;
  gap: 2px;

  .star {
    height: 20px;

    path {
      fill: $tealish;
    }

    &.unfilled {
      > path {
        fill: #2cd5c459;
      }
    }
  }
}
