* {
	box-sizing: border-box;
	outline: none;
}

img {
	display: inline-block;
	margin: 0;
	vertical-align: top;
}

.left {
	float: left;
}
.right {
	float: right;
}
