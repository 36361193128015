.optionSelection {
  .services {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;

    .info {
      color: $tertiary;
      font-weight: 500;
      font-size: em(10px);
      margin-bottom: $gapColRows;
      margin-top: -15px;
      float: left;

      &:before {
        @include border-radius(50%);

        content: "!";
        background: $tertiary;
        color: #fff;
        margin-right: 4px;
        width: 12px;
        height: 12px;
        line-height: 1em;
        text-align: center;
        display: inline-block;
      }
    }

    .warning {
      position: relative;
      border: 0.5px solid $light-blue-grey;
      color: $blue-grey;
      margin-bottom: 40px;
      padding: $gapColRows $gapColRows $gapColRows 48px;
      text-align: left;
      font-size: em(15px);

      &::before {
        content: "i";
        position: absolute;
        display: table-cell;
        top: 25px;
        left: 20px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: 10px;
        font-weight: bold;
        border: 0.5px solid $blue-grey;
        border-radius: 50%;
        text-align: center;
      }
    }

    .service {
      position: relative;
      display: flex;
      flex-direction: column;
      border: 0.8px solid $light-blue-grey;
      border-radius: 10px;
      box-sizing: border-box;
      width: 100%;

      .content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1 1 100%;
      }

      &:hover,
      &.selected {
        border-color: $blue-blue;

        .choose {
          border-color: $blue-blue;
        }
      }

      &.selected {
        background-color: #f1f3fb;

        .choose .ButtonIcon {
          background-color: $blue-blue;

          .icon {
            transform: rotate(0deg);
            fill: $white;
          }
        }
      }

      &.disabled {
        border-color: $light-blue-grey;

        h4,
        .choose .price {
          color: $blue-grey;
          opacity: .5;
        }

        .Tooltip {
          background-color: $light-grey;
          color: $light-blue-grey;
          border:.5px solid $light-blue-grey;
        }

        .choose .ButtonIcon {
          border-color: $light-blue-grey;
          opacity: .5;

          .icon {
            fill: $blue-grey;
          }
        }

        &:hover,
        &.selected {
          border-color: $light-blue-grey;

          .choose {
            border-color: $light-blue-grey;
          }
        }
      }

      h4 {
        width: 100%;
        max-height: 100px;
        margin: 0;
        font-size: 14px;
        color: $blue-blue;
        text-transform: uppercase;
        line-height: normal;
        overflow: hidden;
      }

      .informations {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 25px;

        .Tooltip {
          position: absolute;
          font-size: 11px;
          width: max-content;
          top: -12px;
          right: 50%;
          left: 50%;
          transform: translateX(-50%);

          @include sm {
            top: 10px;
            right: 10px;
            left: auto;
            transform: none;
          }


          background: $pale-grey;
          color: $warm-blue;
          border: solid 0.5px rgba(65, 105, 225, 0.5);
        }
      }

      .details {
        color: $blue-grey;
        font-style: italic;
      }

      .see-more {
        align-self: flex-start;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: $blue-grey;
        cursor: pointer;

        span {
          border-bottom: 1px solid $blue-grey;
        }

        .icon-chevron {
          fill: $blue-grey;
        }
      }

      .choose {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        padding: 15px 20px;
        border-top: 1px solid $light-blue-grey;

        .price {
          font-weight: 700;
          font-size: 20px;
          color: $blue-blue;
        }

        .ButtonIcon {
          width: 40px;
          min-width: 40px;
          height: 40px;
          padding: 0;
          border-radius: 50%;
          outline: none;
          cursor: pointer;

          .icon {
            transform: rotate(45deg);
          }
        }
      }
    }

    .ModalService {
      .modal-content {
        max-height: 275px;
        overflow-y: auto;
      }
    }
  }
}
