#FastBookingRoot {
  &:empty,
  > .fallback {
    height: 368px;
    @include sm {
      height: 383px;
    }
    @include lg {
      height: 524px;
    }
  }
}

.bookingFormContainer {
  border-radius: 20px;
  background-color: $white;
  z-index: 1;
  margin: auto;
  height: max-content;
  width: 100%;

  @include sm {
    width: 500px;
  }

  @include md {
    margin: 0;
  }

  @include lg {
    width: 450px;
    order: 1;
  }

  @include xl {
    width: 400px;
  }

  .estimateError {
    font-weight: 600;
    color: $error;
    text-align: center;
  }

  .otps-form, .result {
    .Button.primary {
      height: 50px;
      background-color: $watermelon;
      border-color: $watermelon;
      transition: all 150ms ease-in-out;
      text-transform: none;
      font-size: 18px !important;
      font-weight: 700;
      letter-spacing: normal !important;

      border-radius: 0 0 20px 20px;
      padding: 30px 0;
      margin: 0;

      &.small {
        font-size: 15px !important;
      }

      &:not(.disabled) {
        &:hover {
          background-color: darken($watermelon, 7%);
          border-color: darken($watermelon, 7%);
        }
      }
    }
  }

  #bookingHome {
    padding: 15px;
    background-color: $white;
    border-radius: 20px;

    @include md {
      padding: 25px 35px;
    }

    .search {
      .title {
        padding: 10px;
        font-size: 20.5px;
        font-weight: bold;
        color: $blue-blue;
        letter-spacing: normal;

        @media #{$tab-portrait} {
          display: none;
        }
      }

      .arrivalSiteText {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 10px;
        text-decoration: underline;
        font-size: 12px;
        margin-top: -6px;
        cursor: pointer;
        color: $primary;
        background: none;
        border: none;
        display: block;
        text-transform: none;
        letter-spacing: inherit;
        text-align: inherit;
        font-weight: 500;

        &:hover {
          color: $tertiary;
        }
      }

      .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > :nth-child(1) {
          width: 100%;
          margin: 5px 0;

          @include sm {
            margin: 10px 0;
          }
        }
        > .DayPicker,
        > .HourPicker {
          margin: 5px 0;
          width: calc(50% - 2px);

          @include sm {
            margin: 10px 0;
            width: calc(50% - 10px);
          }
        }

        .input-label {
          @media screen and (min-width: 350px) {
            text-overflow: initial;
            overflow: initial;
          }
        }

        .InputSelect.empty .selected {
          color: $blue-grey;
          font-size: em(14px);
        }
      }
    }

    .BookingHomeSeparator {
      background-image: linear-gradient(to right, $light-blue-grey 50%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 16px 1px;
      background-repeat: repeat-x;
      height: 1px;
      margin: 25px 0;

      @media #{$tab-portrait} {
        display: none;
      }
    }

    .otps-form {
      display: flex;
      justify-content: center;
      margin: 10px -15px -15px;

      @include md {
        margin: 20px -35px -25px;
      }

      .Button {
        border-radius: 0 0 20px 20px;
        width: 100%;

        &.disabled {
          display: none;
        }
      }
    }
  }

  #estimationSummaryForm {
    position: relative;
    z-index: -20;

    .result {
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: -10;
      border-radius: 20px;
      background-image: linear-gradient(179deg, #eef1f4 15%, #fff 197%);
      margin-top: -35px;
      padding: 50px 30px 0;

      .servicesIncludedSummary {
        margin: 15px 0;
        line-height: normal;
        color: $dark-blue;
        font-weight: 600;
        text-align: center;
      }

      .priceSumUpContainer {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 5%;

        .priceSumUp {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          border-radius: 10px;
          background: #fff;
          padding: 25px 0 15px;
          gap: 8px;

          .totalPrice {
            font-size: 28px;
            margin: 0;
            font-weight: bold;
            color: $blue-blue;
            text-align: center;

            @include sm {
              font-size: 30px;
            }
          }

          .pricePerDay {
            font-size: 14px;
            font-style: italic;
            margin: 0;
            color: $warm-blue;
            line-height: normal;
            text-align: center;
          }

          &.crossedOut,
          &.discount {
            .totalPrice {
              font-size: 25px; // fallback if clamp() isn't supported
              font-size: clamp(20px, 6vw, 30px);
            }

            .pricePerDay {
              font-size: 14px; // fallback if clamp() isn't supported
              font-size: clamp(10px, 3vw, 14px);
              font-weight: 500;

              @include sm {
                font-weight: normal;
              }
            }
          }

          &.discount {
            position: relative;

            .discountLabel {
              position: absolute;
              top: -6px;
              left: 0;
              right: 0;
              margin: 0 auto;
              padding: 0 6px;
              width: max-content;
              height: 19px;
              background-color: #ff446c;
              color: white;
              font-size: 11px;
              font-weight: bold;
              line-height: 22px;
              text-transform: uppercase;
              border-radius: 10px;
            }
          }

          &.crossedOut {
            position: relative;
            background-color: unset;
            border: unset;

            .totalPrice,
            .pricePerDay {
              color: $dark-blue;
            }

            &::after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              margin: 0 auto;
              background-color: #ff446c;
              width: 95%;
              height: 3px;
              border-radius: 20px;
              transform: rotate(156deg);
            }
          }
        }
      }

      .simulatePrice {
        padding: 15px 0 0;
        margin: 10px -30px 0;

        button {
          font-size: 20px !important;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}


@media #{$tab-portrait} {
  #FastBookingRoot {
    .form {
      div.label {
        margin: 0 21px;
      }

      .HourPicker {
        div.label {
          margin: 0 14px;
        }
      }
    }

    #start-date,
    #end-date {
      border-radius: 20px 0 0 20px;
      padding: 11px 10px 9px 20px;
    }

    #bookingHome .search .form {
      > .DayPicker,
      > .HourPicker {
        width: 50%;
      }
    }

    .HourPicker .selectContainer {
      border-radius: 0 20px 20px 0;
      padding-left: 15px;
      border-left-width: 0;

      &:focus-within {
        border-left-width: 0.8px;
      }
    }

    .DayPicker svg.icon.calendar,
    .HourPicker svg.icon.time {
      display: none;
    }
  }
}
