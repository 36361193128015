#media {
  .media-list {
    padding: 0 0 100px;

    @media #{$phoneL} {
      padding: 0;
    }

    h1 {
      font-size: em(42px);
      line-height: em(50px);
      text-align: center;
      font-weight: 600;
      color: $blue-blue;
    }

    .medias {
      padding: 30px 0 40px;

      @media #{$phoneL} {
        padding: 0 0 30px;
      }

      .media {
        display: flex;
        align-items: center;
        margin: 10px 0;
        padding: 20px;
        background-color: $white;
        border: 0.5px solid $light-blue-grey;

        @media #{$phoneL} {
          flex-direction: column;
        }

        .media-image {
          img {
            width: 110px;
          }
        }

        .media-description {
          flex: 1 1 auto;
          padding: 0 50px;

          @media #{$phoneL} {
            padding: 10px 0;
            text-align: center;
          }

          p {
            font-size: em(15px);
            font-weight: 600;
            color: $dark-blue;
            line-height: em(25px);
          }
        }

        .media-action {
          a {
            font-size: em(11px);
            color: $blue-grey;
            border: 0.8px solid $blue-grey;

            &:hover {
              color: $blue-blue;
              border: 0.8px solid $blue-blue;
            }
          }
        }
      }
    }
  }

  .media-kit {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
    border: 0.5px solid $light-blue-grey;
    border-radius: 1px;
    overflow: hidden;

    @media #{$phoneL} {
      margin-top: 0;
    }

    .image {
      position: relative;
      width: 50%;
      overflow: hidden;

      @media #{$phoneL} {
        width: 100%;
        height: 230px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }

    .content {
      width: 50%;
      padding: 40px;

      @media #{$phoneL} {
        width: 100%;
        padding: 0 20px 30px;
      }

      h2 {
        font-size: em(20px);
        font-weight: bold;
        letter-spacing: 1.8px;
        color: $dark-blue;
        text-transform: uppercase;
      }

      p {
        font-size: em(15px);
        line-height: 1.67;
      }

      .action {
        margin-top: 30px;
        text-align: right;

        @media #{$phoneL} {
          text-align: center;
        }
      }
    }
  }

  .media-contact {
    text-align: center;

    @media #{$phoneL} {
      padding: 40px 20px 20px;
    }

    a.button {
      margin-bottom: 10px;
    }

    p {
      font-size: em(15px);
      color: $dark-blue;

      a {
        color: $blue-blue;
        font-weight: bold;
        transition: color 0.15s ease-in-out;

        &:hover {
          color: $dark-blue;
        }
      }
    }
  }
}