.green-chip {
  display: block;

  &::after {
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    background-color: $tealish;
  }

  @media #{$tab-portrait} {
    &.hidden-tab-portrait {
      display: none;
    }
  }

  @media #{$phoneL} {
    &.hidden-phone {
      display: none;
    }
  }
}