.recruitment {
  .page-header {
    .image img {
      border-radius: 10px;
    }

    @media #{$phoneL} {
      .image {
          img {
          transform: translateY(-30%);
        }
      }
    }

    .titles {
      h2 {
        padding-top: 10px;
        font-size: em(15px);
        color: $dark-blue;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }

  .why-join {
    display: flex;
    flex-wrap: wrap;

    h2 {
      width: 100%;
      text-align: left !important;
      padding-bottom: 50px !important;

      @media #{$phoneL} {
        text-align: center !important;
      }
    }

    .item {
      padding: 0 80px 60px 0;
      width: calc(100% / 3);

      @media #{$tab-portrait} {
        width: 50%;
        padding: 0 60px 40px 0;
      }

      @media #{$phoneL} {
        width: 100%;
        text-align: center;
        padding: 10px 20px;
      }

      .icon {
        img {
          width: 50px;
        }
      }

      h3 {
        font-size: em(16px);
        color: $dark-blue;
        letter-spacing: 1.3px;
        text-transform: uppercase;
      }

      p {
        font-size: em(15px);
        line-height: em(25px);
      }
    }
  }

  .valets-pictures {
    display: flex;
    gap: 25px;
    padding-bottom: 60px;

    @media #{$phoneL} {
      gap: 10px;
      padding-top: 25px;
    }

    .valets-picture {
      flex-grow: 1;
      flex-basis: 0;

      img {
        width: 100%;
        border-radius: 10px;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
  }

  .statistics {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 20px 0;
      list-style: none;

      li {
        font-family: AvenirNextCondensed;
        width: 25%;
        padding-right: 60px;
        padding-bottom: 40px;
        font-size: em(16px);
        font-weight: bold;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-stretch: condensed;

        @media #{$tab-portrait} {
          width: 50%;
        }

        @media #{$phoneL} {
          width: 100%;
          padding: 20px 0;
          text-align: center;
        }

        strong {
          font-family: AvenirNext;
          display: block;
          font-size: em(44px);
          color: $tealish;
          font-weight: bold;
          line-height: em(60px);
          letter-spacing: normal;
          text-transform: none;
        }
      }
    }
  }

  #join-us {
    .boxed {
      h2 {
        text-align: left;
        color: $blue-blue;
      }

      > h3 {
        font-size: em(21px);
        font-weight: 600;
        color: $dark-blue;
        padding: 5px 0;
      }

      @media #{$phoneL} {
        h2, > h3 {
          text-align: center;
        }
      }
    }
  }
}