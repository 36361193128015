#ReviewsListRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 650px;
  justify-content: space-between;

  @include lg {
    min-height: 800px;
  }

  .reviewsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    list-style: none;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    width: 100%;

    @include lg {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .review {
      background-color: white;
      border-radius: 30px;
      border: solid 1px rgba(20, 55, 185, 0.1);
      padding: 20px;
      width: 90%;
      min-width: 280px;
      max-width: 450px;
      min-height: 130px;

      @include lg {
        height: 100%;
        width: 100%;
        max-width: 600px;
        align-self: center;
      }

      .header {
        display: flex;
        justify-content: space-between;

        .authorWithRating {
          .author {
            font-size: 16px;
            font-weight: 600;
            margin: 4px 0;
          }
        }

        .date {
          font-size: 12px;
          font-weight: 500;
          color: $tealish;
        }
      }

      .review-rating {
        @media #{$tab-portrait-only} {
          float: none;
        }

        img {
          margin: 5px 1.5px;
          height: 15px;
          @media #{$phoneL} {
            height: 12px;
          }
        }
      }
    }
    p {
      margin: 0;

      &.content {
        font-style: italic;
      }
    }
  }
}
