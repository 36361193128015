.subscription {

	.steps {
		display: table;
		border-spacing: 20px 0;
		width: calc( 100% + 40px );
		margin: 0 -20px 30px;
		@media #{$phoneL} {
			display: block;
			width: 100%;
			margin: 0 0 30px;
		}
		.step {
			@extend .clearfix;
			display: table-cell;
			width: calc( 100% / 3 );
			vertical-align: top;
			margin: 0;
			@media #{$phoneL} {
				display: block;
				width: 100%;
			}
			span {
				float: left;
				font-family: 'Syncopate';
				color: $tertiary;
				font-size: em(53em);
				line-height: normal;
				width: 60px;
				vertical-align: top;
			}
			p {
				float: left;
				width: calc( 100% - 60px );
				vertical-align: top;
				margin: 0;
				@media #{$phoneL} {
					margin-bottom: 20px;
				}
			}
		}
	}

	.subscriptionTable {
		display: table;
		width: 100%;
		@media #{$tab-portrait} {
			display: block;
			width: 100%;
		}
		& > div {
			display: table-cell;
			width: calc( 100% / 3 );
			text-align: center;
			position: relative;
			vertical-align: top;
			@media #{$tab-portrait} {
				display: block;
				width: 100%;
				overflow: hidden;
			}
			&:before {
				@include translate(0, 0);
				@include rotate(45deg);
				position: absolute;
				top: 54px;
				right: -19px;
				content: '';
				width: 37px;
				height: 37px;
				z-index: 10;
				@media #{$tab-portrait} {
					display: none;
				}
			}
			h3 {
				text-transform: uppercase;
				font-family: 'Syncopate';
				font-size: em(22px);
				text-align: center;
				margin: 50px 0 0 0;
				padding: 0;
				font-weight: normal;
				color: $primary;
				@media #{$tab-portrait} {
					margin: 20px 0 0 0;
				}
			}
			em {
				color: $secondary;
				font-weight: 500;
				font-style: normal;
				display: block;
				margin-bottom: 30px;
				@media #{$tab-portrait} {
					margin-bottom: 10px;
				}
			}
			h4 {
				font-weight: 500;
				font-size: em(35px);
				margin: 0;
				strong {
					font-size: em(14px);
					display: block;
					margin-top: 5px;
				}
			}
			.price {
				background: rgba( #000, .05 );
				padding: 20px 0;
				margin-top: 40px;
				@media #{$tab-portrait} {
					margin-top: 10px;
				}
				span {
					font-size: em(25px);
					display: block;
					margin-bottom: 20px;
				}
				a {
					width: 130px;
				}
			}
			&.first {
				background: #FFF;
				border: 1px solid #95989A;
				border-right: 0 none;
				@media #{$tab-portrait} {
					border-right: 1px solid #95989A;
				}
				&:before {
					background: #FFF;
				}
			}
			&.gold {
				background: $tertiary;
				border-right: 0 none;
				color: #FFF;
				h3 {
					color: #FFF;
				}
				&:before {
					background: $tertiary;
				}
			}
			&.premium {
				background: $primary;
				border-right: 0 none;
				color: #FFF;
				overflow: hidden;
				h3 {
					color: #FFF;
				}
				&:before {
					display: none;
				}
				&:after {
					@include rotate(45deg);
					@include transform-origin(50%, 50%);
					position: absolute;
					top: 15px;
					right: -25px;
					background: $secondary;
					content: 'Le + populaire';
					font-size: em(10px);
					line-height: normal;
					padding: 5px 0;
					text-align: center;
					color: $primary;
					width: 100px;
				}
			}
		}
	}
}
