.fastBooking {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 5%;

  @include md {
    padding-left: 10%;
    padding-right: 10%;
  }

  @include xl {
    padding: 5% 5% 3%;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }

  @include xxl {
    padding: 75px;
    max-width: 1380px;
    margin: auto;
  }

  // It is necessary to keep the height similar to that of the children of the fastbooking component
  // as long as the react rendering is not yet ready.
  // This is mainly to avoid a large cumulative shift (CLS) in the layout.
  &:empty {
    height: 890px;
    @include sm {
      height: 955px;
    }
    @include md {
      height: 1000px;
    }
    @include lg {
      height: 1162px;
    }
    @include xl {
      height: 662px;
    }
    @include xxl {
      height: 722px;
    }
  }

  @include sm {
    .bookingFormContainer {
      width: 500px;
    }
  }

  @include lg {
    .bookingFormContainer {
      width: 450px;
    }
  }

  @include xl {
    .bookingFormContainer {
      width: 400px;
    }
    .contentContainer {
      width: calc(100% - 400px);
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;

    @include xl {
      align-items: center;
      order: 2;
    }

    h1 {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      opacity: 0.95;
      letter-spacing: normal;
      margin: 10px 0 20px;
      color: $white;
      font-size: 20px;

      @include sm {
        font-size: 30px;
      }

      @include md {
        font-size: 38px;
        margin-top: 0;
      }

      @include xl {
        font-size: 40px;
        text-align: center;
      }

      @include xxl {
        font-size: 42px;
      }
    }

    img.illustration {
      max-width: 550px;
      display: none;

      @include xl {
        display: block;
        min-width: 350px;
        width: 100%;
        max-height: 300px;
        margin: 0 auto -16px;
      }
    }
  }

  .subtitle {
    color: $white;
    font-size: 16px;
    margin: 0 0 20px;

    @include md {
      font-size: 18px;
    }
    @include xl {
      text-align: center;
    }

    &.second {
      font-weight: bold;
    }
  }

  .reviewsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 10px;
    margin-top: 60px;

    @media (max-width: 359px) {
      flex-direction: column;
    }

    @include md {
      gap: 20px;
      margin-top: 0;
    }

    .review {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 165px;
      padding: 8px 10px;
      background-color: #809cf0;
      border-radius: 10px;
      gap: 4px;

      @include md {
        width: 200px;
        gap: 0;
        padding: 6px 10px;
      }

      @include xl {
        background-color: #{$dark-blue}20;
      }

      p {
        margin: 0;
      }

      .ratingContainer {
        display: flex;
        gap: 5px;

        p {
          font-size: 14px;
          line-height: 20px;

          @include md {
            font-size: 15px;
            line-height: 25px;
          }
        }

        .rateAmount {
          margin-top: 3px;
          @include md {
            margin-top: 5px;
          }
          color: #9cf9f0;
          font-weight: 600;
        }

        .starsContainer {
          gap: 0;
          @include md {
            gap: 2px;
          }

          .star {
            height: 14px;
            @include md {
              height: 20px;
            }

            path {
              fill: #9cf9f0;
            }

            &.unfilled {
              path {
                fill: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }
      }

      .totalReviews {
        color: white;

        span {
          font-weight: 700;
        }

        font-size: 14px;
        line-height: 20px;

        @include md {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }

    img {
      margin: 0 1.5px;
      height: 15px;
      @media #{$phoneL} {
        height: 12px;
      }
    }
  }

  .afterSimulation {
    display: block;
    order: 2;
    @include xl {
      display: none;
    }

    img.illustration {
      width: 85%;
      margin: 10px auto;
      display: block;
    }
    .subtitle.second {
      color: $warm-blue;
    }
  }

  .contentContainer {
    .reviewsDesktop {
      display: none;
      margin: 0 0 20px;

      @include xl {
        display: block;
      }
    }
    .subtitle.second {
      display: none;
      @include xl {
        display: block;
      }
    }
  }

}
