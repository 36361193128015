.business-login {
  .action {
    padding-top: 20px;
    text-align: center;
  }
}

.business-reset-password {
  .message-highlight {
    background: transparent;
  }

  .action {
    a {
      display: block;
      margin-top: 20px;
      font-size: em(15px);
      font-weight: 600;

      &:hover {
        color: $dark-blue;
      }
    }
  }
}

.business-unknow {
  p {
    text-align: center;
  }

  .action {
    a.second {
      margin: 10px 0 20px;
    }
  }
}
