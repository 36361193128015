$gapColRows: 18px;

#StepsMenu {
  position: sticky;
  top: 65px;
  left: 0;
  z-index: 10000;
  background-color: $white;
  border-bottom: 0.8px solid $light-blue-grey;
  min-height: $scrollableMenuHeight;

  .StepsFlowWrapper {
    display: flex;
    align-items: center;

    .StepsMenu {
      flex: 1 1 auto;
    }

    .StepsMenuBack {
      border-right: 1px solid $light-blue-grey;
      height: 60px;
      padding: 0 4px;
      display: flex;
      align-items: center;
      z-index: 1;

      svg {
        transform: rotate(180deg);
        cursor: pointer;
        path {
          fill: $dark-blue;
        }
      }
    }
  }

  .StepsFlow {
    padding: 10px 0;

    .navigation {
      max-width: 900px;
      margin: 0 auto;
      border: none;

      li {
        position: relative;

        a.active,
        &:hover a {
          background-color: $white;
        }

        .ButtonIcon {
          margin: 0;
        }
      }
    }
  }
}

@media #{$tab-portrait} {
  #StepsMenu {
    top: 0;
  }
}

@media #{$tab-portrait} {
  .StepsMenu {
    padding: 0 calc(#{$gapColRows} / 2);
  }
}

@media #{$desktop} {
  #StepsMenu {
    width: calc(100% - 316px);
  }
  .StepsMenu {
    .StepsFlow {
      width: 95%;
      max-width: 820px;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 1180px) {
  .StepsMenu {
    transform: translateX(-24px);
  }
}

.smartbanner-show #StepsMenu {
  top: 110px;
}

@media #{$desktop} {
  .noHeader #StepsMenu {
    top: 0;
  }
}
