.blog {
  padding-bottom: 40px;

  &.detail {
    margin-bottom: 50px;
    padding: 70px 120px;
    background-color: $white;
    border: 0.5px solid $light-blue-grey;

    @media #{$tab-portrait} {
      padding: 30px 50px;
    }

    @media #{$phoneL} {
      padding: 20px;
    }
  }

  .articles {
    display: flex;
    flex-wrap: wrap;

    .article {
      width: 50%;
      padding-bottom: 50px;

      &:nth-child(odd) {
        padding-right: 25px;
      }

      &:nth-child(even) {
        padding-left: 25px;
      }

      @media #{$tab-portrait} {
        padding-bottom: 20px;

        &:nth-child(odd) {
          padding-right: 10px;
        }

        &:nth-child(even) {
          padding-left: 10px;
        }
      }

      @media #{$phoneL} {
        width: 100%;

        &:nth-child(odd),
        &:nth-child(even) {
          padding: 0 0 20px 0;
        }
      }

      .article-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: $white;
        box-shadow: 0px 0px 0px 0.5px $light-blue-grey;
        overflow: hidden;
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
          box-shadow: 0px 0px 0px 0.5px $dark-blue;
        }

        .visuel {
          position: relative;
          padding-bottom: 66%;
          overflow: hidden;

          img {
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h2 {
          flex: 1 1 auto;
          margin: 0;
          padding: 20px;
          font-size: em(20px);
          color: $dark-blue;
          line-height: em(30px);
          font-weight: 600;
        }

        span.date {
          padding: 0 20px 20px;
        }
      }
    }
  }

  span.date {
    font-family: AvenirNextCondensed;
    display: block;
    font-size: em(12px);
    color: $blue-grey;
    font-weight: bold;
    text-transform: uppercase;
    line-height: em(30px);
    letter-spacing: 1px;
  }

  article {
    & > a {
      color: $tertiary;
      font-size: em(14px);
      display: inline-block;
      margin-bottom: 10px;
    }

    h1 {
      font-size: em(34px);
      font-weight: 600;
      color: $dark-blue;
      line-height: em(50px);
    }

    .richeText {
      padding-top: 50px;
      font-size: em(16px);
      line-height: em(26px);

      @include lg {
        font-size: em(15px);
        line-height: em(25px);
      }

      h2,
      h2 strong {
        font-size: em(28px);
        font-weight: 600;
        color: $dark-blue;
        text-align: left;
        line-height: normal;
      }

      h3,
      h3 strong {
        font-size: em(22px);
        font-weight: 600;
        color: $dark-blue;
        text-align: left;
        line-height: normal;
      }

      h4,
      h4 strong {
        font-size: em(18px);
        font-weight: 600;
        color: $dark-blue;
        text-align: left;
        line-height: normal;
      }

      h2 strong,
      h3 strong,
      h4 strong {
        font-weight: bold;
      }

      a {
        font-size: em(16px);
        @include lg {
          font-size: em(15px);
        }
      }

      p {
        font-size: em(16px);
        line-height: em(30px);
        @include lg {
          font-size: em(15px);
          line-height: em(25px);
        }
      }

      .mce-toc {
        ul {
          list-style: none;

          a {
            font-weight: 600;
          }

          li {
            margin: 10px 0;
            @include lg {
              margin: 6px 0;
            }
          }

          li > ul  {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
