.faqSection {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 40px 10vw;
  border: solid 1px $pale-grey;
  max-width: unset;
  border-radius: 20px;
  background: $white;

  @include lg {
    padding: 40px 90px;
    margin: auto;
  }

  h3 {
    font-size: 32px;
    color: $dark-blue;
    line-height: normal;
    text-align: left;
    margin-bottom: 0;

    @include lg {
      font-size: 38px;
    }
  }

  .accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .accordion-item {
      width: 100%;
      border-bottom: 2px solid rgba(128, 128, 128, 0.1);

      .Button {
        all: unset;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
        padding: 15px 0;
        cursor: pointer;
        border: none;
        color: $dark-blue;

        &:hover {
          background-color: rgba(0, 0, 0, 0.035);
        }

        .accordion-title {
          max-width: calc(100% - 40px);
          font-size: 16px;
          line-height: 1.5;
          font-weight: 600;
          text-transform: initial;
          text-align: left;
          white-space: initial;

          @include lg {
            font-size: 18px;
            line-height: 2.5;
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border: solid 2px rgba(44, 213, 196, 0.21);
          border-radius: 22px;
          margin-left: 5px;
          transition: transform 0.3s ease-in-out;
        }

        &[aria-expanded='true'] {
          .icon {
            transform: rotate(180deg);
            border-color: $tealish;
          }

          + .content {
            display: initial;
          }
        }
      }

      .content {
        display: none;

        a {
          font-size: inherit;
          text-decoration: underline;
        }

        a:hover {
          color: $dark-blue;
        }

        p {
          color: $blue-blue;
          font-weight: 500;

          @include lg {
            font-size: 16px;
          }

          strong {
            font-weight: bold;
          }
        }
      }
    }
  }

  .zendesk-redirection {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }
}
