.auth-page {
	.form {
		max-width: 455px;
		margin: 0 auto;
		padding: 50px 0 100px;

		h1 {
			margin: 0;
			padding: 15px 10px;
			font-size: em(26px);
			line-height: em(35px);
			font-weight: 600;
			color: $white;
			background: $dark-blue;
			text-align: center;
		}

		.content {
			padding: 30px;
			background: $white;
			border: 0.3px solid $light-blue-grey;
			border-top: none;

			.field {
				margin-bottom: 30px;

				label {
					padding-left: 10px;
					font-size: em(11px);
					font-weight: 600;
					color: $dark-blue;
					letter-spacing: 1px;
					text-transform: uppercase;

					span {
						position: relative;
						float: right;

						svg {
							height: 15px;
							width: 15px;
							cursor: pointer;
							fill: $dark-blue;
						}
	
						em {
							@include translate(-50%, 0);
							display: none;
							position: absolute;
							left: 50%;
							background: $pale-grey;
							padding: 10px 20px;
							text-align: left;
							font-size: em(12px);
							font-style: normal;
							line-height: em(20px);
							width: 480px;
							bottom: 28px;
							text-transform: none;
							font-weight: normal;
	
							p {
									margin-bottom: 10px;
							}
							@media #{$phoneL} {
								@include translate(calc( -100% + 20px ), 0);
								width: 300px;
							}
	
							&:before {
								@include translate(-50%, 0);
								content: '';
								position: absolute;
								bottom: -7px;
								left: 50%;
								width: 0;
								height: 0;
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 7px 7px 0 7px;
								border-color: $pale-grey transparent transparent
									transparent;
									@media #{$phoneL} {
										left: auto;
										right: 6px;
									}
							}
						}
						&:hover {
							em {
								display: block;
							}
						}
					}
				}

				.reset-password {
					float: right;
					margin: 0;
					color: $blue-grey;
					font-size: em(12px);
					font-style: italic;
					transition: color 0.15s ease-in-out;
	
					&:hover {
						color: $blue-blue;
					}
				}
			}

			.action {
				text-align: center;
			}

			.link {
				text-align: center;
				margin-top: 30px;
				font-size: em(15px);
				color: $dark-blue;
				
				&.top {
					margin-top: 0;
					margin-bottom: 30px;
				}

				a {
					margin-left: 2px;
					color: $blue-blue;
					font-weight: 600;
				}
			}
		}
	}

	&.business-selector {
		.content {
			text-align: center;

			a, button {
				margin: 10px 0;
			}
		}
	}
}