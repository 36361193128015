#order-total {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  padding: 0 15px 50px;

  .cart-end {
    .action {
      margin-top: 35px;
      text-align: center;
    }
  }
}

@media #{$tab-portrait} {
  #order-total .booking-content .form {
    padding: 20px 15px;
  }
}
