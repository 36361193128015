.lpLongStay {

  .lpBlock  {
    margin: 30px 0;
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include md {
      flex-direction: row;
    }

    .content {
      flex-basis: calc(75% - 20px);
    }
    .visual {
      flex-basis: calc(25% - 20px);
      img {
        width: 100%;
        margin: 0 auto;
        max-width: 200px;
        min-height: 150px;

        @include md {
          max-width: 350px;
        }
      }
    }

    &.half {
      .content {
        flex-basis: calc(55% - 20px);
      }
      .visual {
        flex-basis: calc(45% - 20px);
        img {
          max-width: 450px;
          min-height: 250px;
        }
      }
    }
  }

  p {
    margin: 0 0 20px 0;
    line-height: 28px;
    font-size: 16px;
    text-align: justify;

    span {
      color: $warm-blue;
    }

    a {
      font-size: 16px;
      color: $warm-blue;

      &:hover {
        text-decoration: underline;
        color: $blue-blue;
      }
    }
  }

  h1 {
    color: $dark-blue;
    font-weight: 600;
    margin: 0 0 20px;

    font-size: em(26px);
    line-height: em(32px);

    @include md {
      font-size: em(32px);
      line-height: em(40px);
    }

    @include xxl {
      font-size: em(38px);
      line-height: em(45px);
    }
  }

  h2 {
    color: $dark-blue;
    font-weight: 600;
    margin: 0 0 20px;

    font-size: em(26px);
    line-height: em(32px);

    @include md {
      font-size: em(32px);
      line-height: em(40px);
    }

    @include xxl {
      font-size: em(38px);
      line-height: em(45px);
    }
  }

  h3 {
    color: $dark-blue;
    font-weight: 500;
    margin: 0 0 20px;

    font-size: em(20px);
    line-height: em(26px);

    @include md {
      font-size: em(28px);
      line-height: em(32px);
    }

    @include xxl {
      font-size: em(32px);
      line-height: em(40px);
    }
  }

  h4 {
    color: $dark-blue;
    font-weight: 400;
    margin: 0 0 20px;

    font-size: em(18px);
    line-height: em(24px);

    @include md {
      font-size: em(26px);
      line-height: em(30px);
    }

    @include xxl {
      font-size: em(30px);
      line-height: em(38px);
    }
  }
}
