header {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  border-bottom: 0.5px solid $light-blue-grey;
  z-index: 18000;
  min-height: $headerHeightSimplified;

  @media #{$tab-portrait-only} {
    min-height: $headerHeightFull;
  }

  &.nav-open {
    z-index: 18000000;
  }

  @media #{$tab} {
    padding: 0 0 0 10px;
  }

  @media #{$tab-portrait} {
    padding: 0;
  }

  #Logo {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-left: 50px;

    @media screen and (min-width: 960px) and (max-width: 1100px) {
      margin-right: 6px;
      margin-left: 0;
    }

    @media #{$tab-portrait} {
      flex: 1 1 auto;
      justify-content: center;
      margin-right: 0;
      margin-left: 0;
    }

    @media #{$phoneL} {
      max-height: 50px;
    }

    img {
      width: 140px;
    }

    &.egencia {
      img {
        width: 240px;
      }
    }
  }

  nav {
    flex: 1 1 auto;
    min-height: $headerHeightFull;

    @media #{$tab-portrait} {
      position: fixed;
      right: -100%;
      top: $headerHeightFull;
      bottom: 0;
      background: transparent;
      transition: background 0.25 linear;
      overflow-y: auto;

      &.show {
        right: 0;
        left: -1px;
        background: rgba(0, 0, 0, 0.2);

        & > ul {
          right: 0;
        }
      }
    }

    @media #{$phoneL} {
      top: 50.5px;
    }

    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;

      @media #{$tab-portrait} {
        flex-direction: column;
        position: absolute;
        right: -100%;
        width: 230px;
        max-width: 100%;
        background: $white;
        border-left: 0.5px solid $light-blue-grey;
        transition: right 0.25s ease-in-out;
        padding-top: 10px;
        min-height: 400px;
        justify-content: flex-start;
      }

      li {
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        position: relative;

        @media screen and (min-width: 960px) and (max-width: 1100px) {
          padding: 0 8px;
        }

        @media #{$tab-portrait} {
          padding: 10px 20px;
        }

        a {
          display: flex;
          align-items: center;
          height: 100%;
          font-size: em(17px);
          color: $blue-blue;
          transition: color 0.1s ease-in-out;

          &:hover {
            color: $dark-blue;

            .open-icon {
              fill: $dark-blue;
            }
          }

          .open-icon {
            width: 20px;
            height: 20px;
            margin-left: 5px;
            fill: $blue-blue;
            transform: rotate(90deg);
            transition: fill 0.1s ease-in-out;
          }
        }

        ol {
          display: none;
          position: absolute;
          top: 50px;
          right: -60px;
          margin: 0;
          padding: 0;
          background-color: $white;
          border: 1px solid $light-blue-grey;
          list-style: none;
          z-index: 150;

          li {
            padding: 0 20px;

            a {
              padding: 20px 0;
              border-bottom: 1px solid $light-blue-grey;
              gap: 5px;
            }

            &:last-child {
              a {
                border-bottom: none;
              }
            }
          }

          &:before {
            @include translate(-50%, 0);
            content: '';
            position: absolute;
            top: -7px;
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent $light-blue-grey transparent;

            @media #{$tab-portrait} {
              display: none;
            }
          }

          &:after {
            @include translate(-50%, 0);
            content: '';
            position: absolute;
            top: -6px;
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent $white transparent;

            @media #{$tab-portrait} {
              display: none;
            }
          }

          @media #{$tab-portrait} {
            &.show {
              display: block !important;
              position: relative;
              top: 0;
              left: 0;
              width: 100%;
              padding-top: 5px;
              border: none;

              li {
                padding: 0 0 0 20px;

                a {
                  border-bottom: none;
                  padding: 5px 0;
                }
              }
            }
          }
        }

        &.divider {
          flex: 1 1 auto;
          padding: 0;

          @media #{$tab-portrait} {
            display: none;
          }
        }

        &.new-order {
          font-weight: 500;

          @media #{$tab-portrait} {
            padding-bottom: 15px;
          }
        }

        &.business {
          ol {
            width: 170px;
          }
        }

        &.airports,
        &.stations {
          ol {
            width: 250px;

            @media #{$tab-portrait} {
              width: 190px;
            }

            li {
              padding: 0 15px;

              @media #{$tab-portrait} {
                padding: 0 0 0 5px;
              }
              a {
                padding: 8px 0;
                border-bottom: none;
                .icon {
                  width: 18px;
                  height: 18px;
                  transition: fill 0.15s ease-in-out;
                  fill: $warm-blue;


                  @media #{$tab-portrait} {
                    display: none;
                  }
                }
                &:hover {
                  .icon {
                    fill: $blue-blue;
                  }
                }
              }
            }
          }
        }

        &.account {
          padding: 0 5px 0 0;
          margin: 0;

          @media #{$tab-portrait} {
            padding: 20px;

            span {
              display: block;
              width: 100%;
            }
          }
        }

        &.help {
          > a {
            text-transform: uppercase;
            font-weight: 600;
            font-size: em(12px);
            letter-spacing: 1.6px;
          }

          ol {
            right: calc(-50% - 18px);
            width: 230px;

            @media #{$tab-portrait} {
              width: 100%;
            }

            li a {
              padding: 20px 0;
              font-size: em(15px);

              @media #{$tab-portrait} {
                padding: 5px 0;
                font-size: 16px;
              }
            }
          }

          @media #{$tab-portrait} {
            li a {
              font-size: em(17px);
            }
          }
        }

        &.flags {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-left: 0;

          ol {
            padding: 10px 0;
            right: 4px;

            li {
              padding: 5px 10px;

              a {
                padding: 15px 0;
                border-bottom: none;
              }
            }
          }

          span {
            height: 100% !important;
            display: block;
          }

          span,
          a {
            display: block;
            width: 21px;
            height: 21px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;

            &.fr {
              background-image: url('../../static/images/flags/fr@2x.png');
            }
            &.en {
              background-image: url('../../static/images/flags/en@2x.png');
            }
            &.es {
              background-image: url('../../static/images/flags/es@2x.png');
            }
            &.nl {
              background-image: url('../../static/images/flags/nl@2x.png');
            }
            &.pt {
              background-image: url('../../static/images/flags/pt@2x.png');
            }
            &.it {
              background-image: url('../../static/images/flags/it@2x.png');
            }
          }

          @media #{$tab-portrait} {
            flex: 1 1 auto;
            align-items: flex-end;
            padding: 0 15px;
            width: 100%;

            > span {
              display: none;
            }

            > ol {
              display: flex !important;
              flex-direction: row;
              flex-wrap: wrap;
              flex: 1 1 auto;
              align-items: flex-end;
              align-content: flex-end;
              position: relative;
              width: 100%;
              top: 0;
              left: 0;
              border: none;

              li {
                display: flex;
                flex: 0 1 auto;
                justify-content: center;
                width: 25%;
                margin-bottom: 10px;
                padding: 6px;

                a {
                  width: 28px;
                  height: 28px;
                  background-size: 20px 20px;
                  padding: 5px;

                  &.active {
                    border: 1px solid $blue-blue;
                    border-radius: 14px;
                  }
                }
              }
            }
          }
        }

        &.user {
          padding: 0 5px 0 0;

          @media #{$tab-portrait} {
            padding: 10px 20px;
          }

          .name {
            max-width: 90px;
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 3px;
            font-weight: 500;
            text-transform: capitalize;
            white-space: nowrap;
          }

          ol {
            width: 180px;
            right: 0;

            li {
              padding: 0 15px;

              a {
                font-size: em(16px);
              }
            }
          }
        }

        &:hover {
          ol {
            display: block;

            @media #{$tab-portrait} {
              display: none;
            }
          }
        }
      }
    }
  }

  #hamburger {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 65px;
    height: 100%;

    @media #{$tab-portrait} {
      display: block;
    }

    @media #{$phoneL} {
      width: 50px;
    }

    span {
      position: absolute;
      right: 21px;
      display: block;
      width: 17px;
      height: 2px;
      background-color: $blue-blue;

      &:nth-child(1) {
        top: 25px;
      }

      &:nth-child(2) {
        top: 31px;
      }

      &:nth-child(3) {
        top: 37px;
      }

      @media #{$phoneL} {
        right: 14px;

        &:nth-child(1) {
          top: 18px;
        }

        &:nth-child(2) {
          top: 24px;
        }

        &:nth-child(3) {
          top: 30px;
        }
      }
    }
  }
}
