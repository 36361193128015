.travelInfoForm {

  .MeetingPoint {
    margin-bottom: 20px;
    text-align: left;

    .label {
      display: inline-block;
      font-weight: 600;
      font-size: em(11px);
      margin: 0 0 0 15px;
      color: $dark-blue;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .selector {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px 0;
    }

    .site {
      flex: 1 1 50%;
      border: 0.8px solid $light-blue-grey;
      color: #050505;
      margin: 5px 0;
      padding: 6px 20px;
      height: 40px;
      line-height: 1.625rem;
      width: 100%;
      font-weight: 300;
      border-radius: 20px;
      font-size: em(16px);
    }

    .checkpoint {
      flex: 1 1 50%;
    }

    @media #{$tab-min} {
      .selector {
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0 50px;
      }
      .site,
      .checkpoint {
        flex: 1 1 100%;
      }

      .site {
        font-size: 0.875rem;
      }
    }
  }

  .Trip {
    display: flex;
    text-align: left;
    margin-bottom: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;

    .trip-informations {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;

      .TravelInformations {
        flex-grow: 1;
        width: auto;
        max-width: 100%;

        .error.show {
          position: relative;
          margin-bottom: 5px;
        }
      }

      .Switch {
        height: auto;

        .switch-label {
          margin-left: 7px;
          line-height: initial;
          font-size: 12px;
          white-space: normal;
          color: $dark-blue;
          overflow: initial;
        }
      }
    }

    .trip-hour {
      flex: 1 1 100%;
    }

    @media #{$tab-min} {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 0 50px;

      .trip-informations,
      .trip-hour {
        flex: 1 1 50%;
      }
    }
  }

  .Separator {
    background-image: linear-gradient(to right, $light-blue-grey 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom left;
    background-size: 16px 1px;
    background-repeat: round;
    height: 1px;
    margin: 25px 0;
  }

  .ReturnTripNotice,
  .MeetingPointNotice {
    text-align: left;
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin: 10px 0;
    font-size: 13px;
    color: $dark-blue;

    svg {
      flex-shrink: 0.5;
      height: 48px;
      width: 48px;
      fill: $dark-blue;
    }
  }

}
