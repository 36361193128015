.business-connect {
  .form {
    max-width: 600px;

    .action {
      margin-top: 40px;

      a:first-child {
        margin-bottom: 20px;
      }
    }
  }
}

.business-sign-up {
  .form {
    max-width: 100%;

    fieldset {
      margin-bottom: 35px;
      padding: 35px 20px 10px;
      border: 1px solid $light-blue-grey;

      legend {
        font-size: em(15px);
        color: $blue-blue;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 20px;
        letter-spacing: 1.4px;
        margin: auto;
      }

      .field {
        display: inline-block;
        width: calc(50% - 2px);

        &:nth-child(even) {
          padding-right: 15px;
        }

        &:nth-child(odd) {
          padding-left: 15px;
        }

        @media #{$tab-portrait} {
          width: 100%;

          &:nth-child(even) {
            padding-right: 0;
          }

          &:nth-child(odd) {
            padding-left: 0;
          }
        }
      }
    }

    @media #{$phoneL} {
      .content {
        padding: 20px 10px;
      }
    }
  }
}
