.grey-container-modal {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);

  @media #{$desktop} {
    padding-right: 316px;
  }

  .upsellingModal {
    max-width: 640px;
    min-height: 350px;
    margin: 20vh auto;
    padding: 37px 40px 35px;
    border-radius: 20px;
    background-color: white;
    border: solid 0.5px var(--light-blue-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    @media #{$tab-min} {
      margin-top: 35vh;
    }

    &Title {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin: 10px;
        font-size: 18px;
        font-weight: 600;
        color: $blue-blue;
        text-align: center;
      }
    }

    &Description {
      text-align: center;
    }

    &CtaContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
