.contact {
	display: flex;
	flex-wrap: wrap;
	background-color: $white;
	margin-bottom: 50px;

	h1 {
		width: 100%;
		margin: 0;
		background-color: $dark-blue;
		color: $white;
		line-height: 65px;
		text-align: center;
		font-size: em(26px);
		font-weight: 600;
	}

	.left {
		display: flex;
		flex-direction: column;
		width: 300px;
		background-color: $warm-blue;

		@media #{$tab-portrait} {
			width: 100%;
		}

		.image {
			height: 364px;
			background: url('../../static/images/contact/contact.jpg') no-repeat center center;
			background-size: 100%;

			@media #{$tab-portrait} {
				height: 200px;
			}
		}

		.phone {
			flex: 1 1 auto;
			display: flex;
			align-items: center;

			a {
				color: white;
			}

			dl {
				margin: 20px 50px 20px 40px;
				padding: 0 0 0 40px;
				color: $white;
				background: url('../../static/images/contact/phone.svg') no-repeat top left;
				background-size: 26px 26px;

				dt {
					font-size: em(14px);
					text-transform: uppercase;
					font-weight: bold;
				}

				dd {
					margin: 0;
					font-size: em(15px);
					line-height: 1.6rem;
				}
			}
		}

	}

	.right {
		width: calc(100% - 300px);
		padding: 25px 40px 30px;
		border-bottom: 0.5px solid $light-blue-grey;
		border-right: 0.5px solid $light-blue-grey;

		@media #{$tab-portrait} {
			width: 100%;
			border-left: 0.5px solid $light-blue-grey;
		}

		@media #{$phoneL} {
			padding: 25px 20px 30px;
		}

		form {
			display: flex;
			flex-wrap: wrap;
		}

		.field {
			display: inline-block;
			width: 100%;
			margin-bottom: 20px;

			&.half {
				width: 50%;

				@media #{$phoneL} {
					width: 100%;
					padding: 0 !important;
				}

				&:nth-child(2), &:nth-child(4) {
					padding-right: 10px;
				}

				&:nth-child(3), &:nth-child(5) {
					padding-left: 10px;
				}
			}

			label {
				color: $dark-blue;
				font-size: em(11px);
				font-weight: 600;
				padding-left: 15px;
				text-transform: uppercase;
				letter-spacing: 1px;
			}

			select {
				text-transform: none;
			}

			textarea {
				border-radius: 1px;
			}
		}

		.action {
			width: 100%;
			text-align: right;

			@media #{$tab-portrait} {
				text-align: center;
			}
		}
	}
}
