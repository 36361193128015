.sitesSection {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ecf0fc);
  padding: 0 25px 80px;
  width: 100%;
}

.slider-container {
  padding: 0 25px 80px;
  width: 100%;
}

#SitesSliderRoot, #sites-slider-container {
  max-width: 1080px;
  margin: auto;

  @include xl {
    max-width: 1280px;
  }

  .swiper {
    padding: 50px 0;

    @include lg {
      padding-bottom: 80px;
    }

    .swiper-slide {
      overflow: hidden;
      width: 85%;
      height: 400px;
      border-radius: 20px;

      @include md {
        width: 42.5%;
      }

      @include xl {
        width: 22.5%;

        &.swiper-slide-visible:not(.swiper-slide-active) {
          .nextGroupPreview {
            opacity: 0.2;
          }
        }
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      gap: 1px;
      bottom: 0;

      @include lg {
        gap: 10px;
      }

      .swiper-pagination-bullet {
        height: 15px;
        width: 15px;
        background-color: $blue-blue;
        background-clip: content-box;
        padding: 3px;
        margin: 0;

        &.swiper-pagination-bullet-active {
          margin: 0 2px;
          border-radius: 30px;

          &:before {
            height: 15px;
            width: 15px;
            content: '';
            display: block;
            border: solid 2px rgba(65, 105, 225, 0.3);
            border-radius: 16px;
            margin: -5px 0 0 -5px;
          }
        }
      }
    }

    .swiper-button-prev {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 12L4 12' stroke='%23001C86' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 18L4 12L10 6' stroke='%23001C86' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    .swiper-button-next {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 12H20' stroke='%23001C86' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 6L20 12L14 18' stroke='%23001C86' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      background-color: white;
      height: 70px;
      width: 70px;
      border-radius: 50%;
      top: calc(50% - 35px);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 32px;
      box-shadow: 5px 5px 46px 3px rgba(0, 0, 0, 0.34);
      -webkit-box-shadow: 5px 5px 46px 3px rgba(0, 0, 0, 0.34);
      -moz-box-shadow: 5px 5px 46px 3px rgba(0, 0, 0, 0.34);

      @include lg {
        display: initial;
      }

      &::after {
        content: none;
      }

      &.swiper-button-disabled {
        display: none;
      }
    }
  }
}


.siteCard {
  .backgroundImage {
    position: absolute;
    width: 100% !important;
    z-index: -12;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    img {
      height: fit-content;
      width: fit-content;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(20, 55, 185, 0) 37%, #0b1d62 88%);
    padding: 15px 15px 25px;

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 30px;
      background-color: $blue-blue;

      &.station {
        background-color: $warm-blue;
      }

      svg {
        margin: auto;
        height: 18px;
        width: 17px;
        path {
          fill: white;
        }
      }
    }

    .itemsContainer {
      display: flex;
      flex-direction: column;
      gap: 7px;

      .ratingContainer {
        display: flex;
        gap: 5px;

        p {
          margin: 5px 0 0;
          color: #2cd5c4;
          font-weight: 600;
        }
      }

      .title {
        font-size: 21px;
        font-weight: bold;
        color: white;
        margin: 0 0 10px;
        min-height: 50px;
      }

      .Button {
        margin: 0;
        width: fit-content;
      }
    }
  }
}
