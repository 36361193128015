#wrapper .landing.landingValet {
  .segment {
    img {
      max-width: 350px;
      width: 100%;
    }

    .histogram-comparer {
      display: inline-block;
      width: 100%;
      text-align: center;

      .heading {
        text-transform: uppercase;
        line-height: 1.1;
        margin-bottom: 15px;
        div {
          &:not(.stronger) {
            font-size: 21px;
          }
          &.stronger {
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 1px;
          }
        }
      }
      .graphs {
        height: 200px;
        display: flex;
        align-items: flex-end;
        & > div {
          width: 50%;
          height: 100%;
          & > span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 26px;
            font-weight: 400;
          }
        }
        .local-price > span {
          background: #acacac;
          color: #1c2747;
          height: 100%;
        }
        .bv-price {
          position: relative;
          & > span {
            background: #1c2747;
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }
      .footing {
        display: flex;
        & > div {
          width: 50%;
          padding: 10px 0;
          font-size: 12px;
          text-transform: uppercase;
          line-height: 1.1;
        }
        strong {
          display: block;
          font-weight: 600;
        }
      }
    }
  }
  .contain {
    p > strong {
      color: $blue-blue;
      font-weight: bold;
    }

    table {
      margin: 0 0 20px;
      border-collapse: separate !important;
      border-spacing: 0;
      width: 100% !important;
      td {
        border: 1px solid $light-blue-grey !important;
        padding: 5px;
        padding: 10px !important;
        font-family: 'AvenirNext' !important;
      }

      tr:first-child {
        td {
          border-bottom: 0 !important;

          @include sm {
            min-width: 110px !important;
          }

          strong {
            color: $blue-blue;
            font-size: 14.3px;
            font-weight: 600 !important;
            letter-spacing: 1.65px;
            text-transform: uppercase;
          }
        }

        td:first-child {
          display: none;
          border-top-left-radius: 10px;
        }

        td:nth-child(2) {
          border-top-left-radius: 10px;
          border-right: 0 !important;
        }

        td:last-child {
          border-left: 0 !important;
          border-top-right-radius: 10px;
        }

        @include sm {
          td:first-child {
            display: table-cell;
          }

          td:nth-child(2) {
            border-top-left-radius: 0px;
            border-left: 0 !important;
          }
        }
      }

      tr:last-child {
        td {
          color: $dark-blue !important;
          font-weight: bold !important;
          letter-spacing: 2.2px;

          strong {
            letter-spacing: normal;
            font-size: 16.5px !important;
            font-weight: 600 !important;
          }
        }

        td:first-child {
          display: none;
          border-bottom-left-radius: 10px;
        }

        td:nth-child(2) {
          border-bottom-left-radius: 10px;
          border-right: 0 !important;
        }

        td:last-child {
          border-bottom-right-radius: 10px;
          border-left: 0 !important;
        }

        @include sm {
          td:first-child {
            display: table-cell;
            padding-left: 20px !important;
          }

          td:nth-child(2) {
            border-bottom-left-radius: 0;
            border-left: 0 !important;
          }
        }
      }
    }
  }
}
