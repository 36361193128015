.richeText {
	p {
		margin: 0 0 20px 0;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}
	a {
		color: $tertiary;
		&:hover {
			color: $primary;
		}
	}
}
