.error {
	h2 {
		font-size: em(20px);
		text-align: center;
		font-family: 'Syncopate';
		text-transform: uppercase;
		font-weight: normal;
		color: $tertiary;
		text-align: center;
		margin: 0 0 20px 0;
		padding: 0 10px;
		@media #{$phoneL} {
			@include translate(0,0);
			display: block;
			text-align: left;
			position: relative;
			left: auto;
			top: auto;
			padding: 0;
			margin-bottom: 20px;
		}
	}
	strong {
		text-align: center;
		display: block;
	}
}
