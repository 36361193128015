.account {
  margin-bottom: 20px;

  @media #{$tab-portrait} {
    margin-top: 20px;
  }

  .message ul {
    list-style: none !important;
  }

  .marginRight20 {
    margin-right: 20px;
    @media #{$tab-portrait} {
      margin-bottom: 10px;
    }
  }

  ul.nav {
    @extend .clearfix;
    background: $dark-blue;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;

    li {
      float: left;
      width: 150px;

      @media #{$phoneL} {
        width: 100%;
      }

      a {
        display: inline-block;
        height: 100%;
        width: 100%;
        padding: 0 20px;
        color: $white;
        line-height: em(60px);
        font-size: em(12px);
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        letter-spacing: 1.2px;
        opacity: 0.8;
        background-color: none;
        transition: background-color 0.15s ease-in-out;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      &.right {
        float: right;

        a {
          font-size: em(14px);
          text-transform: none;
          letter-spacing: normal;
        }

        @media #{$tab-portrait} {
          float: none;
        }
      }

      &.active a {
        background: $blue-blue;
        color: $white;
        opacity: 1;
      }
    }
  }

  .container {
    background: $white;
    padding: 25px 20px 15px;
    border: 0.3px solid $light-blue-grey;
    border-top: none;
    margin: 0;

    @media #{$tab-portrait} {
      padding: 20px 10px;
    }

    .backLink {
      position: relative;
      color: $blue-blue;
      font-size: em(15px);
      font-weight: 500;
      margin-bottom: 20px;
      display: inline-block;
      padding: 0 0 0 18px;

      &:hover {
        color: $dark-blue;

        svg {
          fill: $dark-blue;
        }
      }

      svg {
        position: absolute;
        top: 5px;
        left: 0;
        width: 10px;
        height: 10px;
        transform: rotate(180deg);
        fill: $blue-blue;
      }
    }

    fieldset {
      margin: 0;
      padding: 20px;
      border: 0.5px solid $light-blue-grey;

      &.new-order {
        text-align: center;

        p {
          margin: 15px 0 25px;
        }

        a {
          width: auto !important;
        }
      }

      &:not(:last-child) {
        margin-bottom: 25px;
      }

      legend {
        font-size: em(16px);
        color: $dark-blue;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 20px;
        letter-spacing: 1.6px;
        margin: auto;

        &.blue-blue {
          color: $blue-blue;
        }
      }
    }

    h3 {
      font-size: em(20px);
      margin: 50px 0 20px 0;
      font-family: "Syncopate";
      text-transform: uppercase;
      font-weight: normal;
      color: $tertiary;
      h2 + &:first-of-type {
        margin-top: 0;
      }
    }

    table {
      margin: 0;
      padding: 0;
      border: 0 none;
      width: 100%;
      border-collapse: collapse;
      font-size: em(14px);
      @media #{$tab-portrait} {
        display: block;
      }
      tbody {
        @media #{$tab-portrait} {
          display: block;
          width: 100%;
        }
      }
      tr {
        @media #{$tab-portrait} {
          border-bottom: 0.5px solid $light-blue-grey;
          padding: 10px 0;
          display: block;
          width: 100%;
          &:first-of-type {
            display: none;
          }
          &:last-of-type {
            border: 0 none;
          }
        }

        th {
          font-weight: bold;
          text-align: left;
          text-transform: uppercase;
          font-size: em(11px);
          padding: 0 10px;
          color: $dark-blue;

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }

        td {
          padding: 10px;
          vertical-align: top;
          border-bottom: 1px solid $light-blue-grey;
          font-size: em(13px);

          &:first-of-type {
            padding-left: 0;
          }
          &:last-of-type {
            padding-right: 0;
          }
          @media #{$tab-portrait} {
            display: flex;
            width: 100%;
            border: 0 none;
            padding: 6px 0;
            font-size: em(12px);
            &:before {
              content: attr(data-th) ": ";
              font-weight: 500;
              width: 90px;
              display: inline-block;
            }
          }
          img {
            vertical-align: middle;
            margin: 0 10px 0 0;
          }

          .action-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 55px;
            height: 35px;
            margin: 0 10px 5px 0;
            border: 1px solid $blue-blue;
            border-radius: 17.5px;

            svg {
              width: 15px;
              height: 15px;
              fill: $blue-blue;
            }

            &:hover {
              border-color: $dark-blue;

              svg {
                fill: $dark-blue;
              }
            }
          }

          strong {
            font-weight: bold;
          }
        }
        &:last-of-type td {
          border: 0 none;
        }
      }
    }
    .form {
      @extend .clearfix;

      .field {
        width: calc((100% - 50px) / 2);
        padding: 0;
        float: left;
        margin: 0 50px 20px 0;


        &.full {
          width: 100%;
          margin-right: 0;
        }

        &.compact {
          width: calc((100% - 100px) / 4);
          margin-right: 25px;

          @media #{$phoneL} {
            width: calc((100% - 25px) / 2);
            margin-right: 25px;
            &:nth-child(even) {
              margin-right: 0;
            }
          }
          @media #{$tab-min} {
            &:nth-child(2n+1) {
              margin-right: 25px;
            }
            &:nth-child(4n-2) {
              margin-right: 50px;
            }
            &:nth-child(4n) {
              margin-right: 0px;
            }
          }
        }

        label {
          display: inline-block;
          color: $dark-blue;
          font-weight: 600;
          font-size: em(11px);
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        &:not(.compact):nth-child(even) {
          margin-right: 0;
        }
        > label {
          padding-left: 15px;
        }

        label.InputSelect {
          width: 100%;
        }
        @media #{$phoneL} {
          width: 100%;
          margin-right: 0;
        }
      }
      .trip-informations {
        width: 100%;

        .TravelInformations {
          .Input {
            padding: 0;
            width: 100%;
          }

          max-width: none;
          ul {
            list-style: none;
          }
        }
      }

      .DayPicker {
        width: 100%;
        > label {
          width: 100%;
        }
      }
      .HourPicker {
        .info {
          position: relative;
          white-space: normal;
        }
      }
    }

    .update-datas {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      padding: 0 10px;
      gap: 10px;

      @media #{$tab-min} {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }

      a {
        background: $white;
        border: 1px solid $light-blue-grey;
        color: $blue-grey;
        font-weight: 600;

        &:hover {
          border: 1px solid $blue-blue;
          color: white;
        }

        &:nth-child(2) {
          @media #{$tab-portrait} {
            margin: 0;
            margin-bottom: 10px;
          }
        }
      }

      @media #{$tab-portrait} {
        flex-direction: column;

        a {
          margin-bottom: 10px !important;
        }
      }
    }

    .action {
      text-align: center;
    }

    p {
      &.centered {
        text-align: center;
      }
      em {
        color: $tertiary;
        font-size: em(14px);
      }
    }

    ul {
      list-style: decimal;
      padding-left: 15px;
    }

    a.button {
      @media #{$phoneL} {
        max-width: 100%;
        margin-bottom: 20px;
        width: 100%;
      }
    }

    .manage-subscriptions {
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      .switch-newsletter-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .order-detail {
    h2 {
      padding: 10px 0 40px !important;
      font-size: em(28px) !important;
      background: none;
    }

    fieldset legend {
      color: $blue-blue;
    }

    .action {
      a {
        display: block;
        color: $blue-blue;
        font-size: em(15px);
        font-weight: 500;
        margin: 20px 0 15px;
      }
    }
  }

  .sponsorship {
    .my-code {
      display: flex;
      flex-wrap: wrap;

      > div {
        padding: 0 10px;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{$tab-portrait} {
          padding-top: 20px;
        }
      }

      strong {
        display: block;
        font-size: em(11px);
        font-weight: 600;
        color: $dark-blue;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .code {
        span {
          display: block;
          width: 150px;
          height: 40px;
          background: $dark-blue;
          font-size: em(26px);
          font-weight: bold;
          letter-spacing: 2px;
          color: $white;
          text-transform: uppercase;
          line-height: em(40px);
          text-align: center;
        }
      }

      .sms {
        strong {
          padding-left: 10px;
        }

        form {
          display: flex;

          input {
            margin-right: 10px;
          }
        }
      }
    }

    .sponsorship-explain {
      display: flex;
      flex-wrap: wrap;

      .block {
        width: calc(100% / 3);
        padding: 20px;

        @media #{$tab-portrait} {
          width: 100%;
        }

        @media #{$phoneL} {
          padding: 0;
        }

        img {
          width: 35px;
        }

        p {
          font-size: em(14px);
          line-height: em(22px);

          &.title {
            margin-top: 25px;
            color: $dark-blue;
            font-weight: 600;
          }
        }
      }
    }

    .asterisk {
      padding-left: 20px;
      font-size: em(14px);
      color: $blue-grey;
      font-style: italic;

      @media #{$phoneL} {
        padding-left: 0;
      }
    }
  }

  #ErrorContainer > div {
    margin: 0 auto 40px;
  }
}

.subscriptionPack.bookingLogin {
  margin: 50px auto;
  .form {
    .field {
      margin-right: 50px;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
  table {
    width: 100%;
    margin: 0;
    padding: 0;
    th {
      text-align: left;
      padding: 5px 0;
      font-size: em(14px);
    }
    td {
      font-size: em(14px);
      padding: 5px 0;
      strong {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: em(30px);
      }
      a {
        text-transform: uppercase;
        color: $tertiary;
      }
    }
  }
}
