@import 'src/styles/var';
@import 'src/styles/mixins';

#review-in-layout.success {
  text-align: center;
  color: $dark-blue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div.text-container {
    p.title {
      font-weight: 500;
      font-size: em(22px);
    }

    p.subtitle {
      font-size: em(16px);
      font-style: italic;
    }
  }
}