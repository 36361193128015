@import 'src/styles/var';

#review-in-description {
  textarea {
    border-radius: 10px;

    &.error {
      border-color: $watermelon;
    }
  }
}